import React from 'react'

import muiThemeable from 'material-ui/styles/muiThemeable'

export default muiThemeable()((props) => {
  const { disabled = false } = props
  return <div style={{
      textAlign: 'center',
    }}>
    <div style={{
        opacity: .6,
        fontSize: 14,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}>{props.title}</div>
    <div style={{
        color: disabled ? 'light-grey' : props.muiTheme.palette.primary1Color,
        fontSize: 18,
        fontWeight: 'normal',
      }}>{props.value}</div>
  </div>
})
