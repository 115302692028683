import React, { Component } from 'react'

import { Card, CardText } from 'material-ui/Card'

import { setTitle } from '../utils'
import { getQuery } from '../utils/parse'

const styles = {
  card: {
    marginBottom: 20,
  },
}

const title = 'User Guide'

class Guide extends Component {
  state = {
  }

  componentWillMount() {
    setTitle(title)
  }

  isDisplay(slug) {
    let s = getQuery('slug').toString().trim().toLowerCase()
    if (s === '' || s === slug) {
      return 'block'
    }

    return 'none'
  }

  render() {
    return (
      <section className="content-wrapper">
        <Card className="card-override" style={styles.card}>
          <CardText>
            <div style={{
                display: this.isDisplay('utm-parameters'),
              }} >
              <h3>UTM parameters</h3>
              <p>UTM parameters or Urchin Traffic Monitor parameters (UTM) are URL parameters used by marketers to track audiences. The parameters enable marketers to analyse the effectiveness of marketing campaigns across traffic sources and publishing media. They are added to the URL of pages either manually or by using a UTM builder tool.</p>
              <p>
                Example url is <em>http://mashdrop.com/?utm_campaign=<strong>facebook</strong>&utm_medium=<strong>PPP</strong>&utm_source=<strong>mashdrop</strong></em>. This means the source of campaign is from Facebook platform and its a Pay-per-Post type of MashDrop's campaign.
              </p>
              <p>Learn more about UTM parameters <a href="https://en.wikipedia.org/wiki/UTM_parameters">https://en.wikipedia.org/wiki/UTM_parameters</a>.</p>
              <br />
            </div>

            <div style={{
                display: this.isDisplay('optimize-images'),
              }} >
              <h3>Optimize images to generate great previews</h3>
              <p>Use images that are at least 1200 x 630 pixels for the best display on high resolution devices. At the minimum, you should use images that are 600 x 315 pixels to display link page posts with larger images.</p>
              <p>Learn more of Sharing Best Practices <a href="https://developers.facebook.com/docs/sharing/best-practices">https://developers.facebook.com/docs/sharing/best-practices</a>.</p>
              <br />
            </div>

            <div style={{
                display: this.isDisplay('maxed-out-campaigns'),
              }} >
              <h3>Maxed out campaigns</h3>
              <p>When click limit is reached the campaign post still works and redirect to target URL but MashDrop's banner will flash for 10 seconds before going through. Also campaign will change status to <strong>Completed</strong> and no longer receive further stats.</p>
              <br />
            </div>
          </CardText>
        </Card>
      </section>
    )
  }
}

export default Guide
