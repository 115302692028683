import React from 'react'

import FlatButton from 'material-ui/FlatButton'
import FontIcon from 'material-ui/FontIcon'
import { Card, CardActions, CardText } from 'material-ui/Card'

export default () => (
  <Card className="card-override" expanded={true}>
    <CardText style={{textAlign: 'center'}}>
      <FontIcon className="fa fa-check-circle"
        style={{fontSize: 100}}
        color="#4CAF50" />
      <h1>Congratulations!</h1>
      <p>You've finished setting up your profile! We will send notification and email when you've been invited on a campaign.</p>
    </CardText>
    <CardActions style={{textAlign: 'center'}}>
      <FlatButton label="improve profile" href="/#/influencer/account" />
      <FlatButton primary={true} label="READ FAQs" href="/#/influencer/faq" />
    </CardActions>
  </Card>
)
