import React from 'react'

export default () => (
  <img src="/img/logo.png" alt="" style={{
    width: 26,
    filter: 'grayscale(100%) brightness(2)',
    position: 'absolute',
    top: 10,
    left: 8,
  }} />
)
