import api from '../api'

/*
 *  action constants
 */

export const CREATE_PAYMENT  = 'CREATE_PAYMENT'
export const GET_PAYMENT  = 'GET_PAYMENT'
export const UPDATE_PAYMENT  = 'UPDATE_PAYMENT'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createPayment = (data) => (dispatch) => {
  dispatch({
    type: CREATE_PAYMENT,
    payload: api.post('/me/payment', data),
  })
}

export const getPayment = () => (dispatch) => {
  dispatch({
    type: GET_PAYMENT,
    payload: api.get('/me/payment'),
  })
}

export const updatePayment = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PAYMENT,
    payload: api.patch('/me/payment', data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
