import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router'

import AppBar from 'material-ui/AppBar'
import Drawer from 'material-ui/Drawer'
import Divider from 'material-ui/Divider'
import MenuItem from 'material-ui/MenuItem'
import Subheader from 'material-ui/Subheader'
import IconButton from 'material-ui/IconButton'

import OverviewIcon from 'material-ui/svg-icons/action/dashboard'
import TransactionIcon from 'material-ui/svg-icons/action/receipt'
import AccountIcon from 'material-ui/svg-icons/action/account-circle'
import CampaignIcon from 'material-ui/svg-icons/image/assistant-photo'

import { formatFigure, isMobile } from '../utils'

import LogoIcon from '../components/Logo'
import AppTitle from '../components/AppTitle'
import VersionBadge from '../components/VersionBadge'

const styles = {
  title: {
    marginLeft: 8,
  },
  menu: {
    textDecoration: 'none',
  },
  balanceLink: {
    position: 'absolute',
    right: 20,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    transaction: state.transaction,
  }
}

class Nav extends Component {
  render() {
    return (
      <Drawer open={this.props.open} docked={!isMobile()}
        onRequestChange={this.props.toggle} >
        <AppBar title={<span><AppTitle /> <VersionBadge /></span>}
          titleStyle={styles.title}
          onTitleClick={this.props.toggle}
          iconElementLeft={<IconButton onClick={this.props.toggle}><LogoIcon /></IconButton>} />

        <Link to="/influencer/overview" activeClassName="active" style={styles.menu}>
          <MenuItem leftIcon={<OverviewIcon />} primaryText="Overview"
            onClick={this.props.toggle} />
        </Link>

        <Subheader>Manage</Subheader>
        <Link to="/influencer/campaign" activeClassName="active" style={styles.menu}>
          <MenuItem leftIcon={<CampaignIcon />} primaryText="Campaign"
            onClick={this.props.toggle} />
        </Link>

        <Subheader>Personal</Subheader>
        <Link to="/influencer/account" activeClassName="active" style={styles.menu}>
            <MenuItem leftIcon={<AccountIcon />} primaryText="Account"
              onClick={this.props.toggle} />
        </Link>
        <Link to="/influencer/transaction" activeClassName="active" style={styles.menu}>
          <MenuItem leftIcon={<TransactionIcon />} primaryText="Transaction"
            onClick={this.props.toggle} />
        </Link>

        <Divider />
        <Subheader>
          Balance <a href="/#/influencer/transaction"
          style={styles.balanceLink} onClick={this.props.toggle}>
          {formatFigure(this.props.transaction.score.unpaid_balance)}</a>
        </Subheader>
        <Divider />
      </Drawer>
    )
  }
}

Nav.propTypes = {
  open: PropTypes.bool.isRequired,
}

Nav.defaultProps = {
  open: true,
}

export default connect(mapStateToProps)(Nav)
