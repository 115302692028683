import * as userAction from '../actions/user'
import { setAuth } from '../utils'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // register
    case `${userAction.REGISTER_USER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${userAction.REGISTER_USER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${userAction.REGISTER_USER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // login
    case `${userAction.LOGIN_USER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${userAction.LOGIN_USER}_FULFILLED`:
      // saving credentials
      setAuth(action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${userAction.LOGIN_USER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get user
    case `${userAction.GET_USER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${userAction.GET_USER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${userAction.GET_USER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // update user
    case `${userAction.UPDATE_USER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${userAction.UPDATE_USER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: true,
          message: 'Changes saved'
        },
      })
    case `${userAction.UPDATE_USER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        }
      })

    // notification
    case userAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })
    // no default
  }

  return state
}
