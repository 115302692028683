import api from '../api'

/*
 *  action constants
 */

export const FIND_NOTIFICATION  = 'FIND_NOTIFICATION'
export const GET_NOTIFICATION  = 'GET_NOTIFICATION'
export const UPDATE_NOTIFICATION  = 'UPDATE_NOTIFICATION'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findNotification = (option) => (dispatch) => {
  let params = '?meta'
  params += '&filter.checked=false'
  params += '&order=created_at,desc'

  dispatch({
    type: FIND_NOTIFICATION,
    payload: api.get('/notification' + params),
  })
}

export const getNotification = (id) => (dispatch) => {
  dispatch({
    type: GET_NOTIFICATION,
    payload: api.get('/notification/' + id),
  })
}

export const updateNotification = (id, data) => (dispatch) => {
  dispatch({
    type: UPDATE_NOTIFICATION,
    payload: api.patch('/notification/' + id, data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF
  })
}
