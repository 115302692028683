import * as postAction from '../actions/post'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  detail: {},
  list: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // create post
    case `${postAction.CREATE_POST}_PENDING`:
      return Object.assign({}, state, {
        detail: {},
        loading: true,
        notify: {
          open: true,
          message: 'Posting campaign...'
        },
      })
    case `${postAction.CREATE_POST}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: true,
          message: 'Campaign successfully posted'
        },
      })
    case `${postAction.CREATE_POST}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

      // create post on facebook group
      case `${postAction.CREATE_POST_FB_GROUP}_PENDING`:
        return Object.assign({}, state, {
          detail: {},
          loading: true,
          notify: {
            open: true,
            message: 'Posting campaign...'
          },
        })
      case `${postAction.CREATE_POST_FB_GROUP}_FULFILLED`:
        return Object.assign({}, state, {
          loading: false,
          detail: action.payload.data,
          notify: {
            open: true,
            message: 'Campaign successfully posted'
          },
        })
      case `${postAction.CREATE_POST_FB_GROUP}_REJECTED`:
        return Object.assign({}, state, {
          loading: false,
          error: true,
          notify: {
            open: true,
            message: action.payload.response.data.msg
          },
        })

    // find post
    case `${postAction.FIND_POST}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        list: [],
      })
    case `${postAction.FIND_POST}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data,
      })
    case `${postAction.FIND_POST}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get post
    case `${postAction.GET_POST}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${postAction.GET_POST}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${postAction.GET_POST}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })


    // post
    case postAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
