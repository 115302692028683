import * as storage from './storage'
import numeral from 'numeral'
import moment from 'moment'
import validUrl from 'valid-url'

let apiInstance
let context

export const AUTH_KEY = 'auth'
export const ID_KEY = 'id'
export const TOKEN_KEY = 'token'
export const TYPE_KEY = 'type'

export const setApiInstance = (instance) => {
  apiInstance = instance
}

export const getAuthToken = () => {
  if (getAuth().hasOwnProperty(TOKEN_KEY)) {
    return getAuth()[TOKEN_KEY]
  }

  return ''
}

export const setAuth = (data) => {
  // set token to api
  apiInstance.defaults.headers = {'Authorization': 'Bearer ' + data.token}

  storage.setLocal(AUTH_KEY, data)
}

export const getAuth = () => {
  return storage.getLocal(AUTH_KEY) || {}
}

export const clearAuth = () => {
  storage.clearSession()
  storage.clearLocal()
}

export const isLogin = () => {
  // check if token exists
  let token = getAuthToken()
  if (token === '') {
    return false
  }

  return true
}

export const setContext = (c) => {
  context = c
}

export const redirect = (path) => {
  context.router.push(path);
}

export const requireAuth = (appRoot, nextState, replace) => {
  if (!isLogin()) {
    replace({
      pathname: appRoot + '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}

export const checkAuth = (appRoot, nextState, replace) => {
  if (isLogin()) {
    replace({
      pathname: appRoot + '/',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}

export const formatDate = (date) => {
  // return moment(date).calendar()
  // return moment(date).fromNow()
  return moment(date).format('MMM DD YYYY - hh:mm a')
}

export const formatRelativeDate = (date) => {
  return moment(date).fromNow()
}

export const formatAmount = (amount) => {
  return parseFloat((amount / 100).toFixed(2))
}

export const deFormatAmount = (amount) => {
  return parseInt((amount * 100).toFixed(2), 10)
}

export const formatFigure = (amount) => {
  return numeral(formatAmount(amount)).format('0,0.00')
}

export const getCategories = () => {
  return storage.getSession('categories') || []
}

export const isURL = (url) => {
  return validUrl.isUri(url)
}

export const isEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const setTitle = (value) => {
  storage.setSession('title', value)
}

export const toUpperCaseFirst = (value) => {
  return value.charAt(0).toUpperCase() + value.substr(1);
}

export const toSiteURL = (path = '') => {
  return window.location.origin.replace('app.', '') + path
}

export const getViewWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}

export const isMobile = () => {
  return getViewWidth() < 960
}

export const viewPortRefresher = () => {
  // refresh if size changed
  let w = isMobile()
  window.addEventListener('resize', function() {
    if (w !== isMobile()) {
      window.location.reload()
    }
  })
}

export const abbreviateNumber = (num) => {
  if (!num) {
    return '-'
  }

  if(num >= 1000000)
    return intlFormat(num / 1000000) + 'M'
  if(num >= 1000)
    return intlFormat(num / 1000) + 'K'
  return intlFormat(num)
}

const intlFormat = (num) => {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10)
}

export const getInitials = name => {
  let initials = name.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

export const stringToHslColor = (str = '', s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export const pastelColor = (str) => {
  return stringToHslColor(str, 60, 70)
}
