import React, { Component } from 'react'

import { GridList, GridTile } from 'material-ui/GridList'

import { API_HOST } from '../api'

import SocialButton from '../components/SocialButton'

class WizardSocial extends Component {
  state = {
  }

  componentWillMount() {
  }

  login(type) {
    // check if no need
    if (this.props[type] !== '') {
      return
    }

    let callback = window.location.origin

    // fix linkedin callback cant have fragment
    if (type === 'linkedin') {
      callback += '?redirect=/influencer/overview&type=' + type
    } else {
      callback += '/#/redirect/influencer/overview?type=' + type
    }

    let redir = API_HOST + '/' + type + '/login?callback=' + encodeURIComponent(callback)

    window.location = redir
  }

  render() {
    return (
      <div>
        <p>Connect your social media accounts to reach more people and earn more money</p>
        <br />
        <br />
        <GridList cols={3} cellHeight={100} style={{textAlign: 'center'}}>
          <GridTile>
            <SocialButton name="facebook" connected={this.props.facebook === '' ? false : true}
              onClick={this.login.bind(this, 'facebook')} />
            {this.props.facebook === '' ? (
              <p>Connect to Facebook Now</p>
            ) : (
              <p>Connected to Facebook as <br /><strong>{this.props.facebook}</strong></p>
            )}
          </GridTile>
          <GridTile>
            <SocialButton name="twitter" connected={this.props.twitter === '' ? false : true}
              onClick={this.login.bind(this, 'twitter')} />
            {this.props.twitter === '' ? (
              <p>Connect to Twitter Now</p>
            ) : (
              <p>Connected to Twitter as <br /><strong>{this.props.twitter}</strong></p>
            )}
          </GridTile>
          <GridTile>
            <SocialButton name="linkedin" connected={this.props.linkedin === '' ? false : true}
              onClick={this.login.bind(this, 'linkedin')} />
            {this.props.linkedin === '' ? (
              <p>Connect to Linkedin Now</p>
            ) : (
              <p>Connected to Linkedin as <br /><strong>{this.props.linkedin}</strong></p>
            )}
          </GridTile>
        </GridList>
      </div>
    )
  }
}

export default WizardSocial
