import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setTitle } from '../utils'
import { getOverviewScore, clearNotif } from '../actions/overview'

import Notify from '../components/Notify'
import PreLoader from '../components/PreLoader'
import OverviewClick from '../containers/OverviewClick'
import OverviewSource from '../containers/OverviewSource'
import OverviewPlatform from '../containers/OverviewPlatform'
import OverviewScore from './OverviewScore'
import EmptyOverview from './EmptyOverview'
import OverviewInfluencer from './OverviewInfluencer'

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
  }
}

const title = 'Overview'

class Overview extends Component {
  state = {
    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    setTitle(title)

    this.props.getOverviewScore()
  }

  componentWillReceiveProps(nextProps) {
    let { overview } = nextProps

    this.setState({
      notifyOpen: overview.notify.open,
      notifyMessage: overview.notify.message,
    })
  }

  render() {
    let { overview } = this.props
    let campaign = overview.score.total_campaign

    if (campaign === undefined) {
      return (<PreLoader open={true} />)
    }

    return (
      <section className="content-wrapper">
        <PreLoader open={overview.loading} />

        {campaign === 0 ? (
          <EmptyOverview />
        ) : (
          <div>
            <OverviewScore />
            <br />
            <OverviewClick />
            <br />
            <OverviewSource />
            <br />
            <OverviewPlatform />
            <br />
            <OverviewInfluencer />
            <br />
          </div>
        )}

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewScore,
  clearNotif,
})(Overview)
