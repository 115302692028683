import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getUser } from '../actions/user'
import { getTwitter } from '../actions/twitter'
import { getPayment } from '../actions/payment'
import { getFacebook } from '../actions/facebook'
import { getLinkedin  } from '../actions/linkedin'
import { findCategory } from '../actions/category'
import { getTransactionScore } from '../actions/transaction'
import { setContext, getAuth, isMobile, redirect, viewPortRefresher }
  from '../utils'

import Nav from './Nav'
import Wizard from './Wizard'
import TitleBar from './TitleBar'
import Theme from './Theme'
import Notification from '../containers/Notification'
import UserErrorDialog from '../containers/UserErrorDialog'

const styles = {
  container: {
    marginLeft: isMobile() ? 0 : 256,
  },
  menu: {
    textDecoration: 'none',
  },
  content: {
    padding: 20,
    paddingTop: 70,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    payment: state.payment,
    transaction: state.transaction,
  }
}

class Root extends Component {
  state = {
    notifDrawerOpen: false,
    navDrawerOpen: !isMobile(),
    wizardMode: false,
  }

  constructor(props) {
    super(props)

    this.notifToggler = this.notifToggler.bind(this)
    this.navToggler = this.navToggler.bind(this)
  }

  componentWillMount() {
    setContext(this.context)

    // check type
    if (getAuth().type !== 'influencer') {
      redirect('/error')

      return
    }

    // get user
    this.props.getUser()

    // bootstrap
    this.props.findCategory()
    this.props.getPayment()
    this.props.getTransactionScore()

    // request for social intrations
    // this.props.getFacebook('?scrape')
    // this.props.getTwitter('?scrape')
    // this.props.getLinkedin('?scrape')
    this.props.getFacebook()
    this.props.getTwitter()
    this.props.getLinkedin()
  }

  componentWillReceiveProps(nextProps) {
    const { user, payment } = nextProps
    // check if current has no error
    if (user.error || user.loading || payment.loading) {
      return
    }

    // wizard mode!
    if (!payment.detail.hasOwnProperty('id')) {
      this.setState({
        wizardMode: true,
      })
    }
  }

  componentDidMount() {
    viewPortRefresher()
  }

  notifToggler() {
    this.setState({
      notifDrawerOpen: !this.state.notifDrawerOpen
    })
  }

  navToggler() {
    // dont toggle if not mobile
    if (!isMobile()) {
      return
    }

    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    })
  }

  render() {
    // let { user } = this.props

    return (
      <Theme>
        <div style={styles.container}>
          <UserErrorDialog />

          {this.state.wizardMode ? (<Wizard />) : null}

          <TitleBar onBellClick={this.notifToggler}
            onMenuClick={this.navToggler} />

          <Nav open={this.state.navDrawerOpen} toggle={this.navToggler} />

          <Notification open={this.state.notifDrawerOpen}
            onRequestClose={this.notifToggler} />

          <section style={styles.content}>
            {this.props.children}
          </section>
        </div>
      </Theme>
    )
  }
}

Root.contextTypes = {
  router: PropTypes.object,
}

export default connect(mapStateToProps, {
  getUser,
  findCategory,
  getTransactionScore,
  getPayment,
  getFacebook,
  getTwitter,
  getLinkedin,
})(Root)
