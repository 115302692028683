import * as twitterAction from '../actions/twitter'
import { setAuth } from '../utils'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // login
    case `${twitterAction.LOGIN_TWITTER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${twitterAction.LOGIN_TWITTER}_FULFILLED`:
      // saving credentials
      setAuth(action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${twitterAction.LOGIN_TWITTER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // connect
    case `${twitterAction.CONNECT_TWITTER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${twitterAction.CONNECT_TWITTER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${twitterAction.CONNECT_TWITTER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get twitter
    case `${twitterAction.GET_TWITTER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${twitterAction.GET_TWITTER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${twitterAction.GET_TWITTER}_REJECTED`:
      let notify = {
        open: true,
        message: action.payload.response.data.msg
      }

      // do not throw this error because its just get
      if (action.payload.response.data.name === 'TWITTER_RESOURCE_DETAIL_ERR') {
        notify.open = false
      }

      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify,
      })

    // remove
    case `${twitterAction.REMOVE_TWITTER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${twitterAction.REMOVE_TWITTER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: {},
        notify: {
          open: true,
          message: 'Twitter successfully removed'
        },
      })
    case `${twitterAction.REMOVE_TWITTER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case twitterAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })
    // no default
  }

  return state
}
