import React, { Component } from 'react'

import numeral from 'numeral'
import TextField from 'material-ui/TextField'
import Subheader from 'material-ui/Subheader'
import { List, ListItem } from 'material-ui/List'
import { GridList, GridTile } from 'material-ui/GridList'
import ClearIcon from 'material-ui/svg-icons/content/clear'

import { getInitials } from '../utils'

import Note from '../components/Note'
import Score from '../components/Score'
import AvatarV2 from './AvatarV2'

class CampaignCreateCost extends Component {
  state = {
    type: '',
    influencers: [],

    max_click: 0,
    click_rate: 0,
  }

  componentWillMount() {
    // get data from props
    let { type, influencers, clickRate, maxClick } = this.props

    this.setState({
      type,
      influencers,
      max_click: parseInt(maxClick, 10),
      click_rate: parseFloat(clickRate),
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value

    this.setState(state)

    // value modifier
    switch (field) {
    case 'click_rate':
      // revert value
      value = parseFloat(value).toFixed(2)
      break;
    case 'max_click':
      // revert value
      value = parseInt(value, 10)
      break;
    // no default
    }

    this.props.onChange(field, value)
  }

  removeInfluncer(index) {
    let influencers = this.state.influencers
    influencers.splice(index, 1)

    this.setState({
      influencers,
    })

    this.props.onChange('influencers', influencers)
  }

  render() {
    return (
      <div>
        <GridList cols={2} cellHeight={50} >
          <GridTile>
            <Score title="Influencer" value={this.state.influencers.length} />
          </GridTile>
          <GridTile>
            <Score title="Total Cost"
              value={numeral(this.props.price).format('0,0.00')} />
          </GridTile>
        </GridList>
        {this.state.type === 'PPP' ? null : (
          <div>
            <br />
            <TextField
              floatingLabelText="Target clicks"
              floatingLabelFixed={true}
              style={{width: '49%'}}
              type="number"
              value={this.state.max_click}
              onChange={this.onChange.bind(this, 'max_click')} />
            <TextField
              floatingLabelText="$ Cost per click"
              floatingLabelFixed={true}
              style={{width: '49%', float: 'right'}}
              hintText="0.00"
              type="number"
              step="0.01"
              value={this.state.click_rate}
              onChange={this.onChange.bind(this, 'click_rate')} />
            <br />
            <br />
            <Note>
              Maxed-out campaign will still work but MashDrop banner will flash for 10 seconds. <a href="/#/advertiser/guide?slug=maxed-out-campaigns" target="_blank">
              Learn more</a>
            </Note>
          </div>
        )}
        <br />
        <Subheader>Influencer</Subheader>
        <div>
          <List>
            {this.state.influencers === undefined ? null :
              this.state.influencers.map((item, index) => (
                <ListItem
                  key={index}
                  primaryText={item.name}
                  secondaryText={this.props.type === 'PPC' ? '' :
                    numeral(item.fee).format('0,0.00') + ' per post'}
                  leftAvatar={<AvatarV2 src={item.image} alt={getInitials(item.name || '-')} />}
                  rightIcon={<ClearIcon style={{
                    display: this.state.disableSaveCost ? 'none' : 'inline',
                  }}
                  onClick={this.removeInfluncer.bind(this, index)} />} />
              ))
            }
          </List>
        </div>
      </div>
    )
  }
}

export default CampaignCreateCost
