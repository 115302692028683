import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Chart } from 'react-google-charts'
import { Card, CardHeader, CardText } from 'material-ui/Card'

import { getCampaignStatSource } from '../actions/campaign_stat'
import { parseGraphSource } from '../utils/parse'

import DateScope from '../components/DateScope'

const styles = {
  cardHeader: {
    float: 'left',
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    campaignStat: state.campaign_stat,
  }
}

const options = {
  hAxis: {title: 'Date'},
  vAxis: {title: 'Source'},
}

const dataHeader = ['Date', 'Facebook', 'Twitter', 'LinkedIn']

class CampaignStatSource extends Component {
  state = {
    data: [dataHeader],
  }

  constructor(props) {
    super(props)

    this.changeDateScope = this.changeDateScope.bind(this)
  }

  componentWillMount() {
    this.props.getCampaignStatSource(this.props.id)
  }

  componentWillReceiveProps(nextProps) {
    let { campaignStat } = nextProps

    // parse data
    let data = parseGraphSource(campaignStat.source)

    this.setState({
      data,
    })
  }

  changeDateScope(value) {
    this.props.getCampaignStatSource(this.props.id, value)
  }

  render() {
    return (
      <Card className="card-override" expanded={true}>
        <CardHeader title="Click Sources" style={styles.cardHeader}
          subtitle="source of clicks base on platform" />

        <DateScope onChange={this.changeDateScope} />

        <CardText>
          <Chart chartType="AreaChart" data={this.state.data}
            options={options} width={"100%"}
            height={"300px"} legend_toggle={true} />
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getCampaignStatSource,
})(CampaignStatSource)
