import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardHeader, CardText } from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'

import { getCampaignStatScore } from '../actions/campaign_stat'

import Score from '../components/Score'

const mapStateToProps = (state, ownProps) => {
  return {
    campaignStat: state.campaign_stat,
  }
}

class CampaignStatScore extends Component {
  state = {
    score: {},
  }

  componentWillMount() {
    this.props.getCampaignStatScore(this.props.id)
  }

  componentWillReceiveProps(nextProps) {
    let { campaignStat } = nextProps

    this.setState({
      score: campaignStat.score,

      // notifyOpen: campaignStat.notify.open,
      // notifyMessage: campaignStat.notify.message,
    })
  }

  render() {
    return (
      <Card className="card-override">
        <CardHeader title="Dashboard" />
        <CardText>
          <GridList cols={5} cellHeight={50}>
            <GridTile>
              <Score title={'Clicks'} value={this.state.score.clicks || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Unique Clicks'} value={this.state.score.unique_clicks || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Likes'} value={this.state.score.likes || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Comments'} value={this.state.score.comments || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Shares'} value={this.state.score.shares || '-'} />
            </GridTile>
          </GridList>
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getCampaignStatScore,
})(CampaignStatScore)
