import React from 'react'
import { render } from 'react-dom'
import { Router, hashHistory, applyRouterMiddleware } from 'react-router'
import { Provider } from 'react-redux'
import { useScroll } from 'react-router-scroll'

import stores from './stores'
import routes from './routes'

// Render the main app react component into the app div.
// For more details see: https://facebook.github.io/react/docs/top-level-api.html#react.render
// render(<Root/>, document.getElementById('app'))

render(
  <Provider store={stores}>
    <Router history={hashHistory}
      render={applyRouterMiddleware(useScroll())}>
      {routes}
    </Router>
  </Provider>
  , document.getElementById('app'))
