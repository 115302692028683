import * as influencerCampaignAction from '../actions/influencer_campaign'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  list: [],
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find influencer campaign
    case `${influencerCampaignAction.FIND_INFLUENCER_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        detail: {},
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${influencerCampaignAction.FIND_INFLUENCER_CAMPAIGN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data.data,
        meta: {
          result_count: action.payload.data.result_count,
          total_count: action.payload.data.total_count,
        },
      })
    case `${influencerCampaignAction.FIND_INFLUENCER_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get influencer campaign
    case `${influencerCampaignAction.GET_INFLUENCER_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${influencerCampaignAction.GET_INFLUENCER_CAMPAIGN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${influencerCampaignAction.GET_INFLUENCER_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // influencer campaign
    case influencerCampaignAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
