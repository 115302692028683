import api from '../api'

/*
 *  action constants
 */

export const GET_OVERVIEW  = 'GET_OVERVIEW'
export const GET_OVERVIEW_SCORE  = 'GET_OVERVIEW_SCORE'
export const GET_OVERVIEW_CLICK  = 'GET_OVERVIEW_CLICK'
export const GET_OVERVIEW_SOURCE  = 'GET_OVERVIEW_SOURCE'
export const GET_OVERVIEW_PLATFORM  = 'GET_OVERVIEW_PLATFORM'
export const GET_OVERVIEW_INFLUENCER = 'GET_OVERVIEW_INFLUENCER'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const getOverview = () => (dispatch) => {
   dispatch({
     type: GET_OVERVIEW,
     payload: api.get('/overview'),
   })
 }

export const getOverviewScore = () => (dispatch) => {
  dispatch({
    type: GET_OVERVIEW_SCORE,
    payload: api.get('/overview/score'),
  })
}

export const getOverviewClick = (dateScope) => (dispatch) => {
  dispatch({
    type: GET_OVERVIEW_CLICK,
    payload: api.get('/overview/click' + parseDateScope(dateScope)),
  })
}

export const getOverviewSource = (dateScope) => (dispatch) => {
  dispatch({
    type: GET_OVERVIEW_SOURCE,
    payload: api.get('/overview/source' + parseDateScope(dateScope)),
  })
}

export const getOverviewPlatform = (dateScope) => (dispatch) => {
  dispatch({
    type: GET_OVERVIEW_PLATFORM,
    payload: api.get('/overview/platform' + parseDateScope(dateScope)),
  })
}

export const getOverviewInfluencer = (dateScope) => (dispatch) => {
  dispatch({
    type: GET_OVERVIEW_INFLUENCER,
    payload: api.get('/overview/influencer' + parseDateScope(dateScope)),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}

const parseDateScope = (scope) => {
  scope = scope || ''

  return '?date_scope=' + scope
}
