import api from '../api'
import { formatter } from '../utils/campaign'

/*
 *  action constants
 */

export const FIND_CAMPAIGN  = 'FIND_CAMPAIGN'
export const GET_CAMPAIGN  = 'GET_CAMPAIGN'
export const CREATE_CAMPAIGN  = 'CREATE_CAMPAIGN'
export const UPDATE_CAMPAIGN  = 'UPDATE_CAMPAIGN'
export const DELETE_CAMPAIGN  = 'DELETE_CAMPAIGN'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findCampaign = (filter) => (dispatch) => {
  let params = '?meta&score'
  // params += '&order=created_at,desc'

  params += filter

  dispatch({
    type: FIND_CAMPAIGN,
    payload: api.get('/campaign' + params),
  })
}

export const getCampaign = (id) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN,
    payload: api.get('/campaign/' + id),
  })
}

export const createCampaign = (data) => (dispatch) => {
  dispatch({
    type: CREATE_CAMPAIGN,
    payload: api.post('/campaign', formatter(data, true)),
  })
}

export const updateCampaign = (id, data) => (dispatch) => {
  dispatch({
    type: UPDATE_CAMPAIGN,
    payload: api.patch('/campaign/' + id, formatter(data, true)),
  })
}

export const deleteCampaign = (id) => (dispatch) => {
  dispatch({
    type: DELETE_CAMPAIGN,
    payload: api.delete('/campaign/' + id),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
