import React, { Component } from 'react'
import { connect } from 'react-redux'

import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'

import { getPayment, updatePayment, clearNotif } from '../actions/payment'
import { formatAmount, isEmail } from '../utils'

import Note from '../components/Note'
import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'

const mapStateToProps = (state, ownProps) => {
  return {
    payment: state.payment,
  }
}

// const styles = {
//   block: {
//     maxWidth: 250,
//   },
//   checkbox: {
//     marginBottom: 16,
//   },
// }

class AccountPayment extends Component {
  state = {
    postFee: 0,
    postNote: '',
    paymentEmail: '',
    isAmerican: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    this.props.getPayment()
  }

  componentWillReceiveProps(nextProps) {
    let { payment } = nextProps

    // format fee
    let postFee = payment.detail.post_fee

    postFee = parseFloat(formatAmount(postFee))

    this.setState({
      postFee,
      postNote: payment.detail.post_note,
      paymentEmail: payment.detail.paypal_email,
      isAmerican: payment.detail.is_american_citizen || false,

      notifyOpen: payment.notify.open,
      notifyMessage: payment.notify.message,
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value
    this.setState(state)
  }

  toggleCheck() {
    let state = this.state

    state.isAmerican = !this.state.isAmerican
    this.setState(state)
  }

  onSave() {
    let { postFee, postNote, paymentEmail, isAmerican } = this.state

    // checking email
    if (!isEmail(paymentEmail)) {
      this.setState({
        notifyOpen: true,
        notifyMessage: 'paypal email must be valid',
      })

      return
    }

    // checking post fee
    if (postFee === 0) {
      this.setState({
        notifyOpen: true,
        notifyMessage: 'post fee cannot be zero',
      })

      return
    }

    this.props.updatePayment({
      post_fee: parseInt(parseFloat(postFee) * 100, 10),
      post_note: postNote,
      paypal_email: paymentEmail,
      is_american_citizen: isAmerican,
    })
  }

  render() {
    let { payment } = this.props

    return (
      <div>
        <Card className="card-override">
          <ProgressBar open={payment.loading} />
          <CardHeader subtitle="Payment Setup" />
          <CardText>
            <TextField floatingLabelFixed={true}
              floatingLabelText="Posting Fee"
              style={{width: '49%'}}
              type="number"
              value={this.state.postFee || 0}
              onChange={this.onChange.bind(this, 'postFee')} />
            <TextField floatingLabelFixed={true}
              floatingLabelText="Paypal Account Email"
              style={{width: '49%', float: 'right'}}
              value={this.state.paymentEmail || ''}
              onChange={this.onChange.bind(this, 'paymentEmail')} />
            <br />
            <TextField fullWidth={true} floatingLabelFixed={true}
              floatingLabelText="Profile Note"
              hintText="Indicate willingness to add comments and endorsement"
              value={this.state.postNote || ''}
              onChange={this.onChange.bind(this, 'postNote')} />
            <br />
            <br />
            <div style={{
                maxWidth: 250,
              }}>
              <Checkbox
                checked={this.state.isAmerican || false}
                label="American Citizen"
                style={{ marginBottom: 16 }}
                onClick={this.toggleCheck.bind(this)}
              />
            </div>
            <Note>Payments will submit to this account when reached payment threshold</Note>
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            <FlatButton label="Save" primary={true}
              onClick={this.onSave.bind(this)} />
          </CardActions>
        </Card>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getPayment,
  updatePayment,
  clearNotif,
})(AccountPayment)
