import React, {Component} from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import TextField from 'material-ui/TextField'
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import { createPasswordReset, clearNotif } from '../actions/password_reset'

import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'

const styles = {
  card: {
    marginTop: 20,
    marginBottom: 20,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    passwordReset: state.password_reset,
  }
}

class Login extends Component {
  state = {
    email: '',

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillReceiveProps(nextProps) {
    let { passwordReset } = nextProps

    this.setState({
      notifyOpen: passwordReset.notify.open,
      notifyMessage: passwordReset.notify.message,
    })
  }

  onReset() {
    if (this.state.email === '') {
      this.setState({
        notifyOpen: true,
        notifyMessage: 'email field is required',
      })

      return
    }

    this.props.createPasswordReset({
      email: this.state.email,
      callback: window.location.origin + '/#/advertiser/password',
    })
  }

  render() {
    let { passwordReset } = this.props

    return (
      <div>
        <Card className="card-override" style={styles.card}>
          <ProgressBar open={passwordReset.loading} />

          <CardHeader title="MashDrop Advertiser"
            subtitle="Reset password request" />
          <CardText>
            <TextField fullWidth={true}
              floatingLabelFixed={true}
              floatingLabelText="Email"
              defaultValue={this.state.email}
              onChange={(e) => {this.setState({email: e.target.value })}} />
            <br />
            Note: this will send email confirmation to a valid account only.
          </CardText>
          <CardActions style={{textAlign: 'center'}}>
            <RaisedButton label="Reset" primary={true}
              onClick={this.onReset.bind(this)} />
          </CardActions>
        </Card>

        <Link to="/advertiser/login">
          <FlatButton label="Do you remember now?" />
        </Link>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  createPasswordReset,
  clearNotif,
})(Login)
