import React, { Component } from 'react'
import { connect } from 'react-redux'

import numeral from 'numeral'
import Dropzone from 'react-dropzone'
import { Card, CardActions, CardHeader, CardText, CardMedia }
  from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'
import { List, ListItem } from 'material-ui/List'
import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Avatar from 'material-ui/Avatar'
import Subheader from 'material-ui/Subheader'
import ClearIcon from 'material-ui/svg-icons/content/clear'
import StatsIcon from 'material-ui/svg-icons/av/equalizer'
import FloatingActionButton from 'material-ui/FloatingActionButton'


import { getCampaign, createCampaign, updateCampaign, deleteCampaign, clearNotif }
  from '../actions/campaign'
import { formatDate, redirect, setTitle } from '../utils'
import { upload } from '../utils/upload'
import { calcTotalPrice, validatePPCMinPriceReq, validateInflu }
  from '../utils/campaign'

import Score from '../components/Score'
import Notify from '../components/Notify'
import PreLoader from '../components/PreLoader'
import ProgressBar from '../components/ProgressBar'
import StatusLabel from '../components/StatusLabel'
import ConfirmDialog from '../components/ConfirmDialog'

const mapStateToProps = (state, ownProps) => {
  return {
    campaign: state.campaign,
  }
}

class CampaignView extends Component {
  state = {
    detail: {
      influencers: [],
    },

    uploadProgress: 0,
    files: [],

    renewing: false,

    disableSave: false,
    disableSaveCost: true,
    disableTerminate: false,
    disableDelete: false,
    disableRenew: false,
    disablePublish: true,
    disableCostAction: false,

    notifyOpen: false,
    notifyMessage: '',

    confirmRenewOpen: false,
    confirmTerminateOpen: false,
  }

  componentDidMount() {
    let id = this.props.params.id

    this.checkCostActionDisablity = this.checkCostActionDisablity.bind(this)

    // get detail
    this.props.getCampaign(id + '?invitation')
  }

  componentWillReceiveProps(nextProps) {
    let { campaign } = nextProps

    // prioritize redirect
    if (campaign.payment.approval_url !== '' && campaign.payment.approval_url !== undefined) {
      window.location = campaign.payment.approval_url

      return
    }

    // we have the profile
    if (campaign.detail.id !== undefined) {
      // in the process of renewing
      if (this.state.renewing) {
        this.setState({
          renewing: false,
        })

        redirect('/advertiser/campaign/' + campaign.detail.id + '/view?renew=true')

        return
      }

      setTitle(campaign.detail.title)

      let {
        disableSave,
        disableSaveCost,
        disableTerminate,
        disableDelete,
        disableRenew,
        disablePublish } = this.state

      switch (campaign.detail.status) {
      case 'draft':
        disableTerminate = true
        disableRenew = true
        disablePublish = false
        disableSaveCost = false
        break
      case 'pending':
        disableTerminate = true
        disableDelete = true
        break
      case 'published':
        disableDelete = true
        disableRenew = true
        break
      case 'terminated':
      case 'completed':
        disableRenew = false
        disableSave = true
        disableTerminate = true
        disableDelete = true
        break
      // no default
      }

      if (campaign.detail.type === 'PPP') {
        disableRenew = false
      }

      this.setState({
        detail: campaign.detail,

        // button states
        disableSave,
        disableSaveCost,
        disableDelete,
        disableTerminate,
        disableRenew,
        disablePublish,

        notifyOpen: campaign.notify.open,
        notifyMessage: campaign.notify.message,
      })
    }
  }

  changeImage(files) {
    if (files.length < 0) {
      return
    }

    let { detail } = this.state

    detail.image = files[0].preview

    // trigger upload
    upload(files[0], (path) => {
      detail.image = path

      this.setState({ detail })
    }, (err) => {
      // handle error
    }, (progress) => {
      // handle progress
      this.setState({
        uploadProgress: progress,
      })
    })

    this.setState({
      files,
      detail,
    })
  }

  selectFile() {
    this.dropzone.open();
  }

  updateInfo() {
    let detail = this.state.detail
    let data = {
      title: detail.title,
      description: detail.description,
      image: detail.image,
      url: detail.url,
      note: detail.note,
    }

    this.props.updateCampaign(detail.id, data)
  }

  updateCost() {
    let data = {}
    let { detail } = this.state

    // update cost setting
    data.influencers = detail.influencers

    if ('max_click' in detail) {
      data.max_click = parseInt(detail.max_click, 10)
    }

    if ('click_rate' in detail) {
      data.click_rate = parseFloat(detail.click_rate)
    }

    this.props.updateCampaign(detail.id, data)
  }

  checkCostActionDisablity(detail) {
    let disableCostAction = false

    if (!validateInflu(detail.influencers)) {
      disableCostAction = true
    }

    if (detail.type === 'PPC'
    && !validatePPCMinPriceReq(detail.max_click, detail.click_rate)) {
      disableCostAction = true
    }

    if (detail.price === 0) {
      disableCostAction = true
    }

    this.setState({
      disableCostAction,
    })
  }

  deleteCampaign() {
    this.props.deleteCampaign(this.state.detail.id)

    redirect('/advertiser/campaign')
  }

  terminateCampaign() {
    let data = {
      status: 'terminated',
    }

    this.updateCost()

    this.props.updateCampaign(this.state.detail.id, data)
  }

  publishCampaign() {
    // update cost
    let { detail } = this.state
    let data = {
      status: 'published',
    }

    // update cost setting
    data.influencers = detail.influencers

    if ('max_click' in detail) {
      data.max_click = parseInt(detail.max_click, 10)
    }

    if ('click_rate' in detail) {
      data.click_rate = parseFloat(detail.click_rate)
    }

    this.props.updateCampaign(detail.id, data)
  }

  renewCampaign() {
    let { detail } = this.state
    let data = {
      type: detail.type,
      title: detail.title,
      description: detail.description,
      url: detail.url,
      image: detail.image,
      note: detail.note,

      max_click: parseInt(detail.max_click, 10),
      click_rate: parseFloat(detail.click_rate),
      influencers: detail.influencers,
    }

    // set as draft
    data.status = 'draft'

    this.setState({
      renewing: true,
    })

    this.props.createCampaign(data)
  }

  onDetailChange(field, e) {
    let { detail } = this.state
    let value = e.target.value

    // override new value
    detail[field] = value

    // re-calculate when click_rate or max_click changed for PPC
    if (['click_rate', 'max_click', 'influencers'].indexOf(field) !== -1) {
      detail.price = calcTotalPrice(
        detail.type,
        detail.influencers,
        detail.max_click,
        detail.click_rate,
      )

      this.checkCostActionDisablity(detail)
    }

    this.setState({
      detail,
    })
  }

  removeInfluncer(index) {
    let { detail } = this.state
    detail.influencers.splice(index, 1)
    detail.price = calcTotalPrice(
      detail.type,
      detail.influencers,
      detail.max_click,
      detail.click_rate,
    )

    this.checkCostActionDisablity(detail)

    this.setState({
      detail,
    })
  }

  getNameInitials(name) {
    let initials = name.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  toggleConfirmDialog() {
    this.setState({
      confirmRenewOpen: !this.state.confirmRenewOpen
    })
  }

  toggleTerminateDialog() {
    this.setState({
      confirmTerminateOpen: !this.state.confirmTerminateOpen
    })
  }

  render() {
    const { campaign } = this.props
    let { detail, uploadProgress, confirmRenewOpen, confirmTerminateOpen } = this.state
    const uploading = uploadProgress > 1 && uploadProgress < 99

    return (
      <section className="content-wrapper">
        <PreLoader open={campaign.loading} />

        <ConfirmDialog open={confirmRenewOpen}
          title="Campaign Renewal"
          body={<span>Creates a new campaign with identical details with zero metrics and save as a <strong>Draft</strong>.</span>}
          onClose={this.toggleConfirmDialog.bind(this)}
          onConfirm={this.renewCampaign.bind(this)} />

        <ConfirmDialog open={confirmTerminateOpen}
          title="Campaign Termination"
          confirmLabel="Terminate"
          body={<span><strong>WARNING!</strong> campaign will stop receiving updates and remaining balance will be refunded.</span>}
          onClose={this.toggleTerminateDialog.bind(this)}
          onConfirm={this.terminateCampaign.bind(this)} />

        <Dropzone ref={(node) => { this.dropzone = node; }}
          style={{display: 'none'}}
          multiple={false}
          accept={'image/*'}
          onDrop={this.changeImage.bind(this)} />

        <Card className="card-override" expanded={true}>
          <CardHeader title="Information" />
          <CardText>
            <CardMedia mediaStyle={{maxWidth: 800, margin: '0 auto'}}>
              <img src={detail.image || null} alt="" />

              <ProgressBar value={uploadProgress} />

              <div style={{textAlign: 'right'}}>
                <FlatButton label="Change"
                  disabled={detail.status === 'draft' ? false : true}
                  onClick={this.selectFile.bind(this)} />
              </div>
            </CardMedia>
            <TextField floatingLabelText="Title"
              floatingLabelFixed={true}
              fullWidth={true}
              value={detail.title || '-'}
              onChange={this.onDetailChange.bind(this, 'title')} />
            <br />
            <TextField
              floatingLabelText="Description"
              floatingLabelFixed={true}
              multiLine={true}
              fullWidth={true}
              value={detail.description || '-'}
              onChange={this.onDetailChange.bind(this, 'description')} />
            <br />
            <TextField
              floatingLabelText="Note to Influencer"
              floatingLabelFixed={true}
              multiLine={true}
              fullWidth={true}
              value={detail.note || '-'}
              onChange={this.onDetailChange.bind(this, 'note')} />
            <br />
            <TextField floatingLabelText="Link"
              floatingLabelFixed={true}
              fullWidth={true}
              value={detail.url || '-'}
              onChange={this.onDetailChange.bind(this, 'url')} />
            <br />
            <TextField
              disabled
              floatingLabelText="Status"
              floatingLabelFixed={true}
              style={{width: '49%'}}
              value={detail.status || '-'}
            />
            <TextField
              disabled
              floatingLabelText="Type"
              floatingLabelFixed={true}
              style={{width: '49%', float: 'right'}}
              value={detail.type || '-'}
            />
            <br />
            <TextField
              disabled
              floatingLabelText="Date Created"
              floatingLabelFixed={true}
              style={{width: '49%'}}
              value={formatDate(detail.created_at) || '-'}
            />
            <TextField
              disabled
              floatingLabelText="Date Updated"
              floatingLabelFixed={true}
              style={{width: '49%', float: 'right'}}
              value={formatDate(detail.updated_at) || '-'}
            />
          </CardText>
          <CardActions>
            <div style={{textAlign: 'right'}}>
              <FlatButton label="Delete"
                style={{
                  display: this.state.disableDelete ? 'none' : 'inline',
                }}
                disabled={uploading ? true : false}
                onClick={this.deleteCampaign.bind(this)} />
              <FlatButton label="Terminate"
                style={{
                  display: this.state.disableTerminate ? 'none' : 'inline',
                }}
                disabled={uploading ? true : false}
                onClick={this.toggleTerminateDialog.bind(this)} />
              <FlatButton label="Save" primary={true}
                style={{
                  display: this.state.disableSave ? 'none' : 'inline',
                }}
                disabled={uploading ? true : false}
                onClick={this.updateInfo.bind(this)} />
              <FlatButton label="Renew" secondary={true}
                style={{
                  display: this.state.disableRenew ? 'none' : 'inline',
                }}
                disabled={uploading ? true : false}
                onClick={this.toggleConfirmDialog.bind(this)} />
            </div>
          </CardActions>
        </Card>
        <br />
        <Card className="card-override" expanded={true}>
          <CardHeader title="Cost" />
          <CardText>
            <GridList cols={2} cellHeight={50} >
              <GridTile>
                <Score title="Influencers" value={detail.influencers === undefined ? {} : (
                    detail.influencers.length
                  )} />
              </GridTile>
              <GridTile>
                <Score title="Total Cost"
                  value={numeral(detail.price).format('0,0.00')} />
              </GridTile>
            </GridList>
            {detail.type === 'PPP' ? (<br />) : (
              <div>
                <br />
                <TextField
                  disabled={this.state.disableSaveCost}
                  floatingLabelText="Target clicks"
                  floatingLabelFixed={true}
                  style={{width: '49%'}}
                  type="number"
                  value={detail.max_click}
                  onChange={this.onDetailChange.bind(this, 'max_click')} />
                <TextField
                  disabled={this.state.disableSaveCost}
                  floatingLabelText="$ Cost per click"
                  floatingLabelFixed={true}
                  style={{width: '49%', float: 'right'}}
                  hintText="0.00"
                  type="number"
                  step="0.01"
                  value={detail.click_rate}
                  onChange={this.onDetailChange.bind(this, 'click_rate')} />
                <br />
                <br />
              </div>
            )}
            <Subheader>Selected Influencers</Subheader>
            <div style={{
                maxHeight: (72 * 4),
                overflow: 'scroll'
              }}>
              <List>
                {detail.influencers === undefined ? {} :
                  detail.influencers.map((item, index) => (
                    <ListItem
                      key={index}
                      primaryText={<div>{item.name} <span style={{float: 'right'}}><StatusLabel status={item.status} /></span></div>}
                      secondaryText={
                        item.fee === undefined ? '' : numeral(item.fee).format('0,0.00') + ' per post '
                      }
                      leftAvatar={item.image === undefined ? (
                        <Avatar>{this.getNameInitials(item.name)}</Avatar>
                      ) : (
                        <Avatar src={item.image} />
                      ) }
                      rightIcon={<ClearIcon style={{
                        display: this.state.disableSaveCost ? 'none' : 'inline',
                      }}
                      onClick={this.removeInfluncer.bind(this, index)} />} />
                  ))
                }
              </List>
            </div>
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            <FlatButton label="Save" primary={true} style={{
                display: this.state.disableSaveCost ? 'none' : 'inline-block',
              }}
              disabled={this.state.disableCostAction}
              onClick={this.updateCost.bind(this)} />
            <RaisedButton label={'Publish for ' + numeral(detail.price).format('0,0.00')} primary={true} style={{
                display: this.state.disablePublish ? 'none' : 'inline-block',
              }}
              disabled={this.state.disableCostAction}
              onClick={this.publishCampaign.bind(this)} />
          </CardActions>
        </Card>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />

        <FloatingActionButton href={'/#/advertiser/campaign/' + this.props.params.id + '/stats'}
          style={{
            position: 'fixed',
            right: 20,
            bottom: 20,
          }}>
          <StatsIcon />
        </FloatingActionButton>
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  getCampaign,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  clearNotif,
})(CampaignView)
