import * as transactionAction from '../actions/transaction'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  list: [],
  detail: {},
  score: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find transaction
    case `${transactionAction.FIND_TRANSACTION}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${transactionAction.FIND_TRANSACTION}_FULFILLED`:
      // catch error
      if (action.payload.data.data === undefined) {
        return Object.assign({}, state, {
          loading: false,
          error: true,
          notify: {
            open: true,
            message: 'unexpected error please try again'
          },
        })
      }

      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data.data,
        meta: {
          result_count: action.payload.data.result_count,
          total_count: action.payload.data.total_count,
        },
      })
    case `${transactionAction.FIND_TRANSACTION}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get transaction
    case `${transactionAction.GET_TRANSACTION}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${transactionAction.GET_TRANSACTION}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${transactionAction.GET_TRANSACTION}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get transaction score
    case `${transactionAction.GET_TRANSACTION_SCORE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${transactionAction.GET_TRANSACTION_SCORE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        score: action.payload.data,
      })
    case `${transactionAction.GET_TRANSACTION_SCORE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case transactionAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
