import api from '../api'

/*
 *  action constants
 */

export const GET_SETTING  = 'GET_SETTING'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const getSetting = () => (dispatch) => {
  dispatch({
    type: GET_SETTING,
    payload: api.get('/setting'),
  })
}


export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
