import { combineReducers } from 'redux'

import app from './app'
import user from './user'
import transaction from './transaction'
import overview from './overview'
import notification from './notification'
import campaign from './campaign'
import campaign_stat from './campaign_stat'
import influencer from './influencer'
import category from './category'
import facebook from './facebook'
import twitter from './twitter'
import linkedin from './linkedin'
import audience from './audience'
import payment from './payment'
import charity from './charity'
import autopost from './autopost'
import invitation from './invitation'
import post from './post'
import influencer_campaign from './influencer_campaign'
import password_reset from './password_reset'
import setting from './setting'

export default combineReducers({
  app,
  user,
  transaction,
  overview,
  notification,
  campaign,
  campaign_stat,
  influencer,
  category,
  facebook,
  twitter,
  linkedin,
  audience,
  payment,
  charity,
  autopost,
  invitation,
  post,
  influencer_campaign,
  password_reset,
  setting,
})
