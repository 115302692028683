import React, { Component } from 'react'

import numeral from 'numeral'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import IconButton from 'material-ui/IconButton'
import { Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle } from 'material-ui/Toolbar'
import ProfileIcon from 'material-ui/svg-icons/action/face'
import { grey500 } from 'material-ui/styles/colors'

import { formatAmount, getInitials, pastelColor } from '../utils'

import { accentColor } from '../advertiser/Theme'
import AvatarV2 from './AvatarV2'

class BottomToolbar extends Component {
  state = {
    open: false,
  }

  onCancel = () => {
    this.props.onClose()
  }

  onContinue = () => {
    this.props.onContinue()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    })
  }

  getTitleText(type = '', influencers = []) {
    if (type === '') {
      return ''
    }

    if (influencers.length < 1) {
      return 'Please Select Influencers'
    }

    if (type === 'PPC') {
      return influencers.length + ' selected influencers'
    }

    if (type === 'PPP') {
      let total = 0
      total = influencers.map(item => item.fee || 0).reduce((a, b) => a + b, 0)

      return influencers.length + ' selected influencers for ' +
        numeral(formatAmount(total)).format('0,0.00')
    }
  }

  render() {
    let toolbarStyle = {
      position: 'fixed',
      zIndex: 1300,
      left: 0,
      bottom: this.state.open ? 0 : -56,
      right: 0,
      backgroundColor: this.state.open ? 'rgb(255, 243, 224)' : accentColor,
      transition: 'all 0.2s ease-in',
      boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
    }

    return (
      <Toolbar style={toolbarStyle}>
        <ToolbarGroup firstChild={true}>
          <IconButton><ProfileIcon /></IconButton>
          <ToolbarTitle
            style={{color: grey500}}
            text={this.getTitleText(this.props.type, this.props.influencers)} />
          {this.props.influencers.map((item, index) => (
            <AvatarV2 key={index}
              style={{marginRight: 2, backgroundColor:  pastelColor(item.id)}}
              alt={getInitials(item.name || '-')}
              src={item.image} size={22} />
          ))}
        </ToolbarGroup>
        <ToolbarGroup lastChild={true}>
          <ToolbarSeparator />
          <FlatButton label="Cancel" primary={true}
            onClick={this.onCancel.bind(this)} />
          <RaisedButton label="Continue" primary={true}
            disabled={this.props.influencers.length < 1}
            onClick={this.onContinue.bind(this)} />
        </ToolbarGroup>
      </Toolbar>
    )
  }
}

export default BottomToolbar
