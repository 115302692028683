import React, { Component } from 'react'
import { connect } from 'react-redux'

import AppBar from 'material-ui/AppBar'
import Drawer from 'material-ui/Drawer'
import IconButton from 'material-ui/IconButton'
import { Card, CardText } from 'material-ui/Card'
import CheckIcon from 'material-ui/svg-icons/navigation/check'
import CheckAllIcon from 'material-ui/svg-icons/action/done-all'

import { findNotification, updateNotification, clearNotif } from '../actions/notification'
import { formatRelativeDate } from '../utils'
// import { serverEventListener } from '../utils/sse'

import EmptyData from '../components/EmptyData'

const mapStateToProps = (state, ownProps) => {
  return {
    notification: state.notification,
  }
}

class Notification extends Component {
  state = {
    list: [],

    open: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  componentDidMount() {
    // let self = this
    // serverEventListener('notification_changes', function(e) {
    //   self.props.findNotification()
    // })

    this.props.findNotification()
  }

  componentWillReceiveProps(nextProps) {
    let { notification } = nextProps

    this.setState({
      list: notification.list || [],

      open: nextProps.open,

      notifyOpen: notification.notify.open,
      notifyMessage: notification.notify.message,
    })
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })

    this.props.onRequestClose()
  }

  checkAll() {
    // check all
    for (var i in this.state.list) {
      let row = this.state.list[i] || {}

      // skip checked
      if (row.id === undefined || row.checked) {
        continue
      }

      this.props.updateNotification(row.id, {
        checked: true,
      })
    }

    this.toggle()
  }

  check() {
  }

  render() {
    return (
      <Drawer width={256} openSecondary={true} open={this.state.open} >
        <AppBar title="Notifications"
          onTitleClick={this.toggle.bind(this)}
          showMenuIconButton={false}
          iconElementRight={
            <IconButton onClick={this.checkAll.bind(this)}>
              <CheckAllIcon />
            </IconButton>
          }
          titleStyle={{fontWeight: 'lighter', fontSize: 18}} />
        <div>
          {this.state.list !== 0 ? null : (
            <EmptyData />
          )}

          {this.state.list.map((row, index) => (
            <Card className="card-override" key={index}>
              <CardText>
                <span>{row.text}</span>
                <div>
                  <span style={{opacity: 0.5}}>{formatRelativeDate(row.created_at)}</span>
                  <IconButton onClick={this.check.bind(this)}
                    style={{
                      float: 'right',
                      display: 'none',
                    }}>
                    <CheckIcon />
                  </IconButton>
                </div>
              </CardText>
            </Card>
          ))}
        </div>
      </Drawer>
    )
  }
}

export default connect(mapStateToProps, {
  findNotification,
  updateNotification,
  clearNotif,
})(Notification)
