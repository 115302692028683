import React, { Component } from 'react'

import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'

// const styles = {
//   block: {
//     maxWidth: 250,
//   },
//   checkbox: {
//     marginBottom: 16,
//   },
// }

class WizardPayment extends Component {
  state = {
    postFee: 0,
    postNote: '',
    paymentEmail: '',
    isAmerican: false,
  }

  componentWillMount() {
    let { postFee, postNote, paymentEmail, isAmerican } = this.props


    this.setState({
      postFee,
      postNote,
      paymentEmail,
      isAmerican,
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value
    this.setState(state)

    this.props.onChange(state)
  }

  toggleCheck() {
    let state = this.state

    state.isAmerican = !this.state.isAmerican
    this.setState(state)

    this.props.onChange(this.state)
  }

  render() {
    return (
      <div>
        <p>Set up your Paypal and Post fee</p>
        <TextField floatingLabelFixed={true}
          floatingLabelText="Posting Fee (required)"
          style={{width: '49%'}}
          type="number"
          value={this.state.postFee}
          onChange={this.onChange.bind(this, 'postFee')} />
        <TextField floatingLabelFixed={true}
          floatingLabelText="Paypal Account Email"
          style={{width: '49%', float: 'right'}}
          value={this.state.paymentEmail}
          onChange={this.onChange.bind(this, 'paymentEmail')} />
        <br />
        <TextField fullWidth={true} floatingLabelFixed={true}
          floatingLabelText="Profile Note (required)"
          hintText="Indicate willingness to add comments and endorsement"
          value={this.state.postNote}
          onChange={this.onChange.bind(this, 'postNote')} />
        <br />
        <br />
        <div style={{
            maxWidth: 250,
          }}>
          <Checkbox
            checked={this.state.isAmerican}
            label="American Citizen"
            style={{ marginBottom: 16 }}
            onClick={this.toggleCheck.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default WizardPayment
