import api from '../api'

/*
 *  action constants
 */

export const FIND_INFLUENCER_CAMPAIGN  = 'FIND_INFLUENCER_CAMPAIGN'
export const GET_INFLUENCER_CAMPAIGN  = 'GET_INFLUENCER_CAMPAIGN'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findInfluencerCampaign = (filter) => (dispatch) => {
  let params = '?meta&score&charged'
  params += filter

  dispatch({
    type: FIND_INFLUENCER_CAMPAIGN,
    payload: api.get('/influencer-campaign' + params),
  })
}

export const getInfluencerCampaign = (id) => (dispatch) => {
  dispatch({
    type: GET_INFLUENCER_CAMPAIGN,
    payload: api.get('/influencer-campaign/' + id),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
