import React, {Component} from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import TextField from 'material-ui/TextField'
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import { redirect, isLogin, clearAuth } from '../utils'
import { getQuery } from '../utils/parse'
import { updatePasswordReset, clearNotif } from '../actions/password_reset'

import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'

const styles = {
  card: {
    marginTop: 20,
    marginBottom: 20,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    passwordReset: state.password_reset,
  }
}

class Password extends Component {
  state = {
    newPass: '',
    confirmPass: '',

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    // if logged in log it out
    if (isLogin()) {
      clearAuth()
      redirect('/advertiser/password')
    }
  }

  componentWillReceiveProps(nextProps) {
    let { passwordReset } = nextProps

    if (passwordReset.detail.password !== undefined) {
      window.location = window.location.origin + '/#/advertiser/'
      return
    }

    this.setState({
      notifyOpen: passwordReset.notify.open,
      notifyMessage: passwordReset.notify.message,
    })
  }

  onSave() {
    let { newPass, confirmPass } = this.state

    // check empty
    if (newPass === '' || confirmPass === '') {
      this.setState({
        notifyOpen: true,
        notifyMessage: 'password and confirm password are required fields',
      })

      return
    }

    // check confirmation
    if (newPass !== confirmPass) {
      this.setState({
        notifyOpen: true,
        notifyMessage: 'password does not match',
      })

      return
    }

    this.props.updatePasswordReset(getQuery('token'), {
      password: newPass,
    })
  }

  render() {
    let { passwordReset } = this.props

    return (
      <div>
        <Card className="card-override" style={styles.card}>
          <ProgressBar open={passwordReset.loading} />

          <CardHeader title="MashDrop Advertiser"
            subtitle="Reset password" />
          <CardText>
            <TextField fullWidth={true}
              floatingLabelFixed={true}
              floatingLabelText="Password"
              type="password"
              defaultValue={this.state.newPass}
              onChange={(e) => {this.setState({newPass: e.target.value })}} />
            <br />
            <TextField fullWidth={true}
              floatingLabelFixed={true}
              floatingLabelText="Confirm Password"
              type="password"
              defaultValue={this.state.confirmPass}
              onChange={(e) => {this.setState({confirmPass: e.target.value })}} />
            <br />
          </CardText>
          <CardActions style={{textAlign: 'center'}}>
            <RaisedButton label="Save" primary={true}
              onClick={this.onSave.bind(this)} />
          </CardActions>
        </Card>

        <Link to="/advertiser/forget">
          <FlatButton label="Reset Password" />
        </Link>
        <Link to="/advertiser/login">
          <FlatButton label="Login" />
        </Link>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  clearNotif,
  updatePasswordReset,
})(Password)
