import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Chart } from 'react-google-charts'
import { Card, CardHeader, CardText } from 'material-ui/Card'

import { getOverviewClick } from '../actions/overview'
import { parseGraphClick } from '../utils/parse'

import DateScope from '../components/DateScope'

const styles = {
  cardHeader: {
    float: 'left',
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
  }
}

const options = {
  hAxis: {title: 'Date'},
  vAxis: {title: 'Clicks'},
  legend: 'none',
}

const dataHeader = ['Date', 'Clicks']

class OverviewClick extends Component {
  state = {
    data: [dataHeader],
  }

  constructor(props) {
    super(props)

    this.changeDateScope = this.changeDateScope.bind(this)
  }

  componentWillMount() {
    this.props.getOverviewClick()
  }

  componentWillReceiveProps(nextProps) {
    let { overview } = nextProps

    // parse data
    let data = parseGraphClick(overview.click)

    this.setState({
      data,
    })
  }

  changeDateScope(value) {
    this.props.getOverviewClick(value)
  }

  render() {
    return (
      <Card className="card-override" expanded={true}>
        <CardHeader title="Total Clicks" style={styles.cardHeader}
          subtitle="clicks of all campaign" />

        <DateScope onChange={this.changeDateScope} />

        <CardText>
          <Chart chartType="LineChart" data={this.state.data}
            options={options} width={"100%"}
            height={"300px"} legend_toggle={true} />
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewClick,
})(OverviewClick)
