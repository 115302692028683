import { setSession } from '../utils/storage'
import * as settingAction from '../actions/setting'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // get setting
    case `${settingAction.GET_SETTING}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${settingAction.GET_SETTING}_FULFILLED`:
      setSession('setting', action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${settingAction.GET_SETTING}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // setting clear message
    case settingAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
