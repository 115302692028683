/*
 *  action constants
 */

export const SET_TITLE = 'SET_TITLE'
export const FLASH_MESSAGE = 'FLASH_MESSAGE'

/*
 *  action types
 */

export const setAppTitle = (value) => (dispatch) => {
  dispatch({
    type: SET_TITLE,
    payload: value
  })
}

export const flashMessage = (text = '', type = '') => (dispatch) => {
  dispatch({
    type: FLASH_MESSAGE,
    payload: {
      type,
      text
    }
  })
}
