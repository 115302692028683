import React, { Component } from 'react'
import { connect } from 'react-redux'

import numeral from 'numeral'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardActions, CardText } from 'material-ui/Card'
import { Step, Stepper, StepLabel } from 'material-ui/Stepper'

import { upload } from '../utils/upload'
import { redirect, isURL } from '../utils'
import { getMinClicks, getMinClickRate, calcTotalPrice, validateInflu, validatePPCMinPriceReq }
  from '../utils/campaign'
import { getSession, removeSession } from '../utils/storage'
import { createCampaign, clearNotif } from '../actions/campaign'

import Note from '../components/Note'
import Notify from '../components/Notify'
import PreLoader from '../components/PreLoader'
import CampaignCreateImage from '../components/CampaignCreateImage'
import CampaignCreateInfo from '../components/CampaignCreateInfo'
import CampaignCreateCost from '../components/CampaignCreateCost'
import CampaignCreateFinal from '../components/CampaignCreateFinal'

const defaultState = {
  type: '',
  influencers: [],

  image: '',
  imageUploading: false,
  imageRawFile: {},
  imageNewFile: {},

  title: '',
  description: '',
  note: '',
  url: '',

  price: 0,
  max_click: 0,
  click_rate: 0,

  status: 'draft',

  finished: false,
  stepIndex: 0,

  disableNext: false,

  notifyOpen: false,
  notifyMessage: '',
}

const mapStateToProps = (state, ownProps) => {
  return {
    campaign: state.campaign,
    setting: state.setting,
  }
}

class CampaignCreate extends Component {
  state = defaultState

  constructor(props) {
    super(props)

    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)

    this.setImage = this.setImage.bind(this)
    this.setInfo = this.setInfo.bind(this)
    this.setCost = this.setCost.bind(this)
    this.publish = this.publish.bind(this)
    this.save = this.save.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    let session = getSession('campaign') || {}
    if (session.length === 0) {
      return
    }

    let { campaign } = nextProps

    this.setState({
      notifyOpen: campaign.notify.open,
      notifyMessage: campaign.notify.message,
      max_click: getMinClicks(),
      click_rate: getMinClickRate(),
    })

    // published redirect
    if (campaign.payment.approval_url !== '' && campaign.payment.approval_url !== undefined) {
      // clear session
      removeSession('campaign')

      window.location = campaign.payment.approval_url

      return
    }

    // draft saved
    if (campaign.detail.id !== undefined) {
      // clear session
      removeSession('campaign')

      redirect('/advertiser/campaign/' + campaign.detail.id + '/view')

      return
    }
  }

  componentWillMount() {
    // get data from campaign session
    let campaign = getSession('campaign')

    if (campaign === null) {
      // throw some error or redirect to influencers
      redirect('/advertiser/influencer?createMode=true')

      return
    }

    let { type, influencers } = campaign
    this.setState({
      type,
      influencers,
      disableNext: true,
    })
  }

  handleNext() {
    let { stepIndex, disableNext, imageNewFile, type, influencers, max_click, click_rate } = this.state

    if (stepIndex === 0) {
      // start uploadig image
      this.uploadImage(imageNewFile)

      // check if url is valid
      disableNext = this.checkRequiredInfoFields()
    }

    let price = calcTotalPrice(type, influencers, max_click, click_rate)

    this.setState({
      price,

      stepIndex: stepIndex + 1,
      finished: stepIndex >= 2,
      disableNext,
    })
  }

  handlePrev() {
    let { stepIndex, disableNext } = this.state

    if (stepIndex > 0) {
      disableNext = false

      this.setState({
        stepIndex: stepIndex - 1,
        disableNext,
      })
    }
  }

  setImage(imageRawFile, imageNewFile) {
    this.setState({
      imageRawFile,
      imageNewFile,
    })

    this.setState({
      disableNext: false,
    })
  }

  uploadImage(file = {}) {
    this.setState({ imageUploading: true })

    upload(file, (path) => {
      // handle success
      this.setState({
        image: path,
        imageUploading: false,
      })
    }, (err) => {
      // handle error
      this.setState({
        notifyOpen: true,
        imageUploading: false,
        notifyMessage: 'Error uploading image. Please try again.',
      })
    })
  }

  setInfo(field, value) {
    let state = this.state

    state[field] = value

    // check required fields and if url is valid
    state.disableNext = this.checkRequiredInfoFields()

    this.setState(state)
  }

  checkRequiredInfoFields() {
    let state = this.state

    if (state.title.trim() !== ''
    && state.description.trim() !== ''
    && state.note.trim() !== ''
    && state.url.trim() !== ''
    && isURL(state.url)) {
      return false
    }

    return true
  }

  setCost(field, value) {
    let { state } = this
    state[field] = value
    let { type, influencers, max_click, click_rate } = state

    state.disableNext = false
    if (!validateInflu(influencers)) {
      state.disableNext = true
    }

    if (state.type === 'PPC'
    && !validatePPCMinPriceReq(max_click, click_rate)) {
      state.disableNext = true
    }

    state.price = calcTotalPrice(type, influencers, max_click, click_rate)

    if (state.price === 0) {
      state.disableNext = true
    }

    this.setState(state)
  }

  prepData() {
    let state = this.state

    // empty non used data on PPP
    let maxClick = 0
    let clickRate = 0
    if (state.type === 'PPC') {
      maxClick = parseInt(state.max_click, 10)
      clickRate = parseFloat(state.click_rate).toFixed(2)
    }

    let data = {
      type: state.type,
      influencers: state.influencers,
      image: state.image,
      title: state.title,
      description: state.description,
      note: state.note,
      url: state.url,
      max_click: maxClick,
      click_rate: clickRate,
      status: state.status,
    }

    return data
  }

  save() {
    let data = this.prepData()

    this.props.createCampaign(data)
  }

  publish() {
    let data = this.prepData()
    data.status = 'published'

    this.props.createCampaign(data)
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<CampaignCreateImage
          file={this.state.imageRawFile}
          onChange={this.setImage} />)
      case 1:
        return (<CampaignCreateInfo
          title={this.state.title}
          description={this.state.description}
          url={this.state.url}
          note={this.state.note}
          onChange={this.setInfo} />)
      case 2:
        return (<CampaignCreateCost
          type={this.state.type}
          clickRate={this.state.click_rate}
          maxClick={this.state.max_click}
          price={this.state.price}
          influencers={this.state.influencers}
          onChange={this.setCost} />)
      case 3:
        return (<CampaignCreateFinal
          title={this.state.title}
          description={this.state.description}
          url={this.state.url}
          image={this.state.imageNewFile.preview || null}
          imageUploading={this.state.imageUploading}
          type={this.state.type}
          price={this.state.price || 0}
          clickRate={this.state.click_rate}
          maxClick={this.state.max_click}
          influencersCount={this.state.influencers.length} />)
      default:
        return 'You\'re a long way from home sonny jim!';
    }
  }

  render() {
    let { campaign } = this.props
    const { finished, stepIndex, imageUploading } = this.state
    let disabledAction = campaign.loading || imageUploading

    return (
      <section className="content-wrapper">
        <Card className="card-override">
          <PreLoader open={campaign.loading} />

          <CardText>
            <Stepper activeStep={stepIndex}>
              <Step>
                <StepLabel>Select Image</StepLabel>
              </Step>
              <Step>
                <StepLabel>Set Details</StepLabel>
              </Step>
              <Step>
                <StepLabel>Cost</StepLabel>
              </Step>
              <Step>
                <StepLabel>Finish</StepLabel>
              </Step>
            </Stepper>

            {this.getStepContent(stepIndex)}
          </CardText>
          <CardActions style={{textAlign: 'center'}}>
            {!finished ? (
              <div>
                <FlatButton
                  label="Back"
                  disabled={stepIndex === 0}
                  onClick={this.handlePrev}
                  style={{marginRight: 12}}
                />
                <FlatButton
                  label={stepIndex === 3 ? 'Finish' : 'Next'}
                  primary={true}
                  disabled={this.state.disableNext}
                  onClick={this.handleNext}
                />
              </div>
            ) : (
              <div>
                <Note>
                  Publishing will automatically send a notification to influencers to post the campaign.
                </Note>
                <RaisedButton primary={true}
                  disabled={disabledAction}
                  label={'Publish for ' + numeral(this.state.price).format('0,0.00')}
                  onClick={this.publish} />
                <br />
                <br />
                <FlatButton primary={true}
                  label="Save as Draft"
                  disabled={disabledAction}
                  onClick={this.save} />
                <FlatButton label="Go Back"
                  disabled={disabledAction}
                  onClick={(event) => {
                  event.preventDefault();
                  this.setState({
                      stepIndex: this.state.stepIndex-1,
                      finished: false
                  });
                }} />
              </div>
            )}
          </CardActions>

          <Notify
            open={this.state.notifyOpen}
            message={this.state.notifyMessage}
            onRequestClose={this.props.clearNotif} />
        </Card>
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  createCampaign,
  clearNotif,
})(CampaignCreate)
