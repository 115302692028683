import React, { Component } from 'react'
import { connect } from 'react-redux'

import FlatButton from 'material-ui/FlatButton'
import EjectIcon from 'material-ui/svg-icons/action/eject'
import { GridList, GridTile } from 'material-ui/GridList'
import { Card, CardHeader, CardText } from 'material-ui/Card'

import { API_HOST } from '../api'
import { abbreviateNumber } from '../utils'
import { getQuery } from '../utils/parse'
import { getFacebook, removeFacebook, connectFacebook, clearNotif as facebookClearNotif }
  from '../actions/facebook'
import { getTwitter, removeTwitter, connectTwitter, clearNotif as twitterClearNotif }
  from '../actions/twitter'
import { getLinkedin, removeLinkedin, connectLinkedin, clearNotif as linkedinClearNotif }
  from '../actions/linkedin'

import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'
import SocialButton from '../components/SocialButton'

const mapStateToProps = (state, ownProps) => {
  return {
    facebook: state.facebook,
    twitter: state.twitter,
    linkedin: state.linkedin,
  }
}

class AccountSocial extends Component {
  state = {
    type: '',

    facebook: '',
    twitter: '',
    linkedin: '',

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    // social connect
    let type = getQuery('type')
    switch (type) {
    case 'facebook':
      let c = getQuery('code')
      let s = getQuery('state')

      // check if empty
      if (c === '' || s === '') {
        return
      }

      this.props.connectFacebook(c, s)
      return
    case 'twitter':
      let t = getQuery('oauth_token')
      let v = getQuery('oauth_verifier')

      // check if empty
      if (t === '' || v === '') {
        return
      }

      this.props.connectTwitter(t, v)
      return
    case 'linkedin':
      c = getQuery('code')
      s = getQuery('state')

      // check if empty
      if (c === '' || s === '') {
        return
      }

      this.props.connectLinkedin(c, s)
      return
    // no default
    }

    this.props.getFacebook()
    this.props.getTwitter()
    this.props.getLinkedin()
  }

  componentWillReceiveProps(nextProps) {
    let { facebook, twitter, linkedin } = nextProps
    let state = this.state

    state.facebook = facebook.detail.id !== undefined ? facebook.detail.name : ''
    state.twitter = twitter.detail.id !== undefined ? twitter.detail.username : ''
    state.linkedin = linkedin.detail.id !== undefined ? linkedin.detail.name : ''

    // notifs on connect attempt
    let type = getQuery('type') || state.type
    if (type !== '') {
      state.notifyOpen = nextProps[type].notify.open
      state.notifyMessage = nextProps[type].notify.message
    }

    this.setState(state)
  }

  login(type) {
    // check if no need
    if (this.props[type].detail.id !== undefined) {
      return
    }

    let callback = window.location.origin
    if (type === 'linkedin') {
      callback += '?redirect=/influencer/account&type=' + type
    } else {
      callback += '/#/redirect/influencer/account?type=' + type
    }

    let redir = API_HOST + '/' + type + '/login?callback=' + encodeURIComponent(callback)

    window.location = redir
  }

  disconnect(type) {
    switch (type) {
    case 'facebook':
      this.props.removeFacebook()
      break;
    case 'twitter':
      this.props.removeTwitter()
      break;
    case 'linkedin':
      this.props.removeLinkedin()
      break;
    // no default
    }

    this.setState({
      type,
    })
  }

  closeNotif() {
    this.props.facebookClearNotif()
    this.props.twitterClearNotif()
    this.props.linkedinClearNotif()
  }

  render() {
    let { facebook, twitter, linkedin } = this.props

    return (
      <div>
        <Card className="card-override">
          <ProgressBar open={facebook.loading || twitter.loading || linkedin.loading} />
          <CardHeader subtitle="Social Connections" />
          <CardText>
            <GridList cols={3} cellHeight={160} style={{textAlign: 'center'}}>
              <GridTile>
                <SocialButton name="facebook" connected={this.state.facebook === '' ? false : true}
                  onClick={this.login.bind(this, 'facebook')} />
                {this.state.facebook === '' ? (
                  <p>Connect to Facebook Now</p>
                ) : (
                  <p>
                    Connected to Facebook as <br /><strong>{this.state.facebook}</strong>
                    <br />
                    with <strong>{abbreviateNumber(this.props.facebook.detail.follower_count)}</strong> friends
                    {(this.props.facebook.detail.page_follower_count || 0) === 0 ? null : ' + fans'}
                  </p>
                )}

                {this.state.facebook === '' || this.props.facebook.detail.default ? (
                  <FlatButton secondary={true} label="Default" style={{
                      display: this.state.facebook === '' ? 'none' : 'inline',
                    }} />
                ) : (
                  <FlatButton icon={<EjectIcon />} label="disconnect"
                    onClick={this.disconnect.bind(this, 'facebook')} />
                )}
              </GridTile>
              <GridTile>
                <SocialButton name="twitter" connected={this.state.twitter === '' ? false : true}
                  onClick={this.login.bind(this, 'twitter')} />
                {this.state.twitter === '' ? (
                  <p>Connect to Twitter Now</p>
                ) : (
                  <p>
                    Connected to Twitter as <br /><strong>{this.state.twitter}</strong>
                    <br />
                    with <strong>{abbreviateNumber(this.props.twitter.detail.follower_count)}</strong> followers
                  </p>
                )}

                {this.state.twitter === '' || this.props.twitter.detail.default ? (
                  <FlatButton secondary={true} label="Default" style={{
                      display: this.state.twitter === '' ? 'none' : 'inline',
                    }} />
                ) : (
                  <FlatButton icon={<EjectIcon />} label="disconnect"
                    onClick={this.disconnect.bind(this, 'twitter')} />
                )}
              </GridTile>
              <GridTile>
                <SocialButton disabled={true} name="linkedin" connected={this.state.linkedin === '' ? false : true}
                  onClick={this.login.bind(this, 'linkedin')} />
                {this.state.linkedin === '' ? (
                  <p>Connect to Linkedin Now</p>
                ) : (
                  <p>
                    Connected to Linkedin as <br /><strong>{this.state.linkedin}</strong>
                    <br />
                    with <strong>{abbreviateNumber(this.props.linkedin.detail.follower_count)}</strong> connections
                  </p>
                )}

                {this.state.linkedin === '' || this.props.linkedin.detail.default ? (
                  <FlatButton secondary={true} label="Default" style={{
                      display: this.state.linkedin === '' ? 'none' : 'inline',
                    }} />
                ) : (
                  <FlatButton icon={<EjectIcon />} label="disconnect"
                    onClick={this.disconnect.bind(this, 'linkedin')} />
                )}
              </GridTile>
            </GridList>
          </CardText>
        </Card>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.closeNotif.bind(this)} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getFacebook,
  getTwitter,
  getLinkedin,

  connectFacebook,
  connectTwitter,
  connectLinkedin,

  facebookClearNotif,
  twitterClearNotif,
  linkedinClearNotif,

  removeFacebook,
  removeTwitter,
  removeLinkedin,
})(AccountSocial)
