import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import { clearAuth } from '../utils'

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  }
}

class UserErrorDialog extends Component {
  logoutHandler() {
    clearAuth()
    window.location.reload()
  }

  render() {
    let { user } = this.props

    return (
      <Dialog
        title="Authentication Problem"
        actions={[
          <FlatButton
            label="Ok"
            primary={true}
            onClick={this.logoutHandler} />,
        ]}
        modal={true}
        open={user.error}
        onRequestClose={this.logoutHandler} >
        <p>
          Something went wrong and you will be logged out
          <br />
          <br />
          <em>Reason: {user.notify.message}</em>
        </p>
      </Dialog>
    )
  }
}


export default connect(mapStateToProps)(UserErrorDialog)
