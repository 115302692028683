export const Storage = type => {
  function createCookie(name, value, days) {
    let date;
    let expires;

    if (days) {
      date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = `; expires=${date.toGMTString()}`;
    } else {
      expires = "";
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
  }

  function readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    let i;
    var c;

    for (i=0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1,c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }
    return null;
  }

  function setData(data) {
    data = JSON.stringify(data);
    if (type === 'session') {
      window.name = data;
    } else {
      createCookie('localStorage', data, 365);
    }
  }

  function clearData() {
    if (type === 'session') {
      window.name = '';
    } else {
      createCookie('localStorage', '', 365);
    }
  }

  function getData() {
    let data = type === 'session' ? window.name : readCookie('localStorage');

    try {
      return JSON.parse(data)
    } catch (e) {
      return {}
    }
  }

  let data = getData();

  return {
    length: 0,
    clear() {
      data = {};
      this.length = 0;
      clearData();
    },
    getItem(key) {
      return data[key] === undefined ? null : data[key];
    },
    key(i) {
      // not perfect, but works
      let ctr = 0;
      for (const k in data) {
        if (ctr === i) return k;
        else ctr++;
      }
      return null;
    },
    removeItem(key) {
      delete data[key];
      this.length--;
      setData(data);
    },
    setItem(key, value) {
      data[key] = `${value}`; // forces the value to a string
      this.length++;
      setData(data);
    }
  };
};
