import React from 'react'

import moment from 'moment'
import numeral from 'numeral'
import muiThemeable from 'material-ui/styles/muiThemeable'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'
import FlatButton from 'material-ui/FlatButton'
import ManageIcon from 'material-ui/svg-icons/action/settings'
import StatsIcon from 'material-ui/svg-icons/av/equalizer'

import Score from '../components/Score'
import StatusLabel from '../components/StatusLabel'
import ViewerEngagement from '../components/ViewerEngagement'

const styles = {
  wrapper: {
    margin: 20,
  },
  socialIcon: {
    fontSize: 15,
    marginLeft: 8,
    marginTop: 15,
  },
}

const getReach = (p) => {
  return p.clicks + p.likes + p.comments + p.shares
}

export default muiThemeable()((props) => (
  <div style={styles.wrapper}>
    <Card className="card-override" expanded={true}>
      <CardHeader title={props.title || '-'}
        titleStyle={{
          textTransform: 'capitalize',
          fontSize: 16,
        }}
        subtitle={
          <div><StatusLabel status={props.status} />  ·  {moment(props.updated).format('MMM DD YYYY')}</div>
        }
        subtitleStyle={{
          fontWeight: 'normal',
        }} />
      <CardText>
        <GridList cols={4} cellHeight={80} >
          <GridTile>
            <img src={props.image} alt="" />
          </GridTile>
          <GridTile>
            <Score title="Viewer Engagement" value={getReach(props)} />
          </GridTile>
          <GridTile>
            {props.type === 'PPC' ? (
              <Score title="Budget / Used" value={numeral(props.price).format('0,0.00') + ' / ' + numeral(props.uniqueClicks * props.rate).format('0,0.00')} />
              ) : (
              <Score title="Cost" value={numeral(props.price).format('0,0.00')} />
            )}
          </GridTile>
          <GridTile>
            <Score title="Type" value={props.type || '-'} />
          </GridTile>
        </GridList>
      </CardText>
      <CardActions>
        <div style={{
            float: 'left',
          }}>
          <ViewerEngagement clicks={props.clicks} facebook={[props.likes, props.comments, props.shares]} />
        </div>
        <div style={{
            textAlign: 'right',
          }}>
          <FlatButton label="Manage" href={'/#/advertiser/campaign/' + props.id + '/view'}
            icon={<ManageIcon />} />
          <FlatButton label="Stats" href={'/#/advertiser/campaign/' + props.id + '/stats'} primary={true}
            icon={<StatsIcon />} />
        </div>
      </CardActions>
    </Card>
  </div>
))
