import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import { CardMedia } from 'material-ui/Card'
import RaisedButton from 'material-ui/RaisedButton'

import Note from '../components/Note'
import Notify from '../components/Notify'

const minWidth = 600
const minHeight = 315
const maxWidth = 1200
const aspectRatio = 0.525

class CampaignCreateImage extends Component {
  state = {
    rawFile: '',
    newFile: {},

    width: minWidth,
    height: minHeight,

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    let { file } = this.props

    if (file.preview === undefined) {
      return
    }

    this.changeImage([file])
  }

  getImageProp(cb, url) {
    let i = new Image()
    i.onload = () => {
      cb(i)
    }

    i.src = url
  }

  imageCrop() {
    let { rawFile } = this.state
    let { name } = rawFile

    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    this.editor.getImageScaledToCanvas().toBlob((blob) => {
      let preview = URL.createObjectURL(blob)

      // NOTE safari is not fully supported the File constructor
      // fortunately we can use Blob to upload it as a file
      // dont forget to add name property
      blob.name = name
      blob.preview = preview

      this.props.onChange(rawFile, blob)
    })
  }

  changeImage(files = []) {
    if (files.length < 0) {
      return
    }

    let rawFile = files[0]
    this.getImageProp((img = {}) => {
      let width = img.width
      let height = img.height

      // check image width
      let notifyMessage = 'Please select another image atleast ' + minWidth + ' x ' + minHeight + ' pixels'
      if (width < minWidth || height < minHeight) {
        this.setState({
          notifyOpen: true,
          notifyMessage,
        })

        return
      }

      // limit dimension
      if (width > maxWidth) {
        width = maxWidth
      }

      // calc new height
      height = this.calcHeightRatio(width)

      this.setState({
        width,
        height,
        rawFile,
      })
    }, rawFile.preview)
  }

  calcHeightRatio(width) {
    return aspectRatio * width
  }

  selectFile() {
    this.dropzone.open();
  }

  setEditorRef(editor) {
    if (editor) {
      this.editor = editor
    }
  }

  toggleNotify() {
    this.setState({
      notifyOpen: !this.state.notifyOpen
    })
  }

  render() {
    let { rawFile, width, height } = this.state
    let preview = rawFile.preview || ''

    return (
      <div style={{textAlign: 'center'}}>
        <Dropzone ref={(node) => { this.dropzone = node; }}
          style={{display: 'none'}}
          multiple={false}
          accept={'image/*'}
          onDrop={this.changeImage.bind(this)} />

        {preview === '' ? null : (
          <CardMedia mediaStyle={{
              cursor: 'move',
              maxWidth: 800,
              margin: '0 auto',
            }}>

            <span>Drag to Reposition Image</span>
            <AvatarEditor
              ref={this.setEditorRef.bind(this)}
              image={preview}
              onImageReady={this.imageCrop.bind(this)}
              onMouseUp={this.imageCrop.bind(this)}
              width={width}
              height={height}
              border={0}
              color={[0, 0, 0, 0.4]} />
          </CardMedia>
        )}

        <br />

        <RaisedButton label="Upload Image"
          primary={true}
          labelPosition="before"
          onClick={this.selectFile.bind(this)} />
        <Note>
          Use images that are at least <strong>{minWidth} x {minHeight} pixels</strong> for the best display. <a href="/#/advertiser/guide?slug=optimize-images" target="_blank">Learn more</a>
        </Note>

        <Notify open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.toggleNotify.bind(this)} />
      </div>
    )
  }
}

export default CampaignCreateImage
