export const parseGraphClick = (raw) => {
  let header = ['date', 'clicks']
  let data = [header]
  for (var i in raw.date) {
    data.push([
      raw.date[i],
      raw.click[i],
    ])
  }

  return data
}

export const parseGraphSource = (raw) => {
  let header = parseGraphDataHeader(raw)

  // check no data
  if (header.length === 1) {
    return [[header, ''], ['', 0]]
  }

  const zeroPad = (header, raw) => {
      let row = []
      header.forEach((head) => {
        // when data set is not exists. its automatically zero
        let object = raw[head]
        if (object === undefined) {
          row.push(0)
        }

        row.push(object[i])
        return
      })

      return row
  }

  let data = [header]
  for (var i in raw.date) {
    data.push(zeroPad(header, raw))
  }

  return data
}

export const parseGraphInfluencer = (raw) => {
  return parseGraphSource(raw)
}

export const parseGraphPlatform = (raw) => {
  let header = ['platform', 'click']
  let data = [header]
  for (var i in raw.platform) {
    data.push([
      raw.platform[i],
      raw.click[i],
    ])
  }

  return data
}

export const getQuery = (name) => {
  let url = window.location.href
  name = name.replace(/[[]]/g, "\\$&")
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url)

  if (!results) return ''
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

// const parseNoData = (data) => {
//
// }

const parseGraphDataHeader = (raw) => {
  // shift date
  let header = ['date']
  Object.keys(raw).forEach((item) => {
    if (item.toLowerCase() === 'date') {
      return
    }

    header.push(item)
  })

  return header
}
