import React, { Component } from 'react'
import { connect } from 'react-redux'

import Badge from 'material-ui/Badge'
import IconButton from 'material-ui/IconButton'
import NotificationsIcon from 'material-ui/svg-icons/social/notifications'

import { findNotification, updateNotification, clearNotif } from '../actions/notification'

import { lightColor } from '../advertiser/Theme'

const mapStateToProps = (state, ownProps) => {
  return {
    notification: state.notification,
  }
}

class Notification extends Component {
  state = {
    list: 0,
    unseen_count: 0,
  }

  componentWillReceiveProps(nextProps) {
    let { notification } = nextProps

    this.setState({
      list: notification.list || [],
      unseen_count: notification.meta.unseen_count || 0,
    })
  }

  click() {
    this.props.onClick()

    // seen all
    for (var i in this.state.list) {
      let row = this.state.list[i] || {}

      // skip seen
      if (row.id === undefined || row.seen) {
        continue
      }

      this.props.updateNotification(row.id, {
        seen: true,
      })
    }
  }

  render() {
    return (
      <span>
        <Badge badgeContent={this.state.unseen_count} secondary={true}
          style={{
            position: 'absolute',
            top: -23,
            right: 32,
            visibility: !!this.state.unseen_count ? 'visible' : 'hidden',
          }}
          badgeStyle={{top: 20, right: 20}} >
        </Badge>
        <IconButton onClick={this.click.bind(this)}>
          <NotificationsIcon color={lightColor} />
        </IconButton>
      </span>
    )
  }
}

export default connect(mapStateToProps, {
  findNotification,
  updateNotification,
  clearNotif,
})(Notification)
