import * as overviewAction from '../actions/overview'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  score: {},
  click: {},
  source: {},
  platform: {},
  influencer: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // get overview
    case `${overviewAction.GET_OVERVIEW}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        // score: action.payload.data.score || {},
        // click: action.payload.data.click || {},
        // source: action.payload.data.source || {},
        // platform: action.payload.data.platform || {},
        // influencer: action.payload.data.influencer || {},
      })
    case `${overviewAction.GET_OVERVIEW}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview score
    case `${overviewAction.GET_OVERVIEW_SCORE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW_SCORE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        score: action.payload.data,
      })
    case `${overviewAction.GET_OVERVIEW_SCORE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview click
    case `${overviewAction.GET_OVERVIEW_CLICK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW_CLICK}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        click: action.payload.data,
      })
    case `${overviewAction.GET_OVERVIEW_CLICK}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview source
    case `${overviewAction.GET_OVERVIEW_SOURCE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW_SOURCE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        source: action.payload.data,
      })
    case `${overviewAction.GET_OVERVIEW_SOURCE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview platform
    case `${overviewAction.GET_OVERVIEW_PLATFORM}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW_PLATFORM}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        platform: action.payload.data,
      })
    case `${overviewAction.GET_OVERVIEW_PLATFORM}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview influencer
    case `${overviewAction.GET_OVERVIEW_INFLUENCER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${overviewAction.GET_OVERVIEW_INFLUENCER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        influencer: action.payload.data,
      })
    case `${overviewAction.GET_OVERVIEW_INFLUENCER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case overviewAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
