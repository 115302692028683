import React from 'react'

import { grey500 } from 'material-ui/styles/colors'

export default () => (
  <p style={{
      textAlign: 'center',
      margin: 100,
      color: grey500,
    }}>
    No data
  </p>
)
