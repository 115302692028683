import { formatAmount, deFormatAmount } from './index'
import { getSession } from './storage'

const TYPE_PPC = 'PPC'
const TYPE_PPP = 'PPP'

// calculates total cost base on campaign cost detail
// returns null when error occured
export const calcTotalPrice = (
  type = '',
  influencers = [],
  maxClick = 0,
  clickRate = 0) => {

  // influencer is required atleast 1
  if (!validateInflu(influencers)) {
    return null
  }

  switch (type) {
  case TYPE_PPC:
    maxClick = parseInt(maxClick || 0, 10)
    clickRate = parseFloat(clickRate || 0).toFixed(2)

    return parseFloat(maxClick * clickRate).toFixed(2)
  case TYPE_PPP:
    // get total sum
    let total = 0
    total = influencers.map(i =>
      parseInt(i.fee, 10)
    ).reduce((a, b) => a + b, 0)

    return total.toFixed(2)
  // no default
  }

  return null
}

// validate minimum PPC cost requirement base on api setting
// returns boolean
export const validatePPCMinPriceReq = (maxClick, clickRate) => {
  if (parseInt(maxClick || 0, 10) < getMinClicks()
  || parseFloat(clickRate || 0) < getMinClickRate()) {
    return false
  }

  return true
}

// validate minimum cost requirement base on api setting
// returns boolean
export const validateInflu = (influencers = []) => {
  if (influencers.length === 0) {
    return false
  }

  return true
}

// NOTE needs to format to decimal places to compensate API lack
// of floating number support
export const formatter = (detail, rollback = false) => {
  if ('click_rate' in detail) {
    detail.click_rate = amountModifier(detail.click_rate, rollback)
  }

  if ('price' in detail) {
    detail.price = amountModifier(detail.price, rollback)
  }

  if ('influencers' in detail) {
    for (var i = 0; i < detail.influencers.length; i++) {
      let r = detail.influencers[i]
      if ('fee' in r) {
        r.fee = amountModifier(r.fee, rollback)
      }
    }
  }

  return detail
}

export const getMinClicks = () => {
  return parseInt(getSession('setting').ppc_min_click, 10)
}

export const getMinClickRate = () => {
  return formatAmount(getSession('setting').ppc_min_rate)
}

const amountModifier = (value, deFormat = false) => {
    if (deFormat) {
      return deFormatAmount(value)
    }

    return formatAmount(value)
}
