import api from '../api'

/*
 *  action constants
 */

export const LOGIN_FACEBOOK         = 'LOGIN_FACEBOOK'
export const CONNECT_FACEBOOK       = 'CONNECT_FACEBOOK'
export const GET_FACEBOOK           = 'GET_FACEBOOK'
export const GET_FACEBOOK_GROUPS    = 'GET_FACEBOOK_GROUPS'
export const REMOVE_FACEBOOK        = 'REMOVE_FACEBOOK'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const loginFacebook = (code, state) => (dispatch) => {
  let params = '?code=' + code + '&state=' + state

  dispatch({
    type: LOGIN_FACEBOOK,
    payload: api.get('/facebook/callback' + params),
  })
}

export const connectFacebook = (code, state) => (dispatch) => {
  let params = '?code=' + code + '&state=' + state

  dispatch({
    type: CONNECT_FACEBOOK,
    payload: api.get('/facebook/connect' + params),
  })
}

export const getFacebook = (option = '') => (dispatch) => {
  dispatch({
    type: GET_FACEBOOK,
    payload: api.get('/me/facebook' + option),
  })
}

export const getFacebookGroups = (option = '') => (dispatch) => {
  dispatch({
    type: GET_FACEBOOK_GROUPS,
    payload: api.get('/me/facebook/groups' + option),
  })
}

export const removeFacebook = () => (dispatch) => {
  dispatch({
    type: REMOVE_FACEBOOK,
    payload: api.delete('/me/facebook'),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
