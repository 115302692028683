import React, { Component } from 'react'
import { connect } from 'react-redux'

import { findInfluencer, clearNotif } from '../actions/influencer'
import { redirect, setTitle } from '../utils'
import { setSession } from '../utils/storage'

import PreLoader from '../components/PreLoader'
import InfiniteList from '../components/InfiniteList'
import CampaignType from '../components/CampaignType'
import InfluencerItem from '../components/InfluencerItem'
import InfluencerToolbar from '../components/InfluencerToolbar'
import BottomToolbar from '../components/BottomToolbar'
import InfluencerView from './InfluencerView'

// const styles = {
//   socialIcon: {
//     fontSize: 15,
//     marginLeft: 8,
//     marginTop: 15,
//   },
//   categoryChip: {
//     float: 'right',
//     marginTop: -6,
//     marginLeft: 8,
//   },
//   chip: {
//     margin: 4,
//   },
//   chipWrapper: {
//     display: 'flex',
//     marginTop: 8,
//     marginBottom: 8,
//   },
// }

const mapStateToProps = (state, ownProps) => {
  return {
    influencer: state.influencer,
    setting: state.setting,
  }
}

const title = 'Influencer'

class Influencer extends Component {
  state = {
    // data
    id: '',
    filter: '',
    list: [],
    meta: {},

    // state
    loading: false,

    // campaign
    type: '',
    influencers: [],
    openType: false,
    createMode: false,
    selectedItems: [],

    // view
    open: false,

    // notify
    notifyOpen: false,
    notifyMessage: '',
  }

  constructor(props) {
    super(props)

    this.filterChanged = this.filterChanged.bind(this)
    this.handleReload = this.handleReload.bind(this)

    // view
    this.toggleOpen = this.toggleOpen.bind(this)

    // campaign
    this.onTypeSubmit = this.onTypeSubmit.bind(this)
    this.toggleCreate = this.toggleCreate.bind(this)
    this.addInfluencer = this.addInfluencer.bind(this)
    this.removeInfluencer = this.removeInfluencer.bind(this)
    this.onContinue = this.onContinue.bind(this)
    this.toggleBottomToolbar = this.toggleBottomToolbar.bind(this)
  }

  componentWillMount() {
    setTitle(title)

    // flag if we should display the campaign type selector
    if (!!this.props.location.query.createMode) {
      this.toggleCreate()
    }

    // this.props.findInfluencer('&order=created_at,desc')
  }

  componentWillReceiveProps(nextProps) {
    let { influencer } = nextProps

    // emptify
    let dataKeys = ['audience', 'payment', 'facebook', 'twitter', 'linkedin']
    influencer.list.forEach((item, index) => {
      dataKeys.forEach((key) => {
        if (item[key] === undefined) {
          influencer.list[index][key] = {}
        }
      })
    });

    this.setState({
      list: influencer.list || [],
      meta: influencer.meta,
      loading: influencer.loading,

      notifyOpen: influencer.notify.open,
      notifyMessage: influencer.notify.message,
    })
  }

  toggleOpen(id) {
    this.setState({
      id,
      open: !this.state.open,
    })
  }

  toggleCreate() {
    let createMode = this.state.createMode

    // refresh
    if (createMode) {

      createMode = false
    }

    this.setState({
      type: '',
      influencers: [],
      selectedItems: [],
      openType: !this.state.openType,
      createMode,
    })
  }

  toggleBottomToolbar() {
    this.setState({
      type: '',
      influencers: [],
      selectedItems: [],
      createMode: !this.state.createMode,
    })
  }

  filterChanged(value) {
    let { filter } = this.state

    // scroll to top when filter is not equal to previous
    if (filter !== value) {
      window.scrollTo(0, 0)
    }

    this.setState({ filter: value })
    this.props.findInfluencer(value)
  }

  handleReload(filter) {
    let val = 'nocache=true'
    if (filter !== '') {
      val = `${filter}&${val}`
    }
    this.props.findInfluencer(val)
  }

  onTypeSubmit(value) {
    this.setState({
      type: value,
      createMode: true,
    })
  }

  addInfluencer(id = '', name = '', fee = 0, image = '') {
    let { influencers, selectedItems } = this.state

    this.removeInfluencer(id)

    // push
    influencers.push({
      id,
      name,
      fee,
      image,
    })

    selectedItems.push(id)

    this.setState({
      influencers,
      selectedItems,
    })
  }

  removeInfluencer(id = '') {
    let { influencers, selectedItems } = this.state

    influencers.forEach((item, index) => {
      if (item.id === id) {
        influencers.splice(index, 1)
      }
    })

    selectedItems.forEach((value, index) => {
      if (value === id) {
        selectedItems.splice(index, 1)
      }
    })

    this.setState({
      influencers,
      selectedItems,
    })
  }

  onContinue() {
    let data = {
      type: this.state.type,
      influencers: this.state.influencers,
    }

    setSession('campaign', data)

    redirect('/advertiser/campaign/create')
  }

  render() {
    // const selectedButton = (
    //   <FlatButton label="Selected" primary={true} icon={<SelectIcon />} />
    // )

    const { list, meta, loading } = this.state
    const { influencer, setting } = this.props

    return (
      <section>
        <PreLoader open={influencer.loading && this.state.id === ''} />

        <InfluencerView id={this.state.id}
          open={this.state.open}
          onClose={this.toggleOpen} />

        <CampaignType open={this.state.openType}
          clickRate={setting.detail.ppc_min_click}
          onSubmit={this.onTypeSubmit}
          onClose={this.toggleCreate} />

        <InfluencerToolbar
          meta={meta}
          loading={loading}
          onChange={this.filterChanged}
          onReload={this.handleReload}
          createMode={this.state.createMode}
          onCreate={this.toggleCreate} />

        <div style={{ height: 58 }} />

        <InfiniteList value={list}  height={240}>
          {(row) => (
            <InfluencerItem key={row.id}
              id={row.id}
              name={row.name}
              email={row.email}
              image={row.image}
              fee={row.payment.post_fee}
              location={row.audience.location}
              categories={row.audience.categories || []}
              posts={row.score.posted_campaigns}
              facebook={row.facebook.follower_count}
              twitter={row.twitter.follower_count}
              linkedin={row.linkedin.follower_count}
              type={this.state.type}
              selected={this.state.selectedItems.indexOf(row.id) !== -1}
              onDeselect={this.removeInfluencer}
              onSelect={this.addInfluencer}
              onView={this.toggleOpen} />
          )}
        </InfiniteList>

        <div style={{ height: 78 }} />

        <BottomToolbar open={this.state.createMode}
          type={this.state.type}
          influencers={this.state.influencers}
          onContinue={this.onContinue}
          onClose={this.toggleBottomToolbar} />
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  findInfluencer,
  clearNotif,
})(Influencer)
