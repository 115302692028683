import api from '../api'

/*
 *  action constants
 */

export const CREATE_AUTOPOST  = 'CREATE_AUTOPOST'
export const GET_AUTOPOST  = 'GET_AUTOPOST'
export const UPDATE_AUTOPOST  = 'UPDATE_AUTOPOST'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createAutopost = (data) => (dispatch) => {
  dispatch({
    type: CREATE_AUTOPOST,
    payload: api.post('/me/autopost', data),
  })
}

export const getAutopost = (id) => (dispatch) => {
  dispatch({
    type: GET_AUTOPOST,
    payload: api.get('/me/autopost'),
  })
}

export const updateAutopost = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_AUTOPOST,
    payload: api.patch('/me/autopost', data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
