import React from 'react'

import { blueGrey400, yellow600, red500, green500, lightBlue500, black500 }
  from 'material-ui/styles/colors'

const getStatusColor = (status) => {
  switch (status.toString().toLowerCase()) {
  case 'draft':
  case 'deferred':
    return blueGrey400
  case 'pending':
    return yellow600
  case 'published':
  case 'accepted':
    return lightBlue500
  case 'completed':
  case 'posted':
    return green500
  case 'terminated':
  case 'denied':
  case 'expired':
    return red500
  default:
    return black500
  }
}

export default (props) => (
  <span style={{
    textTransform: 'capitalize',
    color: getStatusColor(props.status || ''),
  }}>{props.status || ''}</span>
)
