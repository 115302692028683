import React, { Component } from 'react'
import { connect } from 'react-redux'

import numeral from 'numeral'
import TextField from 'material-ui/TextField'
import { Card, CardActions } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import SearchIcon from 'material-ui/svg-icons/action/search'
import NextIcon from 'material-ui/svg-icons/navigation/chevron-right'
import PrevIcon from 'material-ui/svg-icons/navigation/chevron-left'
import RefreshIcon from 'material-ui/svg-icons/action/cached'
import { Table, TableBody, TableHeader, TableHeaderColumn,
  TableRow, TableRowColumn } from 'material-ui/Table'

import { formatDate, formatAmount, redirect, setTitle } from '../utils'
import { findTransaction, clearNotif } from '../actions/transaction'

import ProgressBar from '../components/ProgressBar'
import Notify from '../components/Notify'

import TransactionScore from './TransactionScore'
import TransactionView from './TransactionView'

const mapStateToProps = (state, ownProps) => {
  return {
    transaction: state.transaction,
  }
}

const title = 'Transaction'

class Transaction extends Component {
  state = {
    // data
    id: '',
    list: [],

    // notify
    notifyOpen: false,
    notifyMessage: '',

    // dialogs
    dialogOpen: false,

    // table
    pageOffset: 0,
    pageLimit: 5,
    searchKey: '',
  }

  constructor(props) {
    super(props)

    // table
    this.refreshHanlder = this.refreshHanlder.bind(this)
    this.submitSearchHandler = this.submitSearchHandler.bind(this)
    this.changeSearchHandler = this.changeSearchHandler.bind(this)
    this.changeOffsetHandler = this.changeOffsetHandler.bind(this)
    this.prevHandler = this.prevHandler.bind(this)
    this.nextHandler = this.nextHandler.bind(this)

    this.closeDialogHandler = this.closeDialogHandler.bind(this)
  }

  componentWillMount() {
    setTitle(title)

    this.getList()
  }

  componentWillReceiveProps(nextProps) {
    let { transaction } = nextProps

    if (nextProps.params.id !== undefined) {
      this.setState({
        id: nextProps.params.id,
        dialogOpen: true,
      })
    }

    this.setState({
      list: transaction.list || [],
      notifyOpen: transaction.notify.open,
      notifyMessage: transaction.notify.message,
    })

    // button page status
    let prevButtonDisabled = false
    let nextButtonDisabled = false

    if (transaction.loading) {
      prevButtonDisabled = true
    } else if (this.state.pageOffset > 0) {
      prevButtonDisabled = false
    } else {
      prevButtonDisabled = true
    }

    if (transaction.loading) {
      nextButtonDisabled = true
    } else if (transaction.meta.total_count
    > this.state.pageOffset + transaction.meta.result_count) {
      nextButtonDisabled = false
    } else {
      nextButtonDisabled = true
    }

    this.setState({ prevButtonDisabled, nextButtonDisabled })
  }

  getList() {
    this.props.findTransaction({
      pageOffset: this.state.pageOffset,
      pageLimit: this.state.pageLimit,
      searchKey: this.state.searchKey,
    })
  }

  changeSearchHandler(e, value) {
    this.setState({searchKey: value})
  }

  submitSearchHandler(e) {
    // enter key is hit
    if (e.keyCode === 13) {
      this.refreshHanlder()
    }
  }

  changeOffsetHandler(event, index, value) {
    this.setState({
        pageLimit: value,
        pageOffset: 0,
    })

    this.refreshHanlder()
  }

  refreshHanlder() {
    this.getList()
  }

  nextHandler(event, index, value) {
    let { pageOffset, pageLimit } = this.state
    this.setState({
        pageOffset: pageOffset + pageLimit,
    })

    this.refreshHanlder()
  }

  prevHandler() {
    let { pageOffset, pageLimit } = this.state
    this.setState({
        pageOffset: pageOffset - pageLimit,
    })

    this.refreshHanlder()
  }

  closeDialogHandler() {
    this.setState({
      id: '',
      dialogOpen: false,
    })

    redirect('/advertiser/transaction')
  }

  render() {
    let { transaction } = this.props

    const headerActions = (
      <CardActions>
        <TextField hintText="Search and hit enter"
          style={{ marginLeft: 10 }}
          onKeyDown={this.submitSearchHandler}
          onChange={this.changeSearchHandler} />
        <IconButton style={{ bottom: -8 }}>
          <SearchIcon />
        </IconButton>

        <div style={{ float: 'right' }}>
          <IconButton style={{ bottom: -6 }} onClick={this.refreshHanlder} >
            <RefreshIcon />
          </IconButton>
        </div>
      </CardActions>
    )

    const footerActions = (
      <CardActions>
        <SelectField floatingLabelText="Rows per page"
          floatingLabelFixed={true}
          style={{
            width: 110,
            marginLeft: 10,
          }}
          value={this.state.pageLimit}
          onChange={this.changeOffsetHandler} >
          <MenuItem value={5} primaryText="5" />
          <MenuItem value={15} primaryText="15" />
          <MenuItem value={50} primaryText="50" />
          <MenuItem value={100} primaryText="100" />
        </SelectField>

        <span style={{ float: 'right', marginTop: 30 }}>
          <span style={{
             margin: 6,
             visibility: transaction.loading ? 'hidden' : 'visible',
           }}>
           {this.state.pageOffset + transaction.meta.result_count} - {transaction.meta.total_count}
         </span>

          <FlatButton label="Prev" icon={<PrevIcon />} primary={true}
            disabled={this.state.prevButtonDisabled}
            onClick={this.prevHandler} />
          <FlatButton label="Next" labelPosition="before" icon={<NextIcon />}
            primary={true} disabled={this.state.nextButtonDisabled}
            onClick={this.nextHandler} />
        </span>
      </CardActions>
    )

    return (
      <section className="content-wrapper">
        <TransactionScore />
        <br />
        <Card className="card-override">
          <ProgressBar open={transaction.loading && !this.state.id} />

          { headerActions }
          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}
              enableSelectAll={false} >
              <TableRow>
                <TableHeaderColumn>Campaign</TableHeaderColumn>
                <TableHeaderColumn>Type</TableHeaderColumn>
                <TableHeaderColumn>Amount</TableHeaderColumn>
                <TableHeaderColumn>Date</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false} deselectOnClickaway={false}
              showRowHover={true} >
                {this.state.list.map((row, index) => (
                  <TableRow key={index} selected={row.selected}>
                    <TableRowColumn>
                      <a href={'/#/advertiser/transaction/' + row.id + '/view'}>{row.name}</a>
                    </TableRowColumn>
                    <TableRowColumn>{row.campaign.type || '-'}</TableRowColumn>
                    <TableRowColumn>{numeral(formatAmount(row.amount)).format('0,0.00')}</TableRowColumn>
                    <TableRowColumn>{formatDate(row.created_at)}</TableRowColumn>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          { footerActions }
        </Card>

        <TransactionView
          id={this.state.id}
          open={this.state.dialogOpen}
          onRequestClose={this.closeDialogHandler} />

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  findTransaction,
  clearNotif,
})(Transaction)
