import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setTitle } from '../utils'
// import { serverEventListener } from '../utils/sse'
import { getOverviewScore } from '../actions/overview'
import { findInvitation, updateInvitation, clearNotif }
  from '../actions/invitation'

import Notify from '../components/Notify'
import PreLoader from '../components/PreLoader'
import InvitationItem from '../components/InvitationItem'
import OverviewScore from './OverviewScore'
import OverviewClick from '../containers/OverviewClick'
import OverviewSource from '../containers/OverviewSource'
import OverviewPlatform from '../containers/OverviewPlatform'
import PostDialog from './PostDialog'
import EmptyOverview from './EmptyOverview'

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
    invitation: state.invitation,
  }
}

const title = 'Overview'

class Overview extends Component {
  state = {
    index: null,
    list: [],
    detail: {},
    campaign: {},

    postDialogOpen: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  constructor(props) {
    super(props)

    this.onPost = this.onPost.bind(this)
    this.togglePostDialog = this.togglePostDialog.bind(this)
  }

  componentWillMount() {
    setTitle(title)

    // let self = this
    // serverEventListener('invitation_changes', function(e) {
    //   self.props.findInvitation()
    // })

    this.props.findInvitation()
    this.props.getOverviewScore()
  }

  componentWillReceiveProps(nextProps) {
    let { invitation } = nextProps

    this.setState({
      list: invitation.list || [],

      notifyOpen: invitation.notify.open,
      notifyMessage: invitation.notify.message,
    })
  }

  togglePostDialogX() {
    let { postDialogOpen, detail, campaign } = this.state

    postDialogOpen = !postDialogOpen
    if (!postDialogOpen) {
      detail = {}
      campaign = {}

      this.props.getOverviewScore()
    }

    this.setState({
      postDialogOpen,
      detail,
      campaign,
    })
  }

  togglePostDialog(index) {
    let { postDialogOpen, list } = this.state
    let detail = list[index]
    postDialogOpen = !postDialogOpen
    if (!postDialogOpen) {
      detail = {}
    }
    
    console.log(index);
    console.log(detail);
    console.log(list);
    
    this.setState({
      postDialogOpen,
      index,
      detail,
    })
  }

  removeInvitation(index) {
    let { list } = this.state
    list.splice(index, 1)
    this.setState({
      list,
    })
  }

  onClickAction(index, inviteId, status) {
    switch (status) {
    case 'denied':
    case 'deferred':
      this.props.updateInvitation(inviteId, {
        status,
      })

      this.removeInvitation(index)
      return
    case 'accepted':
      this.setState({ index })
      
      this.props.updateInvitation(inviteId, {
        status,
      })
      return
    case 'posted':
      let invite = this.state.list[index]
      this.setState({
        index,
        detail: invite,
        campaign: invite.campaign,
      })

      this.togglePostDialog(index)
      return
    // no default
    }
  }

  onPost(campaignId) {
    console.log('onPost campaignId', campaignId)
    let { index, detail } = this.state

    this.props.updateInvitation(detail.id, {
      status: 'posted',
    })

    this.removeInvitation(index)
    this.togglePostDialog(index)
  }

  render() {
    let { overview, invitation } = this.props
    let posts = overview.score.campaign_post
    let { campaign } = this.state

    if (posts === undefined) {
      return (<PreLoader open={true} />)
    }
      
    return (
      <div>
        <PreLoader open={overview.loading || invitation.loading} />

        <PostDialog open={this.state.postDialogOpen}
          id={campaign.id}
          campaignId={campaign.id}
          title={campaign.title}
          description={campaign.description}
          image={campaign.image}
          url={campaign.url}
          note={campaign.note}
          onClose={this.togglePostDialog}
          onPost={this.onPost} />

        {this.state.list.map((row, index) => (
          <InvitationItem key={index}
            index={index}
            id={row.id}
            advertiser={row.campaign.advertiser.name}
            title={row.campaign.title}
            description={row.campaign.description}
            image={row.campaign.image}
            url={row.campaign.url}
            note={row.campaign.note}
            price={row.campaign.price}
            maxClick={row.campaign.max_click}
            clickRate={row.campaign.click_rate}
            type={row.campaign.type}
            onClickAction={this.onClickAction.bind(this)} />
        ))}

        {posts === 0 ? (
          <EmptyOverview />
        ) : (
          <div>
            <OverviewScore />
            <br />
            <OverviewClick />
            <br />
            <OverviewSource />
            <br />
            <OverviewPlatform />
            <br />
          </div>
        )}

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewScore,
  findInvitation,
  updateInvitation,
  clearNotif,
})(Overview)
