import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import TextField from 'material-ui/TextField'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import { loginUser, clearNotif } from '../actions/user'
import { isLogin } from '../utils'

import ProgressBar from '../components/ProgressBar'
import Notify from '../components/Notify'

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    app: state.app,
  }
}

class Login extends Component {
  state = {
    emailField: '',
    passwordField: '',

    notifyOpen: false,
    notifyMessage: '',
  }

  constructor(props) {
    super(props)

    this.submitHandler = this.submitHandler.bind(this)
    this.submitOnEnterHandler = this.submitOnEnterHandler.bind(this)
  }

  submitHandler() {
    let email = this.state.emailField
    let password = this.state.passwordField

    // check

    this.props.loginUser(email, password)
  }

  submitOnEnterHandler(e) {
    // enter key is hit
    if (e.keyCode === 13) {
      this.submitHandler()
    }
  }

  componentWillReceiveProps(nextProps) {
    let { user } = nextProps

    // redirect to / when success
    if (isLogin()) {
      window.location.reload()

      return
    }

    this.setState({
      notifyOpen: user.notify.open,
      notifyMessage: user.notify.message,
    })
  }

  render() {
    let { user } = this.props

    return (
      <div>
        <br />
        <Card className="card-override">
          <ProgressBar open={user.loading} />

          <CardHeader title="MashDrop Advertiser" subtitle="Authentication" />
          <CardText>
            <TextField floatingLabelText="Email" fullWidth={true}
              defaultValue={user.detail.email}
              floatingLabelFixed={true}
              onChange={(e) => {this.setState({emailField: e.target.value })}} />
            <br />
            <TextField floatingLabelText="Password" fullWidth={true}
              onKeyDown={this.submitOnEnterHandler}
              floatingLabelFixed={true} type="password"
              onChange={(e) => {this.setState({passwordField: e.target.value })}} />
            <br />
          </CardText>

          <CardActions style={{textAlign: 'center'}}>
            <FlatButton label="Login" primary={true}
              disabled={user.loading}
              onClick={this.submitHandler} />
          </CardActions>
        </Card>
        <br />

        <Link to="/advertiser/register">
          <RaisedButton label="Register" primary={true} />
        </Link>
        <Link to="/advertiser/forget">
          <FlatButton label="Forgot Password?" />
        </Link>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  loginUser,
  clearNotif,
})(Login)
