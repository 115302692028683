import React, { Component } from 'react'

import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import { GridList, GridTile } from 'material-ui/GridList'
import { Card, CardActions, CardText } from 'material-ui/Card'
import PostIcon from 'material-ui/svg-icons/social/public'
import DenyIcon from 'material-ui/svg-icons/content/clear'
import AcceptIcon from 'material-ui/svg-icons/navigation/check'
import LinkIcon from 'material-ui/svg-icons/action/open-in-new'
import LaterIcon from 'material-ui/svg-icons/action/watch-later'
import TextHighlighter from './TextHighlighter'

class InvitationItem extends Component {
  render() {
    return (
      <Card className="card-override" expanded={true} style={{
          marginBottom: 20,
          background: 'rgb(255, 243, 224)',
        }}>
        <CardText>
          {this.props.type === 'PPP' ? (
            <p>You hace been invited by <TextHighlighter>{this.props.advertiser}</TextHighlighter> for PPP campaign</p>
          ) : (
            <p>You hace been selected by <TextHighlighter>{this.props.advertiser}</TextHighlighter> for PPC campaign</p>
          )}
          <GridList cols={1} cellHeight={200} >
            <GridTile
              title={this.props.title || '-'}
              actionIcon={<IconButton href={this.props.url || null} target="_blank"><LinkIcon color="white" /></IconButton>}
              subtitle={this.props.description || '-'}>
              <img src={this.props.image || null} alt={this.props.image || null} />
            </GridTile>
          </GridList>
          {true || this.props.note === '' ? null : (
            <p><strong>Note</strong>: {this.props.note}</p>
          )}
        </CardText>
        <CardActions>
          {this.props.type === 'PPP' ? (
            <div style={{
                textAlign: 'center',
              }}>

              <FlatButton label="Declined" icon={<DenyIcon />}
                onClick={this.props.onClickAction.bind(this, this.props.index, this.props.id, 'denied')} />
              <RaisedButton label="Accept" primary={true} icon={<AcceptIcon />}
                onClick={this.props.onClickAction.bind(this, this.props.index, this.props.id, 'accepted')} />
            </div>
          ) : (
            <div style={{
                textAlign: 'center',
              }}>

              <FlatButton label="Later" icon={<LaterIcon />}
                onClick={this.props.onClickAction.bind(this, this.props.index, this.props.id, 'deferred')} />
              <RaisedButton label="POST NOW" primary={true} icon={<PostIcon />}
                onClick={this.props.onClickAction.bind(this, this.props.index, this.props.id, 'posted')} />
            </div>
          )}
        </CardActions>
      </Card>
    )
  }
}

export default InvitationItem
