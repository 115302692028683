import React, { Component } from 'react'

import { grey500, teal500, indigo500 } from 'material-ui/styles/colors'

import { clearAuth } from '../utils'
import Footer from './Footer'

class Error extends Component {
  render() {
    return (
      <section style={{
          textAlign: 'center',
          marginTop: 100,
          color: grey500,
          Height: 500,
        }}>
        <h1 style={{
            fontSize: 100,
          }} >Ooops!</h1>
        <h4>Something went wrong</h4>
        <br />
        <p>We suggest to do the actions below</p>
        <a href="/#/" style={{color: teal500}}
          onClick={() => {
            window.history.back()
          }}>
          Go Back
        </a>
        <br />
        <a href="/#/" style={{color: indigo500}}
          onClick={() => {
            clearAuth()

            window.location = '/#/'
          }}>
          Clear Session
        </a>
        <br />
        <a href="http://mashdrop.com">
          Go to Website
        </a>

        <Footer />
      </section>
    )
  }
}

export default Error
