import api from '../api'

/*
 *  action constants
 */

export const FIND_POST              = 'FIND_POST'
export const CREATE_POST            = 'CREATE_POST'
export const CREATE_POST_FB_GROUP   = 'CREATE_POST_FB_GROUP'
export const GET_POST               = 'GET_POST'
export const UPDATE_POST            = 'UPDATE_POST'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createPost = (data) => (dispatch) => {
  dispatch({
    type: CREATE_POST,
    payload: api.post('/post', data),
  })
}

export const createFbPostOnGroup = (id = '', data) => (dispatch) => {
  dispatch({
    type: CREATE_POST_FB_GROUP,
    payload: api.post('/post-fb/group/' + id, data),
  })
}

export const findPost = () => (dispatch) => {
  dispatch({
    type: GET_POST,
    payload: api.get('/post'),
  })
}

export const getPost = (id) => (dispatch) => {
  dispatch({
    type: GET_POST,
    payload: api.get('/post/' + id),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
