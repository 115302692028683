import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardHeader, CardText } from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'

import { getOverviewScore } from '../actions/overview'
import { formatFigure } from '../utils'

import Score from '../components/Score'

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
  }
}

class OverviewScore extends Component {
  state = {
    score: {},
  }

  componentWillMount() {
    this.props.getOverviewScore()
  }

  componentWillReceiveProps(nextProps) {
    let { overview } = nextProps

    this.setState({
      score: overview.score,

      // notifyOpen: overview.notify.open,
      // notifyMessage: overview.notify.message,
    })
  }

  render() {
    return (
      <Card className="card-override">
        <CardHeader title="Dashboard" />
        <CardText>
          <GridList cols={5} cellHeight={50}>
            <GridTile>
              <Score title={'Total Campaigns'} value={this.state.score.total_campaign || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Published Campaigns'} value={this.state.score.running_campaign || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Total Clicks'} value={this.state.score.total_clicks || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Average Clicks'} value={this.state.score.average_clicks || '-'} />
            </GridTile>
            <GridTile>
              <Score title={'Total Buy'} value={formatFigure(this.state.score.total_buy)} />
            </GridTile>
          </GridList>
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewScore,
})(OverviewScore)
