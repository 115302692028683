import React, { Component } from 'react'

import AutoComplete from 'material-ui/AutoComplete'

const leeway = 300

class LocationInput extends Component {
  state = {
    value: {},
    dataSource: [],
    loading: false,
    typing: false,
    timer: null,
  }

  constructor(props) {
    super(props)

    // let autocompleteService
    this.getPredictions = this.getPredictions.bind(this)
  }

  componentWillMount() {
    clearTimeout(this.state.timer);

    let googleMaps = window.google && window.google.maps;
    if (!googleMaps) {
      console.error('Google map api was not found in the page.')
      return
    }

    this.autocompleteService = new googleMaps.places.AutocompleteService()
  }

  getPredictions(options) {
    this.autocompleteService.getPlacePredictions(options, (suggestsGoogle, status) => {
      // dont change value when typing
      if (this.state.typing) {
        return
      }

      // when result is only one prefill value
      let value = {}
      if (suggestsGoogle !== undefined && suggestsGoogle.length === 1) {
        value = suggestsGoogle[0].description

        this.props.onChange(value)
      }

      this.setState({
        value,
        dataSource: suggestsGoogle || [],
        loading: false,
      })
    })
  }

  handleUpdateInput(value) {
    this.props.onChange(value)

    // clear timer
    clearTimeout(this.state.timer)

    let delayer = () => {
      this.setState({ typing: false })

      // dont search if empty and clear value
      if (value.trim() === '') {
        this.setState({
          value: {},
        })

        return
      }

      this.getPredictions({
        input: value,
        types: ['(regions)'],
      })
    }

    // set timeout
    let timer = setTimeout(delayer.bind(this), leeway)

    // delay input
    this.setState({
      loading: true,
      typing: true,
      timer,
    })
  }

  render() {
    return (
      <AutoComplete
        {...this.props}
        dataSource={this.state.dataSource}
        filter={AutoComplete.noFilter}
        onUpdateInput={this.handleUpdateInput.bind(this)}
        dataSourceConfig={{
          text: 'description',
          value: 'id',
        }}
      />
    )
  }
}

export default LocationInput
