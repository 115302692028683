import React from 'react'

import muiThemeable from 'material-ui/styles/muiThemeable'
import WarnIcon from 'material-ui/svg-icons/av/new-releases'

export default muiThemeable()((props) => (
  <p style={{textAlign: 'center'}}>
    <WarnIcon style={{marginBottom: -7, marginRight: 4}}
      color={props.muiTheme.palette.accent1Color} />
    {props.children}
  </p>
))
