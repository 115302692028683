import api from '../api'

/*
 *  action constants
 */

export const LOGIN_TWITTER     = 'LOGIN_TWITTER'
export const CONNECT_TWITTER     = 'CONNECT_TWITTER'
export const GET_TWITTER       = 'GET_TWITTER'
export const REMOVE_TWITTER       = 'REMOVE_TWITTER'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const loginTwitter = (token, verifier) => (dispatch) => {
  let params = '?oauth_token=' + token + '&oauth_verifier=' + verifier

  dispatch({
    type: LOGIN_TWITTER,
    payload: api.get('/twitter/callback' + params),
  })
}

export const connectTwitter = (token, verifier) => (dispatch) => {
  let params = '?oauth_token=' + token + '&oauth_verifier=' + verifier

  dispatch({
    type: CONNECT_TWITTER,
    payload: api.get('/twitter/connect' + params),
  })
}

export const getTwitter = (option = '') => (dispatch) => {
  dispatch({
    type: GET_TWITTER,
    payload: api.get('/me/twitter' + option),
  })
}

export const removeTwitter = () => (dispatch) => {
  dispatch({
    type: REMOVE_TWITTER,
    payload: api.delete('/me/twitter'),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
