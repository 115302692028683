import React from 'react'

import ReactTooltip from 'react-tooltip'
import muiThemeable from 'material-ui/styles/muiThemeable'

import FontIcon from 'material-ui/FontIcon'

const styles = {
  socialIcon: {
    fontSize: 15,
    marginLeft: 8,
    marginTop: 15,
  },
}

export default muiThemeable()((props) => (
  <div>
    <FontIcon style={styles.socialIcon} data-tip="Clicks" className="fa fa-mouse-pointer"/> {props.clicks} &nbsp; &middot;
    <FontIcon style={styles.socialIcon} data-tip="Facebook Likes" className="fa fa-heart"/> {props.facebook[0]} &nbsp; &middot;
    <FontIcon style={styles.socialIcon} data-tip="Facebook Comments" className="fa fa-comments"/> {props.facebook[1]} &nbsp; &middot;
    <FontIcon style={styles.socialIcon} data-tip="Facebook Shares" className="fa fa-share-alt"/> {props.facebook[2]}

    <ReactTooltip />
  </div>
))
