import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Snackbar from 'material-ui/Snackbar'

class Notify extends Component {
  render() {
    return (
      <Snackbar
        open={this.props.open}
        message={this.props.message}
        onRequestClose={this.props.onRequestClose}
        action={this.props.action}
        autoHideDuration={4000} />
    )
  }
}

Notify.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}

Notify.defaultProps = {
  open: false,
}

export default Notify
