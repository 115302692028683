import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'
import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'

import { setTitle } from '../utils'
import { createPasswordReset } from '../actions/password_reset'
import { getUser, updateUser, clearNotif } from '../actions/user'

import ProgressBar from '../components/ProgressBar'
import Notify from '../components/Notify'

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  }
}

const title = 'Account Setting'

class Account extends Component {
  state = {
    dataName: '',
    dataContactName: '',
    dataEmail: '',

    notifyOpen: false,
    notifyMessage: '',

    dialogOpen: false,
  }

  constructor(props) {
    super(props)

    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.resetPasswordSumbitHandler = this.resetPasswordSumbitHandler.bind(this)
    this.infoSaveHandler = this.infoSaveHandler.bind(this)
  }

  componentWillMount() {
    setTitle(title)

    this.props.getUser()
  }

  componentWillReceiveProps(nextProps) {
    let { user } = nextProps

    if (user.detail.name === undefined) {
      return
    }

    this.setState({
      dataName: user.detail.name,
      dataContactName: user.detail.contact_name,
      dataEmail: user.detail.email,

      notifyOpen: user.notify.open,
      notifyMessage: user.notify.message,
    })
  }

  closeDialog() {
    this.setState({
      dialogOpen: false,
    })
  }

  openDialog() {
    this.setState({
      dialogOpen: true,
    })
  }

  infoSaveHandler() {
    let user = {
      name: this.state.dataName,
      contact_name: this.state.dataContactName,
    }

    // check

    this.props.updateUser(user)
  }

  resetPasswordSumbitHandler() {
    this.props.createPasswordReset({
      email: this.state.dataEmail,
      callback: window.location.origin + '/#/advertiser/password',
    })

    this.closeDialog()
  }

  render() {
    let { user } = this.props

    const dialogActions = [
      <FlatButton
        label="Cancel"
        primary={false}
        onClick={this.closeDialog} />,
      <FlatButton
        label="Submit"
        primary={true}
        onClick={this.resetPasswordSumbitHandler} />,
    ];

    return (
      <section className="content-wrapper">
        <Dialog
          title="Reset Password"
          actions={dialogActions}
          modal={true}
          open={this.state.dialogOpen}
          onRequestClose={this.closeDialog} >
          This will send instruction to your email upon submit
        </Dialog>

        <Card className="card-override">
          <ProgressBar open={user.loading} />

          <CardHeader subtitle="Basic Information" />
          <CardText>
            <TextField fullWidth={true} floatingLabelFixed={true}
              value={this.state.dataName} floatingLabelText="Name"
              onChange={(e) => {this.setState({dataName: e.target.value})}} />
            <br />
            <TextField fullWidth={true} floatingLabelFixed={true}
              value={this.state.dataContactName} floatingLabelText="Contact Name"
              onChange={(e) => {this.setState({dataContactName: e.target.value})}} />
            <br />
            <TextField fullWidth={true} floatingLabelFixed={true}
              value={this.state.dataEmail} floatingLabelText="Email"
              disabled={true} />
            <br />
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            <FlatButton label="Reset Password" disabled={user.loading}
              onClick={this.openDialog} />
            <FlatButton label="Save" primary={true} disabled={user.loading}
              onClick={this.infoSaveHandler} />
          </CardActions>
        </Card>
        <br />

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  getUser,
  updateUser,
  clearNotif,
  createPasswordReset,
})(Account)
