import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { isLogin, setContext } from '../utils'
import { flashMessage } from '../actions/app'

import Theme from './Theme'
import Footer from '../components/Footer'
import Notify from '../components/Notify'

const styles = {
  content: {
    maxWidth: 600,
    margin: 'auto',
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    app: state.app,
  }
}

class Auth extends Component {
  state = {
    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    setContext(this.context)

    // check if token exists
    if (isLogin() !== true) {
      return
    }
  }

  componentWillReceiveProps(nextProps) {
    let { app } = nextProps

    if (app.notify.text !== '') {
      this.setState({
        notifyOpen: true,
        notifyMessage: app.notify.text,
      })
    }
  }

  closeNotify() {
    this.props.flashMessage()

    this.setState({
      notifyOpen: false,
    })
  }

  render() {
    return (
      <Theme>
        <div>
          <div style={styles.content}>
            {this.props.children}
          </div>

          <Footer />

          <Notify
            open={this.state.notifyOpen}
            message={this.state.notifyMessage}
            onRequestClose={this.closeNotify} />
        </div>
      </Theme>
    )
  }
}

Auth.contextTypes = {
  router: PropTypes.object
};

// export default Auth
export default connect(mapStateToProps, {
  flashMessage
})(Auth)
