import api, { API_HOST } from '../api'

const endpoint = '/upload'

export const upload = (file, onSuccess, onError, onProgress) => {
  let config = {
    onUploadProgress: function(progressEvent) {
      if (onProgress === undefined) {
        return
      }

      let percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

      onProgress(percentage)
    }
  };

  let data = new FormData();

  // check if blob
  if (file.constructor === Blob) {
    data.append('file', file, file.name);
  } else {
    data.append('file', file);
  }

  api.post(endpoint, data, config)
    .then(function (res) {
      if (onSuccess === undefined) {
        return
      }

      // return full path
      let path = API_HOST + endpoint + '/' + res.data.id

      onSuccess(path, res.data);
    })
    .catch(function (err) {
      if (onError === undefined) {
        return
      }

      onError(err);
    })
}
