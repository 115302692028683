import PropTypes from 'prop-types';
import React, { Component } from 'react'

import { isLogin, setContext } from '../utils'
import Theme from './Theme'

const styles = {
  content: {
    maxWidth: 600,
    margin: 'auto',
  },
}

class Auth extends Component {
  componentWillMount() {
    setContext(this.context)

    // check if token exists
    if (isLogin() !== true) {
      return
    }

    // redirect('/')
  }

  render() {
    return (
      <Theme>
        <div style={styles.content}>
          {this.props.children}
        </div>
      </Theme>
    )
  }
}

Auth.contextTypes = {
  router: PropTypes.object
};

export default Auth
