import React, { Component } from 'react'

import Note from '../components/Note'
import TextField from 'material-ui/TextField'

class CampaignCreateInfo extends Component {
  state = {
    title: '',
    description: '',
    note: '',
    url: '',
  }

  componentWillMount() {
    let { title, description, note, url } = this.props

    this.setState({
      title,
      description,
      note,
      url,
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value

    this.setState(state)

    this.props.onChange(field, value)
  }

  render() {
    return (
      <div>
        <p style={{textAlign: 'center'}}>All fields are required</p>
        <TextField
          floatingLabelText="Title"
          floatingLabelFixed={true}
          fullWidth={true}
          value={this.state.title}
          hintText="A clear title without branding or mentioning the domain itself"
          onChange={this.onChange.bind(this, 'title')} />
        <br />
        <TextField
          floatingLabelText="Description"
          floatingLabelFixed={true}
          multiLine={true}
          fullWidth={true}
          value={this.state.description}
          hintText="A clear description, at least two sentences long"
          onChange={this.onChange.bind(this, 'description')} />
        <br />
        <TextField
          floatingLabelText="Note to Influencer"
          floatingLabelFixed={true}
          multiLine={true}
          fullWidth={true}
          value={this.state.note}
          hintText="Specific instructions to influencers"
          onChange={this.onChange.bind(this, 'note')} />
        <br />
        <TextField
          floatingLabelText="Link"
          hintText="http://example.com"
          floatingLabelFixed={true}
          fullWidth={true}
          value={this.state.url}
          onChange={this.onChange.bind(this, 'url')} />
        <br />
        <Note>
          We prodivde UTM parameters to track campaign performance. <a href="/#/advertiser/guide?slug=utm-parameters" target="_blank">Learn more</a>
        </Note>
      </div>
    )
  }
}

export default CampaignCreateInfo
