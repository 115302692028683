import React, { Component } from 'react'

import { grey500, teal500, indigo500 } from 'material-ui/styles/colors'

import { getQuery } from '../utils/parse'

import Footer from './Footer'

class NotFound extends Component {
  componentWillMount() {
    // redirect flag
    let path = getQuery('redirect')
    if (path !== '') {
      this.redirect('/#/redirect' + path + window.location.search)

      return
    }
  }

  redirect(path) {
    window.location = window.location.origin + path
  }

  render() {
    if (getQuery('redirect') !== '') {
      return null
    }

    return (
      <section style={{
          textAlign: 'center',
          marginTop: 100,
          color: grey500,
          Height: 500,
        }}>
        <h1 style={{
            fontSize: 100,
          }} >404</h1>
        <h4>Page not found</h4>
        <br />
        <p>Why don't you try one of these pages instead?</p>
        <a href="/#/advertiser" style={{color: teal500}}>
          Advertiser
        </a>
        <br />
        <a href="/#/influencer" style={{color: indigo500}}>
          Influencer
        </a>
        <br />
        <a href="/">
          Home
        </a>

        <Footer />
      </section>
    )
  }
}

export default NotFound
