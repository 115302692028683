import React from 'react'

import { grey500 } from 'material-ui/styles/colors'

import { toSiteURL } from '../utils'

const styles = {
  wrapper: {
    color: grey500,
    textAlign: 'center',
    margin: 40,
    left: 10,
    right: 10,
    paddingTop: 40,
  },
  listLeft: {
    listStyle: 'none',
    float: 'left',
    padding: 0,
    margin: 0,
  },
  listRight: {
    listStyle: 'none',
    float: 'right',
    padding: 0,
    margin: 0,
  },
  item: {
    display: 'inline-block',
    marginRight: 10,
    marginLeft: 10,
  },
}

export default () => (
  <div style={styles.wrapper}>
    <p>
      <a href={toSiteURL()}><img src="/img/logo.png" alt="" width="25" /></a>
    </p>
    <ul style={styles.listLeft}>
      <li style={styles.item}>&copy; 2017 MashDrop, Inc.</li>
      <li style={styles.item}>
        <a href={toSiteURL('/terms')}>Terms</a>
      </li>
      <li style={styles.item}>
        <a href={toSiteURL('/privacy')}>Privacy</a>
      </li>
      <li style={styles.item}>
        <a href={toSiteURL('/faq')}>FAQ</a>
      </li>
    </ul>
    <ul style={styles.listRight}>
      <li style={styles.item}>
        <a href={toSiteURL('/support')}>Support</a>
      </li>
      <li style={styles.item}>
        <a target="_blank" rel="noopener noreferrer" href="http://blog.mashdrop.com">Blog</a>
      </li>
      <li style={styles.item}>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/mashdrop">Twitter</a>
      </li>
      <li style={styles.item}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/mashdropinc">Facebook</a>
      </li>
    </ul>
  </div>
)
