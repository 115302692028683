import React from 'react'

import muiThemeable from 'material-ui/styles/muiThemeable'

export default muiThemeable()((props) => (
  <span style={{
    color: props.muiTheme.palette.accent1Color,
  }}>
    {props.children}
  </span>
))
