import React, {Component} from 'react'

import { setAuth } from '../utils'

const loginByAuthPayload = (id, token) => {
    if (id === undefined || token === undefined) {
        alert('something went wrong');

        return;
    }

    let type = 'influencer'

    // mimic login and overrides
    setAuth({
        id,
        token,
        type,
    })

    alert('You will be logged in as Influencer');
    window.location = window.location.origin + '/#/influencer/'
    window.location.reload()
}

class ForceLogin extends Component {
    componentWillMount() {
        let { props } = this
        loginByAuthPayload(props.params.id, props.params.token);
    }

    render() {
        return (
            <div style={{textAlign: 'center'}}>
              <p>Logging in...</p>
            </div>
        )
    }
}

export default ForceLogin
