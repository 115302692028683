import React, {Component} from 'react'

import { setTitle } from '../utils'

import { Card, CardText } from 'material-ui/Card'

const styles = {
  card: {
    marginBottom: 20,
  },
}

const title = 'Frequently Asked Question'

class Faq extends Component {
  state = {
  }

  componentWillMount() {
    setTitle(title)
  }

  render() {
    return (
      <section>
        <Card className="card-override" style={styles.card}>
          <CardText>
            <h3>What Am I Expected To Do To Earn Pay-Per-Post Vs Pay-Per-Click Compensation</h3>
            <p>With both PPC and PPP ad campaigns, influencers simply share the most appropriate ads supplied by advertisers on their social networks via the share tool MashDrop provides. With PPP ads, you may also want to indicate any of the following - that you will add comments and endorsements, add hashtags, amplify on all other social networks you may have including your blog or website. Specifying these added services will help to justify the pay-per-post rate that you're asking for.</p>
            <br />

            <h3>When Will I Get Paid?</h3>
            <p>For Pay-Per-Click campaigns, Influencers will be paid within 48-hours after completion of the campaign. Campaign completions will be indicated by a blue dot at the top of the PPC campaign on the user's dashboard. Payment will be via PayPal.</p>
            <p>For Pay-Per-Post campaigns, Influencers will be paid 48-hours after the campaign has been verified and posted on their social network(s). Payment will be via PayPal.</p>
            <br />

            <h3>What Can An Advertiser See On My Account</h3>
            <p>After allowing MashDrop access to your social networks accounts and upon campaign posting we pull in the following stats to share with advertisers.</p>
            <ul>
              <li>Networks posted on</li>
              <li>Number of ad clicks</li>
              <li>Average Number of Views</li>
              <li>Audience Location for Local Ads</li>
              <li>Number of Likes, Shares and Comments (when available)</li>
            </ul>
            <br />

            <h3>My Followers Are Mainly In My Local Community, Does MashDrop Offer Local Advertising</h3>
            <p>Absolutely! This is a core MashDrop feature and we are very much focused on bringing local and micro-influencers together with local advertisers and neighborhood businesses. If you have social influence within your community, church, school, business community, city or region, either exclusively or as a representative sample of your overall followers, we can help you find a local sponsor, simply add your city to the filter location.</p>
            <br />

            <h3>How Do I Connect To My Social Networks</h3>
            <p>MashDrop works with Facebook, Twitter and LinkedIn Networks. In order to sign up for a new MashDrop account you need to first connect to each of the corresponding accounts (we provide the 'connect' buttons) .</p>
            <p>Once you are connected, you will be able to further share campaigns to any social network and any clicks coming from these will be traceable back to you.</p>
            <p>Important: We do not store any passwords!</p>
            <br />

            <h3>What is CPM pricing and how is it different from CPC?</h3>
            <p>The cost per mille (CPM), is a marketing term used to denote the price of 1,000 advertisement impressions on one webpage where the advertisers pay for the number of times an ad is show regardless of whether it is clicked on or not. The cost per click (CPC), is simply the price charged per user-action (i.e.“clicks”) which subsequently transports the viewer to the advertiser’s website or destination URL.</p>
            <br />

            <h3>How Do I Determine What My Pay-Per-Post Rate Should Be</h3>
            <p>Determining what you will charge advertisers to post display ads will likely evolve over time but in the beginning is fairly straight forward: start by determining the average number of impressions your tweets (or posts) typically receive (you can find this information for your Twitter posts at: <a target="_blank" rel="noopener noreferrer" href="https://analytics.twitter.com">https://analytics.twitter.com</a>).</p>
            <p>Once you’ve determined your average number of impressions per tweet or post, multiply it by a CPM rate and convert that amount to the flat-rate per post by dividing by 1000. Display ads typically range anywhere from $2 CPM's to $10 CPM's or more in price, so chose a reasonable CPM figure.</p>
            <p>For example, let’s say your Twitter account generated 150,000 impressions over the last 24-individual tweets. Your average is just over 5,000 impressions per tweet. Multiply that by a $10 CPM and divide by 1000 to determine your Price-per-post rate of $50.00 per post (5,000 x $10/1000 = $50.00) This will give you a fairly good starting point to price your offer rate based on the CPM model. Remember, you are not creating original content for the advertiser, you simply are selecting the ads that best fit your followers interests.</p>
            <p>If you wish to add personal comments or endorsements to the post, or if your audience engages with likes, comments and shares, you might be valuable to the advertiser and worth a $10 CPM or higher rate. Determining your Pay-per-post rate is market driven, it will be dynamic and is as much an art as a science.</p>
            <br />

            <h3>What Percentage Of My 'Pay-Per-Post' Rate Offer Do I Keep</h3>
            <p>You will keep 100% of the price you post. What the advertiser sees will include a 15% addition that goes to MashDrop (multiply your rate by 1.15 to see the final pricing to include the 15% fee) For example, if you are offer price to post is $100 the advertiser will see your rate as $115 per post ($100 x 1.15 = $115)</p>
            <br />

            <h3>Are My Earnings Subject To Income Taxes</h3>
            <p>Any US influencer using MashDrop that makes over $600 over the course of a calendar year will be required to fill out a W-9 form. MashDrop collects W-9s digitally in the MashDrop dashboard under the Payment Info tab in Settings.</p>
            <p>International influencers are not required to fill out a W-9 but will have to declare they are not a US citizen during the MashDrop Payment Info process.</p>
            <p>Please note: MashDrop does not withhold any any taxes from funds received on MashDrop. Please consult a tax professional for advice and tax obligations.</p>
            <br />

            <h3>What Is a W-9</h3>
            <p>MashDrop is required by law to report any payments made over $600 over the course of a calendar year to the IRS. As such, all US influencers who make over $600 on MashDrop are required to fill out a W-9. The W-9 collects basic information such as name, address and social security number of the persons who received payment.</p>
            <br />

            <h3>What Is a 1099</h3>
            <p>A 1099 is the tax form that MashDrop will send you that will give you details on how much you were paid over the course of the year from MashDrop. 1099&#39;s are sent out by the end of January. Please note this form is only sent to US taxpayers.</p>
            <p>MashDrop does not give any tax advice. Please consult a tax professional for advice.</p>
            <br />
          </CardText>
        </Card>
      </section>
    )
  }
}

export default Faq
