import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RefreshIndicator from 'material-ui/RefreshIndicator'

import { isMobile } from '../utils'

class PreLoader extends Component {
  render() {
    let style = {
      container: {
        position: 'fixed',
        textAlign: 'center',
        height: 0,
        top: 30,
        right: 0,
        left: isMobile() ? 0 : 256,
        zIndex: 1110,
        visibility: this.props.open ? 'visible' : 'hidden',
      },
      refresh: {
        display: 'inline-block',
        position: 'relative',
      },
    }

    return (
      <div style={style.container}>
        <RefreshIndicator
          size={40}
          left={10}
          top={0}
          status="loading"
          style={style.refresh}
        />
      </div>
    )
  }
}

PreLoader.propTypes = {
  open: PropTypes.bool.isRequired
}

PreLoader.defaultProps = {
  open: false,
};

export default PreLoader
