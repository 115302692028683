import api from '../api'
import querystring from 'querystring'

/*
 *  action constants
 */

export const FIND_INFLUENCER  = 'FIND_INFLUENCER'
export const GET_INFLUENCER_PROFILE  = 'GET_INFLUENCER_PROFILE'
export const GET_INFLUENCER_POST  = 'GET_INFLUENCER_POST'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findInfluencer = filter => (dispatch) => {
  const f = hideZeroReachInfluencers(filter)

  let params = '?meta&score&charged'
  params += '&filter.suspended=false'
  params += '&audience&payment'
  params += '&facebook&twitter&linkedin&'
  // params += '&slice=0,100&'
  params += f || ''

  dispatch({
    type: FIND_INFLUENCER,
    payload: api.get('/influencer_v2' + params),
  })
}

// inject filter follower_count to start at 1
const hideZeroReachInfluencers = params => {
  params  = params.replace('?', '')

  let p = querystring.parse(params)
  const key = 'deep.between.follower_count'
  if (p.hasOwnProperty(key)) {
    p[key] = p[key].replace('min-', '1-')
  } else {
    p[key] = '1-max'
  }

  return decodeURIComponent(querystring.stringify(p))
}

export const getInfluencerProfile = (id) => (dispatch) => {
  if (id === undefined || id === '') {
    return
  }

  dispatch({
    type: GET_INFLUENCER_PROFILE,
    payload: api.get('/influencer/' + id + '/profile'),
  })
}

export const getInfluencerPost = (id) => (dispatch) => {
  if (id === undefined || id === '') {
    return
  }

  dispatch({
    type: GET_INFLUENCER_POST,
    payload: api.get('/influencer/' + id + '/post'),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
