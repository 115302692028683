import * as audienceAction from '../actions/audience'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // create audience
    case `${audienceAction.CREATE_AUDIENCE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${audienceAction.CREATE_AUDIENCE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${audienceAction.CREATE_AUDIENCE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get audience
    case `${audienceAction.GET_AUDIENCE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${audienceAction.GET_AUDIENCE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${audienceAction.GET_AUDIENCE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // update audience
    case `${audienceAction.UPDATE_AUDIENCE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${audienceAction.UPDATE_AUDIENCE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: true,
          message: 'Changes saved'
        },
      })
    case `${audienceAction.UPDATE_AUDIENCE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // audience
    case audienceAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
