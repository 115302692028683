import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { getSession } from '../utils/storage'

import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import { GridList, GridTile } from 'material-ui/GridList'

const styles = {
  block: {
    maxWidth: 250,
  },
  checkbox: {
    marginBottom: 16,
  },
}

class CategoryDialog extends Component {
  state = {
    open: false,
    disableSelect: false,
    disableSubmit: true,

    list: [],
    selected: [],
  }

  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.isChecked = this.isChecked.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
  }

  componentWillMount() {
    let list = getSession('categories')
    let selected = this.props.value

    // evaluate if checkbox are disabled
    let disableSelect = false
    if (selected.length > 2) {
      disableSelect = true
    }

    if (list !== null) {
      this.setState({
        list,
        selected,
        disableSelect,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    // evaluate if checkbox are disabled
    let disableSelect = false
    if (nextProps.value.length > 2) {
      disableSelect = true
    }

    this.setState({
      disableSelect,
      open: nextProps.open,
      selected: nextProps.value,
    })
  }

  handleClose() {
    this.setState({
      open: false,
    })

    this.props.onRequestClose()
  }

  toggleSelect(value) {
    let { selected, disableSelect, disableSubmit } = this.state

    let index = selected.indexOf(value)
    if (index !== -1) {
      // remove if exists
      selected.splice(index, 1)
    } else {
      // add
      selected.push(value)
    }

    // limit selection
    disableSelect = false
    if (selected.length > 2) {
      disableSelect = true
    }

    // minimum selection
    disableSubmit = true
    if (selected.length > 0) {
      disableSubmit = false
    }

    this.setState({
      selected,
      disableSelect,
      disableSubmit,
    })
  }

  isChecked(value) {
    return this.state.selected.indexOf(value) !== -1
  }

  isDisabled(value) {
    if (this.isChecked(value)) {
      return false
    }

    return this.state.disableSelect
  }

  handleSubmit() {
    this.props.onSubmit(this.state.selected)
    this.handleClose()
  }

  render() {
    let disableClose = this.state.selected.length === 0

    const actions = [
      <FlatButton
        label="Close"
        disabled={disableClose}
        onClick={this.handleClose}
      />,
      <RaisedButton
        label="Submit"
        primary={true}
        disabled={this.state.disableSubmit}
        onClick={this.handleSubmit}
      />,
    ]

    return (
      <Dialog
        title="Select up to 3 Categories"
        actions={actions}
        onRequestClose={this.handleClose}
        autoScrollBodyContent={true}
        modal={this.state.disableSubmit}
        open={this.state.open}>
        <br />
        <GridList cols={3} cellHeight={50}>
          {this.state.list.map((item, index) => (
            <GridTile key={index}>
                <Checkbox label={item}
                  checked={this.isChecked(item)}
                  disabled={this.isDisabled(item)}
                  style={styles.checkbox}
                  onClick={this.toggleSelect.bind(this, item)} />
            </GridTile>
          ))}
        </GridList>
        <br />
      </Dialog>
    )
  }
}

CategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
}

CategoryDialog.defaultProps = {
  open: false,
};

export default CategoryDialog
