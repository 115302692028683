import * as linkedinAction from '../actions/linkedin'
import { setAuth } from '../utils'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // login
    case `${linkedinAction.LOGIN_LINKEDIN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${linkedinAction.LOGIN_LINKEDIN}_FULFILLED`:
      // saving credentials
      setAuth(action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${linkedinAction.LOGIN_LINKEDIN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // connect
    case `${linkedinAction.CONNECT_LINKEDIN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${linkedinAction.CONNECT_LINKEDIN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${linkedinAction.CONNECT_LINKEDIN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get linkedin
    case `${linkedinAction.GET_LINKEDIN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${linkedinAction.GET_LINKEDIN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${linkedinAction.GET_LINKEDIN}_REJECTED`:
      let notify = {
        open: true,
        message: action.payload.response.data.msg
      }

      // do not throw this error because its just get
      if (action.payload.response.data.name === 'LINKEDIN_RESOURCE_DETAIL_ERR') {
        notify.open = false
      }

      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify,
      })

    // remove
    case `${linkedinAction.REMOVE_LINKEDIN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${linkedinAction.REMOVE_LINKEDIN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: {},
        notify: {
          open: true,
          message: 'Linkedin successfully removed'
        },
      })
    case `${linkedinAction.REMOVE_LINKEDIN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case linkedinAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
