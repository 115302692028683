import React, { Component } from 'react'

import RaisedButton from 'material-ui/RaisedButton'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import Chip from 'material-ui/Chip'
import MenuItem from 'material-ui/MenuItem'
import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar'
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right'
import FilterIcon from 'material-ui/svg-icons/content/filter-list'
import RefreshIcon from 'material-ui/svg-icons/action/cached'

import { isMobile } from '../utils'
import { getSession, setSession } from '../utils/storage'

import SearchDialog from '../components/SearchDialog'

const styles = {
  chip: {
    margin: 4,
  },
  chipWrapper: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
  },
  menuOrigin: {
    horizontal: 'left',
    vertical: 'top'
  },
  toolbar: {
    position: 'fixed',
    zIndex: 1101,
    left: isMobile() ? 0 : 256,
    top: 50,
    right: 0,
    boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
  },
}

const toolbarMenu = [
  {
    title: 'Status  ',
    value: 'status',
    data: [
      {value: 'filter.status=draft', text: 'Draft'},
      {value: 'filter.status=pending', text: 'Pending'},
      {value: 'filter.status=published', text: 'Published'},
      {value: 'filter.status=completed', text: 'Completed'},
      {value: 'filter.status=terminated', text: 'Terminated'},
    ],
  },{
    title: 'Type  ',
    value: 'type',
    data: [
      {value: 'filter.type=PPC', text: 'Pay per Click'},
      {value: 'filter.type=PPP', text: 'Pay per Post'},
    ],
  },{
    title: 'Sort  ',
    value: 'sort',
    data: [
      {value: 'order=created_at,desc', text: 'Newest'},
      {value: 'order=created_at', text: 'Oldest'},
      {value: 'order=price,desc', text: 'Most Expensive'},
      {value: 'order=price', text: 'Least Expensive'},
      {value: 'deep.order=clicks,desc', text: 'Most Clicked'},
      {value: 'deep.order=clicks', text: 'Least Clicked'},
    ],
  },
]

const sessionKey = 'campaign_toolbar_filter'

class CampaignToolbar extends Component {
  state = {
    filters: [],

    nameDialogOpen: false,
  }

  componentWillMount() {
    let filters = getSession(sessionKey) || null

    // load filter session is null
    if (filters === null) {
      this.addFilter('sort', {
        type:'sort',
        text: 'Newest',
        value: 'order=created_at,desc'
      })
    } else {
      this.setState({ filters })
    }

    this.onChange()
  }

  onChange = () => {
    let { filters } = this.state

    // update session
    setSession(sessionKey, filters)

    let filter = ''
    filters.forEach((v, i) => {
      if (v.value === undefined) {
        return
      }

      filter += '&' + v.value
    })

    this.props.onChange(filter)
  }

  addFilter = (type, row) => {
    let filters = this.state.filters

    // check existing and remove
    filters.forEach((v, i) => {
      if (v.type === type) {
        filters.splice(i, 1)
      }
    })

    let filter = row
    filter.type = type
    filters.push(filter)

    this.setState({
      filters,
    })

    this.onChange()
  }

  removeFilter = (index, row) => {
    let filters = this.state.filters
    filters.splice(index, 1)

    this.setState({
      filters,
    })

    this.onChange()
  }

  toggleNameDialog = () => {
    this.setState({
      nameDialogOpen: !this.state.nameDialogOpen,
    })
  }

  addFilterName = (value) => {
    this.addFilter('name', {
      value: 'search=' + (value || ''),
      text: value,
    })
  }

  render() {
    let toolbarMenuItems = toolbarMenu.map((item, i) => (
      <MenuItem key={i}
        primaryText={item.title}
        rightIcon={<ArrowDropRight />}
        menuItems={item.data.map((row, j) => (
          <MenuItem key={j}
            value={row.value}
            primaryText={row.text}
            onClick={this.addFilter.bind(this, item.value, row)} />
        ))}
      />
    ))

    const filterMenu = (
      <IconMenu iconButtonElement={<IconButton><FilterIcon /></IconButton>}
        anchorOrigin={styles.menuOrigin}
        targetOrigin={styles.menuOrigin} >
        {[
            <MenuItem key={100} primaryText="Search Name"
                onClick={this.toggleNameDialog.bind(this)} />,
            ...toolbarMenuItems,
        ]}
      </IconMenu>
    )

    return (
      <div>
        <SearchDialog open={this.state.nameDialogOpen}
          onSubmit={this.addFilterName.bind(this)}
          onRequestClose={this.toggleNameDialog.bind(this)} />
        <Toolbar style={styles.toolbar}>
          <ToolbarGroup firstChild={true}>
            {filterMenu}
            <div style={styles.chipWrapper} >
              {this.state.filters.map((row, id) => (
                <Chip key={id}
                  style={styles.chip}
                  onRequestDelete={this.removeFilter.bind(this, id, row)}>{row.type}: {row.text}</Chip>
              ))}
            </div>
          </ToolbarGroup>
          <ToolbarGroup lastChild={true}>
            <ToolbarSeparator />
            <IconButton onClick={this.onChange.bind(this)}><RefreshIcon /></IconButton>
            {this.props.noCreate || false ? null : (
              <RaisedButton label="Create Campaign" primary={true} onClick={this.props.onCreate} />
            )}
          </ToolbarGroup>
        </Toolbar>
      </div>
    )
  }
}

export default CampaignToolbar
