import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'

import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import Dialog from 'material-ui/Dialog'
import { GridList, GridTile } from 'material-ui/GridList'
import IconButton from 'material-ui/IconButton'
import LinkIcon from 'material-ui/svg-icons/action/open-in-new'
import LinearProgress from 'material-ui/LinearProgress'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table'

import { createPost, createFbPostOnGroup, clearNotif } from '../actions/post'
import { connectFacebook, getFacebookGroups } from '../actions/facebook'
import { connectTwitter } from '../actions/twitter'
import { connectLinkedin  } from '../actions/linkedin'

import Notify from '../components/Notify'
import SocialButton from '../components/SocialButton'

// const styles = {
//   socialIcon: {
//     fontSize: 15,
//   },
// }

const mapStateToProps = (state, ownProps) => {
  return {
    post: state.post,
    facebook: state.facebook,
    twitter: state.twitter,
    linkedin: state.linkedin,
  }
}

class PostDialog extends Component {
  state = {
    disabled: [],
    connected: [],
    fbGroups: [],
    message: '',

    open: false,
    dialogOpen: false,
    fbDialogOpen: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  constructor(props) {
    super(props)

    this.checkDisabled = this.checkDisabled.bind(this)
    this.checkConnected = this.checkConnected.bind(this)
  }

  componentWillMount() {
      this.props.getFacebookGroups()
  }

  componentWillReceiveProps(nextProps) {
    let { post, facebook, twitter, linkedin } = nextProps

    // connected account
    let connected = []
    if (facebook.detail.id !== undefined) {
      connected.push('facebook')
    }

    if (twitter.detail.id !== undefined) {
      connected.push('twitter')
    }

    if (linkedin.detail.id !== undefined) {
      connected.push('linkedin')
    }

    let fbGroups = facebook.groups
    this.setState({
      connected,
      fbGroups,
      notifyOpen: post.notify.open,
      notifyMessage: post.notify.message,
    })
  }

  toggle(value) {
    // connection
    if (!this.checkConnected(value)) {
      this.toggleDialog()

      return
    }

    let { disabled } = this.state

    // disable
    if (this.checkDisabled(value)) {
      disabled.forEach((item, index) => {
        if (item === value) {
          disabled.splice(index, 1)
        }
      })

      this.setState({
        disabled,
      })

      return
    }

    // enable
    disabled.push(value)
    this.setState({
      disabled,
    })
  }

  post() {
    let { connected, message } = this.state

    connected.forEach((platform) => {
      // skip facebook because of different handling
      if (platform === 'facebook') {
        return
      }

      // skip if disabled
      if (this.checkDisabled(platform)) {
        return
      }

      this.props.createPost({
        campaign_id: this.props.campaignId,
        platform: platform,
        message: message,
      })
    })

    // if facebook, open new window to manage
    // which pages or groups to publish
    if (connected.indexOf('facebook') !== -1) {
      this.toggleFbDialog()
      return
    }

    // closes the dialog after click post button
    if (this.props.id !== undefined) {
      this.props.onPost(this.props.id)
      return
    }

    this.props.onClose()
  }

  checkDisabled(platform) {
    return this.state.disabled.indexOf(platform) > -1
  }

  checkConnected(platform) {
    return this.state.connected.indexOf(platform) > -1
  }

  checkEnabled(platform) {
    if (this.checkDisabled(platform)) {
      return false
    }

    return this.checkConnected(platform)
  }

  toggleDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen
    })
  }

  toggleFbDialog() {
    this.setState({
      fbDialogOpen: !this.state.fbDialogOpen
    })
  }

  postOnFbGroup(groupId) {
    const { message } = this.state
    const { campaignId } = this.props

    console.log({
      platform: 'facebook',
      campaign_id: campaignId,
      message: message,
    });

    this.props.createFbPostOnGroup(groupId, {
      platform: 'facebook',
      campaign_id: campaignId,
      message: message,
    })
  }

  onDoneFbDialog() {
    this.toggleFbDialog()
    // closes the dialog after click post button
    if (this.props.id !== undefined) {
      this.props.onPost(this.props.id)
      return
    }

    this.props.onClose()
  }

  onCloseFbDialog() {
    this.setState({
      open: false,
      fbDialogOpen: false,
    })
  }

  render() {
    let { post } = this.props
    const { fbDialogOpen, fbGroups } = this.state

    const actions = [
      <FlatButton
        disabled={post.loading}
        label="Close"
        onClick={this.props.onClose}
      />,
      <RaisedButton
        primary={true}
        label="Post"
        disabled={this.state.disabled.length === this.state.connected.length}
        onClick={this.post.bind(this)}
      />,
    ]

    const campaign = (
      <GridTile
        title={this.props.title || '-'}
        actionIcon={<IconButton href={this.props.url || null} target="_blank"><LinkIcon color="white" /></IconButton>}
        subtitle={this.props.description || '-'}>
        <img src={this.props.image || null} alt="" />
      </GridTile>
    )

    return (
      <div>
        <Dialog
          title="Post a Campaign"
          actions={actions}
          onRequestClose={this.props.onClose}
          autoScrollBodyContent={true}
          open={this.props.open}>

          <LinearProgress mode="indeterminate" style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            visibility: post.loading ? 'visible' : 'hidden',
          }} />

          <br />
          <GridList cols={1} cellHeight={250} >
            {campaign}
          </GridList>
          <br />
          <GridList cols={3} cellHeight={15} style={{textAlign: 'center'}} >
            <SocialButton name="facebook"
              connected={this.checkEnabled('facebook')}
              onClick={this.toggle.bind(this, 'facebook')} />
            <SocialButton name="twitter"
              connected={this.checkEnabled('twitter')}
              onClick={this.toggle.bind(this, 'twitter')} />
            <SocialButton name="linkedin"
              disabled={true}
              connected={this.checkEnabled('linkedin')}
              onClick={this.toggle.bind(this, 'linkedin')} />
          </GridList>
          <br />
          <TextField floatingLabelText="Post Comment" fullWidth={true}
            defaultValue={this.state.message}
            onChange={(e) => {this.setState({message: e.target.value })}} />
          {this.props.note === '' ? null : (
            <span><strong>Note</strong>: {this.props.note}</span>
          )}
        </Dialog>

        <Dialog
          title="Social Connect"
          actions={[
            <FlatButton
              label="Close"
              onClick={this.toggleDialog.bind(this)}
            />,
            <FlatButton
              primary={true}
              label="go to Setting"
              href="/#/influencer/account"
              onClick={this.toggleDialog.bind(this)}
            />
          ]}
          onRequestClose={this.toggleDialog.bind(this)}
          open={this.state.dialogOpen}>
          Please enable this on account setting
        </Dialog>

        <Dialog
          title="Select Facebook Group to publish the campaign"
          actions={[
            <FlatButton
              label="Done"
              onClick={this.onDoneFbDialog.bind(this)}
            />
          ]}
          autoScrollBodyContent={true}
          onRequestClose={this.onCloseFbDialog.bind(this)}
          open={fbDialogOpen}>

          <Table multiSelectable={true}>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>Group Name</TableHeaderColumn>
                {/*<TableHeaderColumn>Member Count</TableHeaderColumn>*/}
                <TableHeaderColumn style={{ textAlign: 'center' }}>Action</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {fbGroups.map(group =>
                  <TableRow key={group.id}>
                    <TableRowColumn>{group.name}</TableRowColumn>
                    {/*<TableRowColumn>{group.member_count}</TableRowColumn>*/}
                    <TableRowColumn style={{ textAlign: 'center' }}>
                        <RaisedButton primary={true} label={"Publish"}
                            onClick={() => {
                                this.postOnFbGroup(group.id)
                            }}/>
                    </TableRowColumn>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Dialog>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

PostDialog.propTypes = {
  open: PropTypes.bool.isRequired,
}

PostDialog.defaultProps = {
  open: false,
}

export default connect(mapStateToProps, {
  createPost,
  createFbPostOnGroup,
  clearNotif,
  connectFacebook,
  getFacebookGroups,
  connectTwitter,
  connectLinkedin,
})(PostDialog)
