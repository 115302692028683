import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LinearProgress from 'material-ui/LinearProgress'

class ProgressBar extends Component {
  render() {
    let open = this.props.open || (this.props.value > 1 && this.props.value < 99)
      ? 'visible' : 'hidden'
    let mode = this.props.value > 1 ? 'determinate' : 'indeterminate'
    let style = {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      visibility: open,
    }

    return (
      <div style={this.props.style}>
        <LinearProgress mode={mode} style={style}
          value={this.props.value} />
      </div>
    )
  }
}

ProgressBar.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.number,
  style: PropTypes.object,
}

ProgressBar.defaultProps = {
  open: false,
  style: {
    position: 'relative',
    height: 0,
  },
};

export default ProgressBar
