import React, { Component } from 'react'

import AppBar from 'material-ui/AppBar'
import MenuItem from 'material-ui/MenuItem'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'

import RefreshIcon from 'material-ui/svg-icons/action/cached'
import GuideIcon from 'material-ui/svg-icons/communication/import-contacts'
import HelpIcon from 'material-ui/svg-icons/action/help-outline'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import LogoutIcon from 'material-ui/svg-icons/action/power-settings-new'

import { clearAuth, isMobile } from '../utils'
import { watchChanges } from '../utils/storage'

import { lightColor } from './Theme'
import NotificationIndicator from '../containers/NotificationIndicator'

const styles = {
  appBar: {
    position: 'fixed',
    left: isMobile() ? 0 : 256,
    right: 0,
    width: 'inherit',
  },
  title: {
    fontWeight: 100,
    fontSize: 20,
    textTransform: 'capitalize',
  },
  iconMenuOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
}

class TitleBar extends Component {
  state = {
    title: '',
  }

  componentWillMount() {
    let self = this
    watchChanges('session', 'title', (title) => {
      self.setState({
        title,
      })
    })
  }

  refreshHandler() {
    window.location.reload()
  }

  logoutHandler() {
    clearAuth()
    this.refreshHandler()
  }

  render() {
    const barIcon = (
      <div>
        <NotificationIndicator onClick={this.props.onBellClick} />

        <IconMenu
          iconButtonElement={<IconButton><MoreVertIcon color={lightColor} /></IconButton>}
          targetOrigin={styles.iconMenuOrigin}
          anchorOrigin={styles.iconMenuOrigin} >
          <MenuItem primaryText="Refresh" onClick={this.refreshHandler}
            leftIcon={<RefreshIcon />} />
          <MenuItem primaryText="Guide" href="/#/advertiser/guide"
            leftIcon={<GuideIcon />} />
          <MenuItem primaryText="FAQs" href="/#/advertiser/faq"
            leftIcon={<HelpIcon />} />
          <MenuItem primaryText="Logout" onClick={this.logoutHandler.bind(this)}
            leftIcon={<LogoutIcon />} />
        </IconMenu>
      </div>
    )

    return (
      <AppBar
        title={this.state.title}
        onLeftIconButtonClick={this.props.onMenuClick}
        showMenuIconButton={isMobile()}
        titleStyle={styles.title}
        style={styles.appBar}
        iconElementRight={barIcon} />
    )
  }
}

export default TitleBar
