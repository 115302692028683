import React, { Component } from 'react';
import { connect } from 'react-redux'

import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'

import { getCharity, updateCharity, clearNotif } from '../actions/charity'

import Note from '../components/Note'
import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'

const mapStateToProps = (state, ownProps) => {
  return {
    charity: state.charity,
  }
}

class AccountCharity extends Component {
  state = {
    donation: 0,
    charityEmail: '',

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    this.props.getCharity()
  }

  componentWillReceiveProps(nextProps) {
    let { charity } = nextProps

    this.setState({
      donation: charity.detail.donation_percentage || 0,
      charityEmail: charity.detail.paypal_email || '',

      notifyOpen: charity.notify.open,
      notifyMessage: charity.notify.message,
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value
    this.setState(state)
  }

  onSave() {
    let { donation, charityEmail } = this.state

    this.props.updateCharity({
      donation_percentage: parseInt(donation, 10),
      paypal_email: charityEmail,
    })
  }

  render() {
    let { charity } = this.props

    return (
      <div>
        <Card className="card-override">
          <ProgressBar open={charity.loading} />
          <CardHeader subtitle="Charity Setup" />
          <CardText>
            <TextField floatingLabelFixed={true}
              floatingLabelText="Charity Paypal Email"
              style={{width: '49%'}}
              value={this.state.charityEmail || ''}
              onChange={this.onChange.bind(this, 'charityEmail')} />
            <TextField floatingLabelFixed={true}
              floatingLabelText="Donation Percentage"
              style={{width: '49%', float: 'right'}}
              type="number"
              value={this.state.donation || 0}
              onChange={this.onChange.bind(this, 'donation')} />
            <Note>Donation will automatically go to charity Paypal account and duducted on payout</Note>
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            <FlatButton label="Save" primary={true}
              onClick={this.onSave.bind(this)} />
          </CardActions>
        </Card>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getCharity,
  updateCharity,
  clearNotif,
})(AccountCharity)
