import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Dialog from 'material-ui/Dialog'

class ConfirmDialog extends Component {
  confirm() {
    this.props.onConfirm()
    this.props.onClose()
  }

  render() {
    const actions = [
      <FlatButton
        label={this.props.cancelLabel}
        onClick={this.props.onClose}
      />,
      <RaisedButton
        label={this.props.confirmLabel}
        primary={true}
        onClick={this.confirm.bind(this)}
      />,
    ]

    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        onRequestClose={this.props.onClose}
        open={this.props.open}>
        {this.props.body}
      </Dialog>
    )
  }
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  open: false,
  title: 'Confirmation',
  text: 'Do you want to continue?',
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
};

export default ConfirmDialog
