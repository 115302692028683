import React from 'react'

import numeral from 'numeral'
import FontIcon from 'material-ui/FontIcon'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import { GridList, GridTile } from 'material-ui/GridList'
import muiThemeable from 'material-ui/styles/muiThemeable'
import ProfileIcon from 'material-ui/svg-icons/action/face'
import CheckIcon from 'material-ui/svg-icons/navigation/check'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'

import { abbreviateNumber, getInitials, pastelColor } from '../utils'

import Score from '../components/Score'
import AvatarV2 from '../components/AvatarV2'

const styles = {
  wrapper: {
    margin: 20,
  },
  socialIcon: {
    fontSize: 15,
    marginLeft: 8,
    marginTop: 15,
  },
  audienceContainer: {
    fontSize: 15,
  },
}

function getThumbnail(src) {
  return src.replace('/upload/', '/thumbnail/') + '/40x40'
}

export default muiThemeable()((props) => (
  <div style={styles.wrapper}>
    <Card className="card-override" expanded={true}>
      <CardHeader title={props.name || '-'} 
        avatar={
          <AvatarV2
            style={{ cursor: 'pointer', backgroundColor:  pastelColor(props.id)}}
            onClick={() => { props.onView(props.id) }}
            alt={getInitials(props.name)}
            src={getThumbnail(props.image)} />
        }
        titleStyle={{
          textTransform: 'capitalize',
          fontSize: 16,
        }}
        subtitle={props.categories.length === undefined ? '-' : props.categories.join(' · ')}
        subtitleStyle={{
          fontWeight: 'normal',
        }} />
      <CardText>
        <GridList cols={3} cellHeight={55} >
          <GridTile>
            <Score title="Total Reach" value={abbreviateNumber(
              (props.facebook || 0) +
              (props.twitter || 0) +
              (props.linkedin || 0))} />
          </GridTile>
          <GridTile>
            <Score title="Posted Campaigns" value={props.posts || '-'} />
          </GridTile>
          <GridTile>
            <Score title="Audience Location for Local Ads" value={<div style={styles.audienceContainer}>
                {props.location || '-'}
            </div>} />
          </GridTile>
        </GridList>
      </CardText>
      <CardActions>
        <div style={{float: 'left'}}>
          {props.facebook ? (<span><FontIcon style={styles.socialIcon} className="fa fa-facebook colored"/> {abbreviateNumber(props.facebook)}</span>) : null}
          {props.twitter ? (<span><FontIcon style={styles.socialIcon} className="fa fa-twitter colored"/> {abbreviateNumber(props.twitter)}</span>) : null}
          {props.linkedin ? (<span><FontIcon style={styles.socialIcon} className="fa fa-linkedin colored"/> {abbreviateNumber(props.linkedin)}</span>) : null}
        </div>
        <div style={{textAlign: 'right'}}>
          <FlatButton primary={true} label="Profile" icon={<ProfileIcon />}
            onClick={() => {props.onView(props.id)}} />

          {props.selected ? (
            <RaisedButton
              style={{
                display: props.type === '' ? 'none' : 'inline-block',
              }}
              onClick={() => {props.onDeselect(props.id)}}
              icon={<CheckIcon />}
              label="Selected" />
          ) : (
            <RaisedButton primary={true}
              onClick={() => {props.onSelect(props.id, props.name, props.fee, props.image)}}
              style={{
                display: props.type === '' ? 'none' : 'inline-block',
              }}
              label={'Select' + (props.type === 'PPC' ? '' :
                ' for ' + numeral(props.fee).format('0,0.00'))} />
          )}
        </div>
      </CardActions>
    </Card>
  </div>
))
