import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'

import { requireAuth, checkAuth } from '../utils'

// advertiser private container
import AdvertiserRoot from '../advertiser/Root'
import AdvertiserOverview from '../advertiser/Overview'
import AdvertiserCampaign from '../advertiser/CampaignListOld'
import AdvertiserCampaignCreate from '../advertiser/CampaignCreate'
import AdvertiserCampaignView from '../advertiser/CampaignView'
import AdvertiserCampaignStat from '../advertiser/CampaignStat'
import AdvertiserInfluencer from '../advertiser/Influencer'
import AdvertiserAccount from '../advertiser/Account'
import AdvertiserTransaction from '../advertiser/Transaction'
import AdvertiserFaq from '../advertiser/Faq'
import AdvertiserGuide from '../advertiser/Guide'

// advertiser public container
import AdvertiserAuth from '../advertiser/Auth'
import AdvertiserLogin from '../advertiser/Login'
import AdvertiserRegister from '../advertiser/Register'
import AdvertiserForget from '../advertiser/Forget'
import AdvertiserPassword from '../advertiser/Password'

const appRoot = '/advertiser'

export default (
  <Route path={appRoot}>
    <Route component={AdvertiserAuth}>
      <Route path="password" component={AdvertiserPassword}/>
    </Route>

    <Route component={AdvertiserRoot} onEnter={(nextState, replace) => {
        requireAuth(appRoot, nextState, replace)
      }}>
      <IndexRedirect to="overview"/>
      <Route path="overview" component={AdvertiserOverview}/>
      <Route path="campaign">
        <IndexRoute component={AdvertiserCampaign}/>
        <Route path="create" component={AdvertiserCampaignCreate}/>
        <Route path=":id/view" component={AdvertiserCampaignView}/>
        <Route path=":id/stats" component={AdvertiserCampaignStat}/>
      </Route>
      <Route path="influencer" component={AdvertiserInfluencer}/>
      <Route path="account" component={AdvertiserAccount}/>
      <Route path="transaction" component={AdvertiserTransaction}/>
      <Route path="transaction/:id/view" component={AdvertiserTransaction}/>
      <Route path="faq" component={AdvertiserFaq}/>
      <Route path="guide" component={AdvertiserGuide}/>
    </Route>

    <Route component={AdvertiserAuth} onEnter={(nextState, replace) => {
        checkAuth(appRoot, nextState, replace)
      }}>
      <Route path="login" component={AdvertiserLogin}/>
      <Route path="register" component={AdvertiserRegister}/>
      <Route path="forget" component={AdvertiserForget}/>
    </Route>
  </Route>
)
