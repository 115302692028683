import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import numeral from 'numeral'
import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'
import Avatar from 'material-ui/Avatar'
import Divider from 'material-ui/Divider'
import { GridList, GridTile } from 'material-ui/GridList'
import Chip from 'material-ui/Chip'
import Subheader from 'material-ui/Subheader'
import IconButton from 'material-ui/IconButton'
import LinkIcon from 'material-ui/svg-icons/action/open-in-new'
import LinearProgress from 'material-ui/LinearProgress'

import { abbreviateNumber, getInitials, pastelColor } from '../utils'
import { getInfluencerProfile, getInfluencerPost, clearNotif } from '../actions/influencer'

import Score from '../components/Score'
import { primaryColor, lightColor } from './Theme'
import ViewerEngagement from '../components/ViewerEngagement'
import AvatarV2 from '../components/AvatarV2'

const styles = {
  chip: {
    marginRight: 4,
    marginLeft: 4,
    backgroundColor: primaryColor,
  },
  chipWrapper: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialIcon: {
    fontSize: 15,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    influencer: state.influencer,
  }
}

const defaultDetailState = {
  score: {},
  audience: {},
  payment: {},
  facebook: {},
  twitter: {},
  linkedin: {},
}

const defaultState = {
    id: '',
    open: false,
    loading: false,

    detail: defaultDetailState,
    posts: [],
}

class InfluencerView extends Component {
  state = defaultState

  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    let { id, influencer } = nextProps

    this.setState({
      open: nextProps.open,
      detail: defaultDetailState,
      posts: [],
    })

    if (id === undefined || id === '') {
      return
    }

    // get profile
    if (!this.state.loading) {
      this.props.getInfluencerProfile(id)
      this.props.getInfluencerPost(id)
      this.setState({ loading: true })
      return
    }

    // we have the profile
    if (influencer.detail.id !== undefined && influencer.posts !== undefined) {
      // emptify
      let dataKeys = ['audience', 'payment', 'facebook', 'twitter', 'linkedin', 'score']
      dataKeys.forEach((key) => {
        if (influencer.detail[key] === undefined) {
          influencer.detail[key] = {}
        }
      })

      this.setState({
        id,
        loading: false,
        open: nextProps.open,
        detail: influencer.detail,
        posts: influencer.posts,
      })
    }
  }

  componentWillUnmount() {
    console.log('un mounting')
  }

  handleClose() {
    this.setState(defaultState)
    this.props.onClose()
  }

  render() {
    const actions = [
      <FlatButton
        label="Close"
        primary={true}
        onClick={this.handleClose}
      />,
    ]

    let { influencer } = this.props
    let { detail, posts, loading } = this.state

    return (
      <Dialog
        title={detail.name || '-'}
        actions={actions}
        onRequestClose={this.handleClose}
        open={this.state.open}
        autoScrollBodyContent={true} >
        <br />

        <div>
          <LinearProgress mode="indeterminate" style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            visibility: influencer.loading ? 'visible' : 'hidden',
          }} />

          <GridList cols={4} cellHeight={150} >
            <GridTile>
              {loading
                ? <Avatar size={150} />
                : <AvatarV2
                    style={{ backgroundColor:  pastelColor(detail.id)}}
                    alt={getInitials(detail.name || '-')}
                    src={detail.image}
                    size={150} />}
            </GridTile>
            <GridTile>
              <br />
              <Score title="Average clicks" value={detail.score.average_clicks || '-'} />
              <br />
              <Divider />
              <br />
              <Score title={
                <span>Facebook&nbsp;
                  {/*{detail.facebook.url ? <a href={detail.facebook.url}*/}
                  {/*  target="_blank" rel="noopener noreferrer">friends</a> : 'friends'}*/}
                  {detail.facebook.url ? <span>friends</span> : 'friends'}

                  {detail.facebook.page_id ? ' + ' : null}

                  {detail.facebook.page_id
                    ? <a href={'https://www.facebook.com/' + detail.facebook.page_id} target="_blank" rel="noopener noreferrer">fans</a>
                    : null}
                </span>}
                value={abbreviateNumber(detail.facebook.follower_count)} />
            </GridTile>
            <GridTile>
              <br />
              <Score title="Average reach" value={detail.score.average_reach || '-'} />
              <br />
              <Divider />
              <br />
              <Score title={
                <span>Twitter {detail.twitter.url ?
                  <a href={detail.twitter.url}
                    target="_blank" rel="noopener noreferrer">followers</a> : 'followers'}
                </span>}
                value={abbreviateNumber(detail.twitter.follower_count)} />
            </GridTile>
            <GridTile>
              <br />
              <Score title="Post fee"
                value={numeral(detail.payment.post_fee).format('0,0.00') || '-'} />
              <br />
              <Divider />
              <div style={{
                position: 'absolute',
                fontSize: 10,
                textAlign: 'center',
                width: '100%',
              }}>currently unavailable for posts</div>
              <br />
              <Score disabled title={
                <span>Linkedin {detail.linkedin.url ?
                  <a href={detail.linkedin.url}
                     style={{ color: 'inherit' }}
                     target="_blank"
                     rel="noopener noreferrer">connections</a> : 'connections'}
                </span>}
                value={abbreviateNumber(detail.linkedin.follower_count)} />
            </GridTile>
          </GridList>
        </div>
        <br />
        <Divider />
        <div>
          <br />
          <p style={{textAlign: 'center'}}>{detail.payment.post_note || '-'}</p>
          <br />
          <Divider />
          <br />
          <GridList cols={1} cellHeight={80} >
            <GridTile>
              <Subheader style={{textAlign: 'center'}} >Audience Location for Local Ads</Subheader>
              <div style={{textAlign: 'center'}}>
                <strong>{detail.audience.location || '-'}</strong>
              </div>
            </GridTile>
            <GridTile>
              <Subheader style={{textAlign: 'center'}} >Category</Subheader>
              <div style={styles.chipWrapper}>
                {detail.audience.categories === undefined ? null : detail.audience.categories.map((item, index) => (
                  <Chip style={styles.chip} labelColor={lightColor} key={index}>{item}</Chip>
                )) }
              </div>
            </GridTile>
          </GridList>
          <br />
          <br />
          <Divider />
          <br />
          <Subheader style={{textAlign: 'center'}} >Postings</Subheader>
          <GridList cols={4} cellHeight={50} >
            <GridTile>
              <Score title="Campaign posted" value={detail.score.posted_campaigns || '-'} />
            </GridTile>
            <GridTile>
              <Score title="Likes per post" value={detail.score.average_likes || '-'} />
            </GridTile>
            <GridTile>
              <Score title="Comments per post" value={detail.score.average_comments || '-'} />
            </GridTile>
            <GridTile>
              <Score title="Shares per post" value={detail.score.average_shares || '-'} />
            </GridTile>
          </GridList>
          <br />
          <GridList cols={2} cellHeight={180}>
            {posts.map((item, index) => (
              <GridTile
                key={index}
                title={item.title}
                actionIcon={
                  <IconButton href={item.url} target="_blank" rel="noopener noreferrer">
                    <LinkIcon color="white" />
                  </IconButton>}
                subtitle={
                  <ViewerEngagement clicks={item.score.clicks} facebook={[item.score.likes, item.score.comments, item.score.shares]} />
                }>
                <img src={item.image} alt={item.image} />
              </GridTile>
            ))}
          </GridList>
        </div>
      </Dialog>
    )
  }
}

InfluencerView.propTypes = {
  open: PropTypes.bool.isRequired,
}

InfluencerView.defaultProps = {
  open: false,
};

export default connect(mapStateToProps, {
  getInfluencerProfile,
  getInfluencerPost,
  clearNotif,
})(InfluencerView)
