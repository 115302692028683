import api from '../api'

/*
 *  action constants
 */

export const CREATE_PASSWORD_RESET  = 'CREATE_PASSWORD_RESET'
export const GET_PASSWORD_RESET = 'GET_PASSWORD_RESET'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createPasswordReset = (data) => (dispatch) => {
  dispatch({
    type: CREATE_PASSWORD_RESET,
    payload: api.post('/password/reset', data),
  })
}

export const getPasswordReset = (token) => (dispatch) => {
  dispatch({
    type: GET_PASSWORD_RESET,
    payload: api.get('/password/reset/' + token),
  })
}

export const updatePasswordReset = (token, data) => (dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_RESET,
    payload: api.patch('/password/reset/' + token, data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
