import * as invitationAction from '../actions/invitation'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  list: [],
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find invitation
    case `${invitationAction.FIND_INVITATION}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${invitationAction.FIND_INVITATION}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data.data,
        meta: {
          result_count: action.payload.data.result_count,
          total_count: action.payload.data.total_count,
          unseen_count: action.payload.data.unseen_count,
        },
      })
    case `${invitationAction.FIND_INVITATION}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get invitation
    case `${invitationAction.GET_INVITATION}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${invitationAction.GET_INVITATION}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${invitationAction.GET_INVITATION}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // update invitation
    case `${invitationAction.UPDATE_INVITATION}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${invitationAction.UPDATE_INVITATION}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${invitationAction.UPDATE_INVITATION}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // invitation
    case invitationAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
