import * as appAction from '../actions/app'

let defautState = {
  error: false,
  loading: false,
  title: '',
  notify: {
    open: false,
    type: '',
    text: '',
  },
};

export default (state = defautState, action) => {
  switch (action.type) {
    case appAction.SET_TITLE:
      console.log("WWW");

      return Object.assign({}, state, {
        title: action.payload,
      })

    case appAction.FLASH_MESSAGE:
      return Object.assign({}, state, {
        notify: {
          text: action.payload.text,
          type: action.payload.type,
        }
      })
    // no default
  }

  return state
}
