import * as campaignAction from '../actions/campaign'
import { formatter } from '../utils/campaign'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  list: [],
  detail: {},
  payment: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find campaign
    case `${campaignAction.FIND_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        detail: {},
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignAction.FIND_CAMPAIGN}_FULFILLED`:
      for (var i = 0; i < action.payload.data.data.length; i++) {
        let r = action.payload.data.data[i]
        formatter(r)
      }

      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data.data,
        meta: {
          result_count: action.payload.data.result_count,
          total_count: action.payload.data.total_count,
        },
      })
    case `${campaignAction.FIND_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get campaign
    case `${campaignAction.GET_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${campaignAction.GET_CAMPAIGN}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: formatter(action.payload.data),
      })
    case `${campaignAction.GET_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // create campaign
    case `${campaignAction.CREATE_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        payment: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignAction.CREATE_CAMPAIGN}_FULFILLED`:
      if (action.payload.data.approval_url !== ''
      && action.payload.data.approval_url !== undefined) {
        return Object.assign({}, state, {
          loading: false,
          payment: action.payload.data,
        })
      }

      return Object.assign({}, state, {
        loading: false,
        detail: formatter(action.payload.data),
      })
    case `${campaignAction.CREATE_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // update campaign
    case `${campaignAction.UPDATE_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        payment: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignAction.UPDATE_CAMPAIGN}_FULFILLED`:
      if (action.payload.data.approval_url !== ''
      && action.payload.data.approval_url !== undefined) {
        return Object.assign({}, state, {
          loading: false,
          payment: action.payload.data,
        })
      }

      return Object.assign({}, state, {
        loading: false,
        detail: formatter(action.payload.data),
        notify: {
          open: true,
          message: 'Changes saved'
        },
      })
    case `${campaignAction.UPDATE_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // delete campaign
    case `${campaignAction.DELETE_CAMPAIGN}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignAction.DELETE_CAMPAIGN}_FULFILLED`:
      // update list
      let { list } = state
      list.forEach((item, index) => {
        if (item.id === action.payload.data.id) {
          list.splice(index, 1)
        }
      })

      return Object.assign({}, state, {
        list,
        loading: false,
        detail: {},
      })
    case `${campaignAction.DELETE_CAMPAIGN}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // campaign
    case campaignAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
