import React, { Component } from 'react'
import { connect } from 'react-redux'

import { formatAmount, setTitle } from '../utils'
import { findInfluencerCampaign } from '../actions/influencer_campaign'

import PostDialog from './PostDialog'
import EmptyData from '../components/EmptyData'
import PreLoader from '../components/PreLoader'
import InfluencerCampaignItem from '../components/InfluencerCampaignItem'
import CampaignToolbar from '../components/CampaignToolbar'

const mapStateToProps = (state, ownProps) => {
  return {
    campaign: state.influencer_campaign,
  }
}

const title = 'Campaign'

class Campaign extends Component {
  state = {
    // data
    index: null,
    list: [],
    detail: {},

    // notify
    notifyOpen: false,
    notifyMessage: '',

    // dialogs
    dialogOpen: false,
  }

  componentWillMount() {
    setTitle(title)

    this.props.findInfluencerCampaign('&order=created_at,desc')
  }

  componentWillReceiveProps(nextProps) {
    let { campaign } = nextProps

    this.setState({
      list: campaign.list || [],

      notifyOpen: campaign.notify.open,
      notifyMessage: campaign.notify.message,
    })
  }

  filterChanged(value) {
    this.props.findInfluencerCampaign(value)
  }

  togglePostDialog(index) {
    let { dialogOpen, list } = this.state
    let detail = list[index]
    dialogOpen = !dialogOpen
    if (!dialogOpen) {
      detail = {}
    }

    this.setState({
      dialogOpen,
      index,
      detail,
    })
  }

  onPost(index) {
    this.togglePostDialog(index)
  }

  render() {
    const { campaign } = this.props
    const { detail, dialogOpen } = this.state

    return (
      <section style={{marginTop: 60}}>
        <PreLoader open={campaign.loading} />

        <PostDialog open={dialogOpen}
          campaignId={detail.id}
          title={detail.title}
          description={detail.description}
          image={detail.image}
          url={detail.url}
          note={detail.note}
          onClose={this.togglePostDialog.bind(this)}
          onPost={this.onPost} />

        <CampaignToolbar noCreate={true}
          onChange={this.filterChanged.bind(this)} />

        {this.state.list.length !== 0 ? null : (
          <EmptyData />
        )}

        {this.state.list.map((row, index) => (
          <InfluencerCampaignItem key={index}
            id={row.id}
            title={row.title}
            image={row.image}
            status={row.status}
            type={row.type}
            updated={row.updated_at}

            uniqueClicks={row.score.unique_clicks}
            price={formatAmount(row.price)}
            rate={formatAmount(row.click_rate)}

            clicks={row.score.clicks}
            avaialbleClicks={row.score.available_clicks}
            likes={row.score.likes}
            comments={row.score.comments}
            shares={row.score.shares}
            onPost={this.onPost.bind(this, index)} />
        ))}
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  findInfluencerCampaign,
})(Campaign)
