import React, { Component } from 'react'

import { Card, CardText } from 'material-ui/Card'

import { setTitle } from '../utils'

const styles = {
  card: {
    marginBottom: 20,
  },
}

const title = 'Frequently Asked Question'

class Faq extends Component {
  state = {
  }

  componentWillMount() {
    setTitle(title)
  }

  render() {
    return (
      <section className="content-wrapper">
        <Card className="card-override" style={styles.card}>
          <CardText>
            <h3>As An Advertiser, How Do I Find The Right influencers To Post My Ads?</h3>
            <p>MashDrop has a broad network of influencers and their audiences represent virtually all consumer-market categories. To find the right influencer for your brand, simply set the category filters for the audience you seek and a list of matching influencers and their social networks will be revealed for you to explore. You can select as few or many influencers as you wish. It’s important to note that by aggregating category-matching influencers together into a single media buy far-reaching audiences with high levels of viewer engagement can be achieved.</p>
            <br />

            <h3>What is The Difference Between 'Pay-Per-Click' And ‘Pay-Per-Post’ Pricing?</h3>
            <p>Though the PPC and PPP display ads themselves can be identical, how they're paid for is very different. When purchasing PPC ads, advertisers buy a block of clicks which can be distributed among the influencers they’ve selected. And with each URL-linked ‘click’, their account credit balance will be debited accordingly. With PPC ads, the advertiser only pays for clicks to destination URLs, all other click actions, including, likes, shares, comments, retweet, etc., are free and unlimited. For pay-per-post (PPP) ads, on the other hand, the advertiser makes a flat, one-time payment to each influencer they chosen. With PPP, all viewer click actions are free and unlimited.</p>
            <br />

            <h3>What Can I Expect The Influencers I Select Will Do For Me?</h3>
            <p>The influencers you select describe what they will do in their profile. At minimum, they will post your ads with embedded links to a target website or URL on their Facebook pages, Twitter feeds and LinkedIn profiles. Keep in mind, MashDrop is a 'native-advertising' platform and the sponsor, not the influencer, provides the campaign artwork that will be shared. The chosen influencer(s) will then select the ad campaign that best matches their audience’s interests free to add comments, endorsements, testimonials, hashtags, etc., to their post.</p>
            <br />

            <h3>What Should I Do If An Influencer Is Not Responding</h3>
            <p>We ask our influencers to respond to opportunity notifications in a timely manner. In the event an Influencer does not respond or accept a campaign invitation, fees paid and clicks bought will be refunded 48 hours after purchase for PPP and single-influencer PPC ad campaigns. For multiple-influencer PPC campaigns, click credits will remain in force until the buy is fully depleted. Advertisers can track click actions on their account dashboard.</p>
            <br />

            <h3>Why Are There 2-Forms Of Payment - Pay-Per-Click and Pay-Per-Post?</h3>
            <p>Choice is good. With the Pay-Per-Click (PPC), advertisers determine what they’re willing to pay and can adjust their bids in order to attract influencers. Conversely, Pay-Per-Post (PPP) pricing enables offers to be made by influencers who can adjust their rates as competition and market conditions change. Either way, the final pricing option is one that both parties mutually agree upon.</p>
            <br />

            <h3>Does MashDrop Impose Restrictions On The Types Of Products Influencers Will Posts?</h3>
            <p>MashDrop strives to be an open platform. We do not currently impose any restrictions on the kinds of products or services advertisers may wish to promote, however, we cannot guarantee that all advertisements will find influencers willing to carry the campaigns offered. The social-influencer marketplace itself will determine what, if any, restrictions there will be.</p>
            <br />

            <h3>Can I Embed Links To A Video In My Campaign Ad?</h3>
            <p>Yes. You can add your video's URL link to the image that will be posted by the influencer.</p>
            <br />
          </CardText>
        </Card>
      </section>
    )
  }
}

export default Faq
