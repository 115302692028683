import React from 'react'

import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import FontIcon from 'material-ui/FontIcon'
import { Card, CardActions, CardText } from 'material-ui/Card'

export default () => (
  <Card className="card-override" expanded={true}>
    <CardText style={{textAlign: 'center'}}>
      <FontIcon className="fa fa-line-chart"
        style={{fontSize: 100}}
        color="#03A9F4" />
      <h1 style={{fontSize: 21}} >Welcome to MashDrop!</h1>
      <p>You can start creating your first campaign.</p>
    </CardText>
    <CardActions style={{textAlign: 'center'}}>
      <FlatButton label="read faqs" href="/#/advertiser/faq" />
      <RaisedButton primary={true} label="create campaign" href="/#/advertiser/influencer?createMode=true" />
    </CardActions>
  </Card>
)
