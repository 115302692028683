import * as campaignStatAction from '../actions/campaign_stat'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  title: '',
  score: {},
  click: {},
  source: {},
  platform: {},
  influencer: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // get overview
    case `${campaignStatAction.GET_CAMPAIGN_STAT_OVERVIEW}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        title: '',
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_OVERVIEW}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        title: action.payload.data.title || '',
        // score: action.payload.data.score || {},
        // click: action.payload.data.click || {},
        // source: action.payload.data.source || {},
        // platform: action.payload.data.platform || {},
        // influencer: action.payload.data.influencer || {},
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_OVERVIEW}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview score
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SCORE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        score: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SCORE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        score: action.payload.data,
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SCORE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview click
    case `${campaignStatAction.GET_CAMPAIGN_STAT_CLICK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        click: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_CLICK}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        click: action.payload.data,
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_CLICK}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview source
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SOURCE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        source: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SOURCE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        source: action.payload.data,
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_SOURCE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview platform
    case `${campaignStatAction.GET_CAMPAIGN_STAT_PLATFORM}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        platform: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_PLATFORM}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        platform: action.payload.data,
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_PLATFORM}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get overview influencer
    case `${campaignStatAction.GET_CAMPAIGN_STAT_INFLUENCER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        influencer: {},
        notify: {
          open: false,
          message: ''
        },
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_INFLUENCER}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        influencer: action.payload.data,
      })
    case `${campaignStatAction.GET_CAMPAIGN_STAT_INFLUENCER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case campaignStatAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
