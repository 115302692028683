import React, { Component } from 'react'

import RaisedButton from 'material-ui/RaisedButton'
import IconMenu from 'material-ui/IconMenu'
import IconButton from 'material-ui/IconButton'
import Chip from 'material-ui/Chip'
import MenuItem from 'material-ui/MenuItem'
import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar'
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right'
import FilterIcon from 'material-ui/svg-icons/content/filter-list'
import RefreshIcon from 'material-ui/svg-icons/action/cached'

import { getCategories, isMobile } from '../utils'
import { getSession, setSession } from '../utils/storage'

import SearchDialog from '../components/SearchDialog'
import SearchLocationDialog from '../components/SearchLocationDialog'

const styles = {
  chip: {
    margin: 4,
  },
  chipWrapper: {
    display: 'flex',
    marginTop: 8,
    marginBottom: 8,
  },
  menuOrigin: {
    horizontal: 'left',
    vertical: 'top'
  },
  toolbar: {
    position: 'fixed',
    zIndex: 1101,
    left: isMobile() ? 0 : 256,
    top: 50,
    right: 0,
    boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
  },
}

const mostReachFilter = {
  type: 'sort',
  text: 'Most Reach',
  value: 'deep.order=follower_count,desc',
}

const toolbarMenu = [
  {
    title: 'Post Fee',
    value: 'post fee',
    data: [
      {value: 'deep.between.post_fee=min-1000', text: 'less than $10'},
      {value: 'deep.between.post_fee=1100-10000', text: '$11 - $100'},
      {value: 'deep.between.post_fee=101-500', text: '$101 - $500'},
      {value: 'deep.between.post_fee=501-1000', text: '$501 - $1,000'},
      {value: 'deep.between.post_fee=1000-max', text: 'more than $1,000'},
    ]
  },{
    title: 'Reach',
    value: 'followers',
    data: [
      {value: 'deep.between.follower_count=min-1000', text: 'less than 1K'},
      {value: 'deep.between.follower_count=1001-10000', text: '1K - 10K'},
      {value: 'deep.between.follower_count=10001-100000', text: '10K - 100K'},
      {value: 'deep.between.follower_count=100001-1000000', text: '100K - 1M'},
      {value: 'deep.between.follower_count=1000001-max', text: 'more than 1M'},
    ]
  },{
    title: 'Sort',
    value: 'sort',
    data: [
      // {...hotFilter},
      {value: 'deep.order=follower_count,desc', text: 'Most Reach'},
      {value: 'deep.order=follower_count', text: 'Least Reach'},
      {value: 'order=created_at,desc', text: 'Newest'},
      {value: 'order=created_at', text: 'Oldest'},
      {value: 'deep.order=post_count,desc', text: 'Most Published Campaign'},
      {value: 'deep.order=post_count', text: 'Least Published Campaign'},
      {value: 'deep.order=post_fee,desc', text: 'Most Expensive'},
      {value: 'deep.order=post_fee', text: 'Least Expensive'},
    ]
  },
]

const sessionKey = 'influencer_toolbar_filter'

class InfluencerToolbar extends Component {
  state = {
    filters: [mostReachFilter],

    nameDialogOpen: false,
    locationDialogOpen: false,
  }

  componentWillMount() {
    // load saved filters
    let filters = getSession(sessionKey)
    if (filters === null) {
      filters = [mostReachFilter]
    }

    // load filter session
    this.setState({ filters })

    this.searchInfluencer(filters)
  }

  onChange = () => {
    let { filters } = this.state
    // update session
    setSession(sessionKey, filters)

    this.searchInfluencer(filters)
  }

  searchInfluencer = filters => {
    this.props.onChange(this.buildFilter(filters))
  }

  buildFilter = f => f.filter(f => f.value !== undefined).map(f => f.value).join('&')

  addFilter = (type, row) => {
    let filters = this.state.filters

    // check existing and remove
    filters.forEach((v, i) => {
      if (v.type === type) {
        filters.splice(i, 1)
      }
    })

    let filter = row
    filter.type = type
    filters.push(filter)

    this.setState({ filters })
    this.onChange()
  }

  removeFilter = (index, row) => {
    let filters = this.state.filters
    filters.splice(index, 1)

    this.setState({ filters })
    this.onChange()
  }

  toggleNameDialog = () => {
    this.setState({
      nameDialogOpen: !this.state.nameDialogOpen,
    })
  }

  addFilterName = (value) => {
    this.addFilter('name', {
      value: 'search=' + (value || ''),
      text: value,
    })
  }

  toggleLocationDialog = () => {
    this.setState({
      locationDialogOpen: !this.state.locationDialogOpen,
    })
  }

  addFilterLocation = (value) => {
    this.addFilter('location', {
      value: 'deep.location=' + (value || ''),
      text: value,
    })
  }

  handleReload = () => {
    let { filters } = this.state
    this.props.onReload(this.buildFilter(filters))
  }

  render() {
    const filterMenu = (
      <IconMenu iconButtonElement={<IconButton><FilterIcon /></IconButton>}
        anchorOrigin={styles.menuOrigin}
        targetOrigin={styles.menuOrigin} >
        {[
            <MenuItem key="name" primaryText="Name"
              onClick={this.toggleNameDialog.bind(this)} />,
            <MenuItem key="location" primaryText="Location"
              onClick={this.toggleLocationDialog.bind(this)} />,
            <MenuItem key="category" primaryText="Category"
              rightIcon={<ArrowDropRight />}
              menuItems={getCategories().map((item, id) => (
                <MenuItem key={id} primaryText={item}
                  onClick={this.addFilter.bind(this, 'category', {
                    value: 'deep.category=' + item,
                    text: item,
                  })} />
            ))} />,
            ...toolbarMenu.map((item, key) => (
              <MenuItem key={key} primaryText={item.title}
                rightIcon={<ArrowDropRight />}
                menuItems={item.data.map((row, id) => (
                  <MenuItem key={id} value={row.value} primaryText={row.text}
                    onClick={this.addFilter.bind(this, item.value, row)} />
                ))} />
            ))
        ]}
      </IconMenu>
    )

    const { meta, loading } = this.props

    return (
      <div>
        <SearchDialog open={this.state.nameDialogOpen}
          onSubmit={this.addFilterName.bind(this)}
          onRequestClose={this.toggleNameDialog.bind(this)} />
        <SearchLocationDialog open={this.state.locationDialogOpen}
          onSubmit={this.addFilterLocation.bind(this)}
          onRequestClose={this.toggleLocationDialog.bind(this)} />

        <Toolbar style={styles.toolbar}>
          <ToolbarGroup firstChild={true}>
            {filterMenu}
            <div style={styles.chipWrapper} >
              {this.state.filters.map((row, id) => (
                <Chip key={id}
                  style={styles.chip}
                  onRequestDelete={this.removeFilter.bind(this, id, row)}>{row.type}: {row.text}</Chip>
              ))}
            </div>
          </ToolbarGroup>
          <ToolbarGroup lastChild={true}>
            <span style={{
              color: 'grey',
              fontStyle: 'italic',
              textAlign: 'center',
            }}>
              {loading ? 'loading...' : `${meta.result_count} search results`}
            </span>
            <ToolbarSeparator />
            <IconButton onClick={this.handleReload.bind(this)}><RefreshIcon /></IconButton>
            <RaisedButton primary={true}
              label={this.props.createMode ? 'Start over' : 'Create Campaign'}
              onClick={this.props.onCreate} />
          </ToolbarGroup>
        </Toolbar>
      </div>
    )
  }
}

export default InfluencerToolbar
