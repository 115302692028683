import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FlatButton from 'material-ui/FlatButton'
import Dialog from 'material-ui/Dialog'
import Avatar from 'material-ui/Avatar'
import { GridList, GridTile } from 'material-ui/GridList'
import FontIcon from 'material-ui/FontIcon'
import { amber500, cyan500 } from 'material-ui/styles/colors'

import { formatFigure } from '../utils'

const styles = {
  socialIcon: {
    fontSize: 15,
  },
  fineText: {
    fontSize: 12,
  },
};

class CampaignType extends Component {
  state = {
    open: false,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    })
  }

  handleClose() {
    this.setState({
      open: false,
    })

    this.props.onClose()
  }

  handleSubmit(value) {
    this.handleClose()

    this.props.onSubmit(value)
  }

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.handleClose.bind(this)}
      />,
    ]

    return (
      <Dialog
        title="Create a New Campaign"
        actions={actions}
        onRequestClose={this.handleClose.bind(this)}
        open={this.state.open}
        autoScrollBodyContent={true} >
        <br />

        <div>
          <p style={{textAlign: 'center'}}>Choose a type of campaign</p>
          <br />
          <GridList cols={2} cellHeight={250} >
            <GridTile style={{textAlign: 'center'}}>
              <Avatar size={150}
                style={{
                  cursor: 'pointer'
                }}
                onClick={this.handleSubmit.bind(this, 'PPC')}
                backgroundColor={cyan500}
                icon={<FontIcon className="fa fa-hand-o-up" />} />
              <h4>Pay per CLICK</h4>
              <p style={styles.fineText}>
                starts at ${formatFigure(this.props.clickRate) || '0.5'} per click, set maximum clicks and hire more influencer
              </p>
            </GridTile>
            <GridTile style={{textAlign: 'center'}}>
              <Avatar size={150}
                style={{
                  cursor: 'pointer'
                }}
                onClick={this.handleSubmit.bind(this, 'PPP')}
                backgroundColor={amber500}
                icon={<FontIcon className="fa fa-pencil" />} />
              <h4>Pay per POST</h4>
              <p style={styles.fineText}>
                UNLIMITED clicks! just pay for influencer's post fee
              </p>
            </GridTile>
          </GridList>
        </div>
      </Dialog>
    )
  }
}

CampaignType.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

CampaignType.defaultProps = {
  open: false,
};

export default CampaignType
