import api from '../api'

/*
 *  action constants
 */

export const FIND_INVITATION  = 'FIND_INVITATION'
export const GET_INVITATION  = 'GET_INVITATION'
export const UPDATE_INVITATION  = 'UPDATE_INVITATION'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findInvitation= (option) => (dispatch) => {
  let params = '?meta&campaign'
  params += '&filter.status=pending'
  params += '&order=created_at,desc'

  dispatch({
    type: FIND_INVITATION,
    payload: api.get('/invitation' + params),
  })
}

export const getInvitation= (id) => (dispatch) => {
  dispatch({
    type: GET_INVITATION,
    payload: api.get('/invitation/' + id),
  })
}

export const updateInvitation= (id, data) => (dispatch) => {
  dispatch({
    type: UPDATE_INVITATION,
    payload: api.patch('/invitation/' + id, data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
