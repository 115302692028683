import api from '../api'

/*
 *  action constants
 */

export const LOGIN_LINKEDIN     = 'LOGIN_LINKEDIN'
export const CONNECT_LINKEDIN     = 'CONNECT_LINKEDIN'
export const GET_LINKEDIN       = 'GET_LINKEDIN'
export const REMOVE_LINKEDIN      = 'REMOVE_LINKEDIN'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const loginLinkedin = (code, state) => (dispatch) => {
  let params = '?code=' + code + '&state=' + state

  dispatch({
    type: LOGIN_LINKEDIN,
    payload: api.get('/linkedin/callback' + params),
  })
}

export const connectLinkedin = (code, state) => (dispatch) => {
  let params = '?code=' + code + '&state=' + state

  dispatch({
    type: CONNECT_LINKEDIN,
    payload: api.get('/linkedin/connect' + params),
  })
}

export const getLinkedin = (option = '') => (dispatch) => {
  dispatch({
    type: GET_LINKEDIN,
    payload: api.get('/me/linkedin' + option),
  })
}

export const removeLinkedin = () => (dispatch) => {
  dispatch({
    type: REMOVE_LINKEDIN,
    payload: api.delete('/me/linkedin'),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
