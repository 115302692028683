import React, { Component } from 'react'
import { connect } from 'react-redux'

import { redirect, setTitle } from '../utils'
import { findCampaign } from '../actions/campaign'

import EmptyData from '../components/EmptyData'
import PreLoader from '../components/PreLoader'
import CampaignItem from '../components/CampaignItem'
import CampaignToolbar from '../components/CampaignToolbar'

const mapStateToProps = (state, ownProps) => {
  return {
    campaign: state.campaign,
  }
}

const title = 'Campaign'

class Campaign extends Component {
  state = {
    // data
    id: '',
    list: [],

    // notify
    notifyOpen: false,
    notifyMessage: '',

    // dialogs
    dialogOpen: false,
  }

  componentWillMount() {
    setTitle(title)

    // this.props.findCampaign('&order=created_at,desc')
  }

  componentWillReceiveProps(nextProps) {
    let { campaign } = nextProps

    this.setState({
      list: campaign.list || [],

      notifyOpen: campaign.notify.open,
      notifyMessage: campaign.notify.message,
    })
  }

  filterChanged(value) {
    this.props.findCampaign(value)
  }

  onCreate() {
    redirect('/advertiser/influencer?createMode=true')
  }

  render() {
    let { campaign } = this.props

    return (
      <section style={{marginTop: 80}}>
        <PreLoader open={campaign.loading} />

        <CampaignToolbar onChange={this.filterChanged.bind(this)}
          onCreate={this.onCreate.bind(this)} />

        {this.state.list.length !== 0 ? null : (
          <EmptyData />
        )}

        {this.state.list.map((row, index) => (
          <CampaignItem key={index}
            id={row.id}
            title={row.title}
            image={row.image}
            influencers={row.influencers.length}
            status={row.status}
            type={row.type}
            updated={row.updated_at}

            uniqueClicks={row.score.unique_clicks}
            price={row.price}
            rate={row.click_rate}

            clicks={row.score.clicks}
            likes={row.score.likes}
            comments={row.score.comments}
            shares={row.score.shares} />
        ))}
      </section>
    )
  }
}

export default connect(mapStateToProps, {
  findCampaign,
})(Campaign)
