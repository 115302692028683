import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Chart } from 'react-google-charts'
import { Card, CardHeader, CardText } from 'material-ui/Card'

import { getOverviewPlatform } from '../actions/overview'
import { parseGraphPlatform } from '../utils/parse'

import DateScope from '../components/DateScope'

const styles = {
  cardHeader: {
    float: 'left',
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
  }
}

const options = {
  is3D: true,
}

const dataHeader = ['Status', 'Platform']

class OverviewPlatform extends Component {
  state = {
    data: [dataHeader],
  }

  constructor(props) {
    super(props)

    this.changeDateScope = this.changeDateScope.bind(this)
  }

  componentWillMount() {
    this.props.getOverviewPlatform()
  }

  componentWillReceiveProps(nextProps) {
    let { overview } = nextProps

    // parse data
    let data = parseGraphPlatform(overview.platform)

    this.setState({
      data,
    })
  }

  changeDateScope(value) {
    this.props.getOverviewPlatform(value)
  }

  render() {
    return (
      <Card className="card-override" expanded={true}>
        <CardHeader title="Rate per platform" style={styles.cardHeader}
          subtitle="shows total reach base on platform" />

        <DateScope onChange={this.changeDateScope} />

        <CardText>
          <Chart chartType="PieChart" data={this.state.data}
            options={options} width={"100%"}
            height={"300px"} legend_toggle={true} />
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewPlatform,
})(OverviewPlatform)
