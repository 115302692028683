import { formatAmount, deFormatAmount } from './index'

// NOTE needs to format to decimal places to compensate API lack
// of floating number support
export const formatter = (detail, rollback = false) => {
  if ('payment' in detail) {
    if ('post_fee' in detail.payment) {
      detail.payment.post_fee = amountModifier(detail.payment.post_fee, rollback)
    }
  }

  return detail
}

const amountModifier = (value, deFormat = false) => {
    if (deFormat) {
      return deFormatAmount(value)
    }

    return formatAmount(value)
}
