import React from 'react'

import muiThemeable from 'material-ui/styles/muiThemeable'
import FontIcon from 'material-ui/FontIcon'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import { fade } from 'material-ui/utils/colorManipulator'

const getColor = (name) => {
  switch (name) {
  case 'facebook':
      return '#3b5998'
  case 'twitter':
    return '#55acee'
  case 'linkedin':
    return '#0077b5';
  // no default
  }
}

export default muiThemeable()((props) => (
  props.connected ?
  <FlatButton
    style={Object.assign({}, {
      minWidth: 150,
      cursor: 'pointer',
      opacity: props.disabled ? 0.5 : 1,
    }, props.style)}
    labelStyle={props.labelStyle}
    label={props.label}
    disabled={props.disabled}
    onClick={props.onClick}
    hoverColor={fade(getColor(props.name), 0.3)}
    backgroundColor={props.connected ?
      getColor(props.name) :
      props.muiTheme.palette.alternateTextColor}
    icon={<FontIcon className={"fa fa-" + props.name}
      color={props.connected ?
        props.muiTheme.palette.alternateTextColor :
        getColor(props.name) } />} /> :
  <RaisedButton
    style={{
      minWidth: 150,
      opacity: props.disabled ? 0.5 : 1,
    }}
    disabled={props.disabled}
    label={props.label}
    onClick={props.onClick}
    backgroundColor={props.connected ?
      getColor(props.name) :
      props.muiTheme.palette.alternateTextColor}
    icon={<FontIcon className={"fa fa-" + props.name}
      hoverColor={getColor(props.name)}
      color={props.connected ?
        props.muiTheme.palette.alternateTextColor :
        getColor(props.name) } />} />
))
