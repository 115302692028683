import api from '../api'

/*
 *  action constants
 */

export const CREATE_AUDIENCE  = 'CREATE_AUDIENCE'
export const GET_AUDIENCE  = 'GET_AUDIENCE'
export const UPDATE_AUDIENCE  = 'UPDATE_AUDIENCE'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createAudience = (data) => (dispatch) => {
  dispatch({
    type: CREATE_AUDIENCE,
    payload: api.post('/me/audience', data),
  })
}

export const getAudience = () => (dispatch) => {
  dispatch({
    type: GET_AUDIENCE,
    payload: api.get('/me/audience'),
  })
}

export const updateAudience = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_AUDIENCE,
    payload: api.patch('/me/audience', data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
