import React from 'react'
import { Route } from 'react-router'

import advertiser from './advertiser'
import influencer from './influencer'

import Redirect from '../components/Redirect'
import Home from '../components/Home'
import NotFound from '../components/NotFound'
import Error from '../components/Error'
import ForceLogin from '../components/ForceLogin'

export default (
  <Route>
    {advertiser}
    {influencer}

    <Route path="/login-as-influencer/:id/:token" component={ForceLogin}/>
    <Route path="redirect/*" component={Redirect}/>
    <Route path="/" component={Home}/>
    <Route path="/error" component={Error}/>
    <Route path="*" component={NotFound}/>
  </Route>
)
