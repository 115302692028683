import React from 'react'

const styles = {
  badge: {
    fontSize: 10,
    position: 'absolute',
    background: '#ff9100',
    lineHeight: '10px',
    padding: '3px 5px 2px',
    margin: '10px 0 0 5px',
    cursor: 'pointer',
  },
}

const num = '9'

let prefix = 'BETA'
if (process.env.NODE_ENV !== 'production') {
  prefix = 'DEV'
}

export default () => (
  <small style={styles.badge} className="version-badge">{prefix} <span>{num}</span></small>
)
