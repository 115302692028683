import api from '../api'

/*
 *  action constants
 */

export const REGISTER_USER  = 'REGISTER_USER'
export const LOGIN_USER     = 'LOGIN_USER'
export const GET_USER       = 'GET_USER'
export const UPDATE_USER    = 'UPDATE_USER'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const registerUser = (user) => (dispatch) => {
  dispatch({
    type: REGISTER_USER,
    payload: api.post('/register', user),
  })
}

export const loginUser = (email, password) => (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: api.post('/login', {
      email,
      password,
    }),
  })
}

export const getUser = () => (dispatch) => {
  dispatch({
    type: GET_USER,
    payload: api.get('/me'),
  })
}

export const updateUser = (user) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: api.patch('/me', user),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
