import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import { Step, Stepper, StepLabel } from 'material-ui/Stepper'

import { isEmail } from '../utils'
import { getQuery } from '../utils/parse'
import { getLocal, setLocal, removeLocal } from '../utils/storage'
import { getFacebook, connectFacebook } from '../actions/facebook'
import { getTwitter, connectTwitter } from '../actions/twitter'
import { getLinkedin, connectLinkedin  } from '../actions/linkedin'
import { createAudience  } from '../actions/audience'
import { createPayment  } from '../actions/payment'
import { createCharity  } from '../actions/charity'

import WizardUser from '../components/WizardUser'
import WizardAudience from '../components/WizardAudience'
import WizardSocial from '../components/WizardSocial'
import WizardPayment from '../components/WizardPayment'
import WizardCharity from '../components/WizardCharity'

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    facebook: state.facebook,
    twitter: state.twitter,
    linkedin: state.linkedin,
  }
}

class Wizard extends Component {
  state = {
    // user
    name: '',
    email: '',
    image: '',

    // audience
    location: '',
    categories: [],

    // social
    facebookName: '',
    twitterName: '',
    linkedinName: '',

    // payment
    postFee: 0,
    postNote: '',
    paymentEmail: '',
    isAmerican: false,

    // charity
    donation: 0,
    charityEmail: '',

    // wizard dialog
    open: true,
    finished: false,
    stepIndex: 0,
    disableNext: false,
  }

  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.finish = this.finish.bind(this)
  }

  componentWillMount() {
    // social connect
    switch (getQuery('type')) {
    case 'facebook':
      let c = getQuery('code')
      let s = getQuery('state')

      this.props.connectFacebook(c, s)
      this.cleanQuery()
      break;
    case 'twitter':
      let t = getQuery('oauth_token')
      let v = getQuery('oauth_verifier')

      this.props.connectTwitter(t, v)
      this.cleanQuery()
      break;
    case 'linkedin':
      c = getQuery('code')
      s = getQuery('state')

      this.props.connectLinkedin(c, s)
      this.cleanQuery()
      break;
    // no default
    }

    // get social
    // this.props.getFacebook()
    // this.props.getTwitter()
    // this.props.getLinkedin()

    let state = this.state

    // get stored audience
    let wizardIndex = getLocal('wizardIndex')
    let audience = getLocal('audience')
    let payment = getLocal('payment')
    let charity = getLocal('charity')

    if (wizardIndex !== null) {
      state.stepIndex = parseInt(wizardIndex, 10)
    }

    if (audience !== null) {
      state.location = audience.location
      state.categories = audience.categories
    }

    if (payment !== null) {
      state.postFee = payment.postFee
      state.postNote = payment.postNote
      state.paymentEmail = payment.paymentEmail
      state.isAmerican = payment.isAmerican
    }

    if (charity !== null) {
      state.donation = charity.donation
      state.charityEmail = charity.charityEmail
    }
  }

  componentWillReceiveProps(nextProps) {
    let { user, facebook, twitter, linkedin } = nextProps

    if (user.detail.name === undefined) {
      return
    }

    // set user
    this.setState({
      name: user.detail.name,
      email: user.detail.email,
      image: user.detail.image,

      // social
      facebookName: facebook.detail.name || '',
      twitterName: twitter.detail.username || '',
      linkedinName: linkedin.detail.name || '',

      notifyOpen: user.notify.open,
      notifyMessage: user.notify.message,
    })
  }

  cleanQuery() {
    window.location = window.location.origin + '/' + window.location.hash
  }

  handleClose() {
    this.setState({
      open: false,
    })
  }

  handleNext() {
    let { stepIndex, disableNext, location, categories, postFee, postNote, paymentEmail }
      = this.state

    // disable next
    disableNext = false

    // pre check step
    switch (stepIndex) {
    // audience
    case 0:
      disableNext
        = this.checkAudienceRequrements(location, categories)
      break
    // payment
    case 2:
      disableNext
        = this.checkPaymentRequrements(postFee, postNote, paymentEmail)
      break
    // no default
    }

    let finished = stepIndex >= 4
    this.setState({
      stepIndex: stepIndex + 1,
      finished: finished,
      disableNext,
    })

    if (finished) {
      this.handleClose()
    }

    setLocal('wizardIndex', stepIndex + 1)
  }

  handlePrev() {
    let { stepIndex, disableNext } = this.state

    // re-enable on first step
    if (stepIndex === 1 || stepIndex === 3) {
      disableNext = false
    }

    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1,
        disableNext,
      });
    }

    setLocal('wizardIndex', stepIndex - 1)
  }

  setAudience(value) {
    let { disableNext } = this.state
    let { location, categories } = value

    // check required to next
    disableNext = this.checkAudienceRequrements(location, categories)

    // set to storage
    setLocal('audience', {
      location,
      categories,
    })

    this.setState({
      location,
      categories,
      disableNext,
    })
  }

  checkAudienceRequrements(location = '', categories = []) {
    if (categories.length !== 0) {
      return false
    }

    return true
  }

  setPayment(value) {
    let { disableNext } = this.state
    let { postFee, postNote, paymentEmail, isAmerican } = value

    // check required to next
    disableNext = this.checkPaymentRequrements(postFee, postNote, paymentEmail)

    // set to storage
    setLocal('payment', {
      postFee,
      postNote,
      paymentEmail,
      isAmerican,
    })

    this.setState({
      postFee,
      postNote,
      paymentEmail,
      isAmerican,
      disableNext,
    })
  }

  checkPaymentRequrements(fee = '', note = [], email = '') {
    if (email !== '' && !isEmail(email)) {
      return true
    }

    if (fee !== '' && note !== '') {
      return false
    }

    return true
  }

  setCharity(value) {
    let { donation, charityEmail } = value

    // set to storage
    setLocal('charity', {
      donation,
      charityEmail,
    })

    this.setState({
      donation,
      charityEmail,
    })
  }

  finish() {
    let state = this.state
    let data = {
      audience: {
        location: state.location,
        categories: state.categories,
      },

      payment: {
        post_fee: parseInt(parseFloat(state.postFee) * 100, 10),
        post_note: state.postNote,
        paypal_email: state.paymentEmail,
        is_american_citizen: state.isAmerican,
      },

      charity: {
        paypal_email: state.charityEmail,
        donation_percentage: parseInt(state.donation, 10),
      },
    }

    this.props.createAudience(data.audience)
    this.props.createPayment(data.payment)
    this.props.createCharity(data.charity)

    // clean local storage
    removeLocal('wizardIndex')
    removeLocal('audience')
    removeLocal('payment')
    removeLocal('charity')

    this.handleClose()
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <WizardUser name={this.state.name}
          email={this.state.email}
          image={this.state.image} />
      case 1:
        return <WizardAudience
          location={this.state.location}
          categories={this.state.categories}
          onChange={this.setAudience.bind(this)} />
      case 2:
        return <WizardSocial facebook={this.state.facebookName}
          twitter={this.state.twitterName}
          linkedin={this.state.linkedinName} />
      case 3:
        return <WizardPayment postFee={this.state.postFee}
          postNote={this.state.postNote}
          paymentEmail={this.state.paymentEmail}
          isAmerican={this.state.isAmerican}
          onChange={this.setPayment.bind(this)} />
      case 4:
        return <WizardCharity donation={this.state.donation}
          charityEmail={this.state.charityEmail}
          onChange={this.setCharity.bind(this)} />
      default:
        return 'You\'re a long way from home sonny jim!'
    }
  }

  render() {
    const { stepIndex } = this.state

    const actions = [
      <FlatButton label="Back"
        disabled={stepIndex === 0}
        onClick={this.handlePrev} />,
      <RaisedButton label="Next"
        style={{
          display: stepIndex === 4 ? 'none' : 'inline-block'
        }}
        disabled={this.state.disableNext}
        onClick={this.handleNext}
        primary={true} />,
      <RaisedButton label="Finish"
        style={{
          display: stepIndex !== 4 ? 'none' : 'inline-block'
        }}
        disabled={this.state.disableNext}
        primary={true}
        onClick={this.finish} />,
    ]

    return (
      <Dialog
        title="Setup Wizard"
        actions={actions}
        onRequestClose={this.handleClose}
        modal={true}
        autoScrollBodyContent={true}
        autoDetectWindowHeight={false}
        open={this.state.open}>

        <div>
          <Stepper activeStep={stepIndex}>
            <Step>
              <StepLabel>You</StepLabel>
            </Step>
            <Step>
              <StepLabel>Interest</StepLabel>
            </Step>
            <Step>
              <StepLabel>Connections</StepLabel>
            </Step>
            <Step>
              <StepLabel>Payment</StepLabel>
            </Step>
            <Step>
              <StepLabel>Charity</StepLabel>
            </Step>
          </Stepper>

          {this.getStepContent(stepIndex)}
        </div>
      </Dialog>
    )
  }
}

export default connect(mapStateToProps, {
  getFacebook,
  connectFacebook,
  getTwitter,
  connectTwitter,
  getLinkedin,
  connectLinkedin,
  createAudience,
  createPayment,
  createCharity,
})(Wizard)
