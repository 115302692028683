import api from '../api'

/*
 *  action constants
 */

export const GET_CAMPAIGN_STAT_OVERVIEW  = 'GET_CAMPAIGN_STAT_OVERVIEW'
export const GET_CAMPAIGN_STAT_SCORE  = 'GET_CAMPAIGN_STAT_SCORE'
export const GET_CAMPAIGN_STAT_CLICK  = 'GET_CAMPAIGN_STAT_CLICK'
export const GET_CAMPAIGN_STAT_SOURCE  = 'GET_CAMPAIGN_STAT_SOURCE'
export const GET_CAMPAIGN_STAT_PLATFORM  = 'GET_CAMPAIGN_STAT_PLATFORM'
export const GET_CAMPAIGN_STAT_INFLUENCER  = 'GET_CAMPAIGN_STAT_INFLUENCER'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */


export const getCampaignStatOvervew = (id) => (dispatch) => {
   dispatch({
     type: GET_CAMPAIGN_STAT_OVERVIEW,
     payload: api.get('/campaign/' + id + '/overview?title'),
   })
 }

export const getCampaignStatScore = (id) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_STAT_SCORE,
    payload: api.get('/campaign/' + id + '/score'),
  })
}

export const getCampaignStatClick = (id, dateScope) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_STAT_CLICK,
    payload: api.get('/campaign/' + id + '/click' + parseDateScope(dateScope)),
  })
}

export const getCampaignStatSource = (id, dateScope) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_STAT_SOURCE,
    payload: api.get('/campaign/' + id + '/source' + parseDateScope(dateScope)),
  })
}

export const getCampaignStatPlatform = (id, dateScope) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_STAT_PLATFORM,
    payload: api.get('/campaign/' + id + '/platform' + parseDateScope(dateScope)),
  })
}

export const getCampaignStatInfluencer = (id, dateScope) => (dispatch) => {
  dispatch({
    type: GET_CAMPAIGN_STAT_INFLUENCER,
    payload: api.get('/campaign/' + id + '/influencer' + parseDateScope(dateScope)),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF
  })
}

const parseDateScope = (scope) => {
  scope = scope || ''

  return '?date_scope=' + scope
}
