import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'

const styles = {
  dropdownHolder: {
    textAlign: 'right',
  },
}

class DateScope extends Component {
  state = {
    value: 'last_week',
  }

  changeHandler(event, index, value) {
    this.setState({
      value: value,
    })

    this.props.onChange(value)
  }

  render() {
    return (
      <div style={styles.dropdownHolder} >
        <DropDownMenu value={this.state.value}
          onChange={this.changeHandler.bind(this)} >
          <MenuItem value={'last_24_hours'} primaryText="Last 24 hours" />
          <MenuItem value={'last_week'} primaryText="Last 7 days" />
          <MenuItem value={'last_month'} primaryText="Last month" />
          <MenuItem value={'last_year'} primaryText="Last year" />
          <MenuItem value={'all'} primaryText="All" />
        </DropDownMenu>
      </div>
    )
  }
}

DateScope.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default DateScope
