import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setTitle } from '../utils'
import { getCampaignStatOvervew, clearNotif } from '../actions/campaign_stat'

import PreLoader from '../components/PreLoader'
import Notify from '../components/Notify'

import CampaignStatScore from '../containers/CampaignStatScore'
import CampaignStatClick from '../containers/CampaignStatClick'
import CampaignStatSource from '../containers/CampaignStatSource'
import CampaignStatPlatform from '../containers/CampaignStatPlatform'

const mapStateToProps = (state, ownProps) => {
  return {
    campaignStat: state.campaign_stat,
  }
}

class CampaignStat extends Component {
  state = {
    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    this.props.getCampaignStatOvervew(this.props.params.id)
  }

  componentWillReceiveProps(nextProps) {
    let { campaignStat } = nextProps

    setTitle(campaignStat.title + ' stats')

    this.setState({
      notifyOpen: campaignStat.notify.open,
      notifyMessage: campaignStat.notify.message,
    })
  }

  render() {
    let { campaignStat, params, clearNotif } = this.props

    return (
      <div>
        <PreLoader open={campaignStat.loading} />

        <CampaignStatScore id={params.id} />
        <br />
        <CampaignStatClick id={params.id} />
        <br />
        <CampaignStatSource id={params.id} />
        <br />
        <CampaignStatPlatform id={params.id} />
        <br />

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getCampaignStatOvervew,
  clearNotif,
})(CampaignStat)
