import React, { Component } from 'react'
import { connect } from 'react-redux'

import { GridList, GridTile } from 'material-ui/GridList'
import { Card, CardHeader, CardText } from 'material-ui/Card'

import { API_HOST } from '../api'
import { getQuery } from '../utils/parse'
import { loginFacebook, clearNotif as facebookClearNotif }
  from '../actions/facebook'
import { loginTwitter, clearNotif as twitterClearNotif }
  from '../actions/twitter'
import { loginLinkedin, clearNotif as linkedinClearNotif }
  from '../actions/linkedin'
import { isLogin, toSiteURL } from '../utils'

import { lightColor, primaryColor } from './Theme'
import Footer from '../components/Footer'
import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'
import SocialButton from '../components/SocialButton'

const mapStateToProps = (state, ownProps) => {
  return {
    facebook: state.facebook,
    twitter: state.twitter,
    linkedin: state.linkedin,
  }
}

const styles = {
  container: {
    left: 0,
    right: 0,
    height: '100%',
    position: 'absolute',
    background: primaryColor,
  },
  button: {
    height: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  buttonLabel: {
    color: lightColor
  },
}

class Login extends Component {
  state = {
    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    switch (getQuery('type')) {
    case 'facebook':
      let c = getQuery('code')
      let s = getQuery('state')

      this.props.loginFacebook(c, s)
      break;
    case 'twitter':
      let t = getQuery('oauth_token')
      let v = getQuery('oauth_verifier')

      this.props.loginTwitter(t, v)
      break;
    case 'linkedin':
      c = getQuery('code')
      s = getQuery('state')

      this.props.loginLinkedin(c, s)
      break;
    // no default
    }
  }

  componentWillReceiveProps(nextProps) {
    let { facebook, twitter, linkedin } = nextProps

    // redirect to / when success
    if (isLogin()) {
      window.location = window.location.origin + '/#/influencer/overview'
      // location.reload()

      return
    }

    this.setState({
      notifyOpen: facebook.notify.open || twitter.notify.open ||
        linkedin.notify.open,
      notifyMessage: facebook.notify.message || twitter.notify.message ||
        linkedin.notify.message,
    })
  }

  login(type) {
    let callback = window.location.origin

    // fix linkedin callback cant have fragment
    if (type === 'linkedin') {
      callback += '?redirect=/influencer/login&type=' + type
    } else {
      callback += '/#/redirect/influencer/login?type=' + type
    }

    let redir = API_HOST + '/' + type + '/login?callback=' + encodeURIComponent(callback)

    window.location = redir
  }

  closeNotif() {
    this.props.facebookClearNotif()
    this.props.twitterClearNotif()
    this.props.linkedinClearNotif()
  }

  render() {
    let { facebook, twitter, linkedin } = this.props
    let loading = facebook.loading || twitter.loading || linkedin.loading

    return (
      <div style={styles.container}>
        <br />
        <Card className="card-override">
          <ProgressBar open={loading} />

          <CardHeader title="MashDrop Influencer"
            subtitle="Sign up and Login" />
          <CardText>
            <GridList cols={1} cellHeight={70} style={{textAlign: 'center'}}>
              <GridTile>
                <SocialButton name="facebook"
                  disabled={loading}
                  connected={true}
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                  onClick={this.login.bind(this, 'facebook')}
                  label="Login with Facebook"/>
              </GridTile>
              <GridTile>
                <SocialButton name="twitter"
                  disabled={loading}
                  connected={true}
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                  onClick={this.login.bind(this, 'twitter')}
                  label="Login with twitter"/>
              </GridTile>
              <GridTile>
                <SocialButton name="linkedin"
                  disabled={true}
                  connected={true}
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                  onClick={this.login.bind(this, 'linkedin')}
                  label="Login with linkedin"/>
              </GridTile>
            </GridList>

            <br />
            <p style={{
                textAlign: 'center',
              }}>By signing up you confirm that you accept the <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/terms')}>Terms of Use</a> and <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/privacy')}>Privacy Policy</a>.</p>
          </CardText>
        </Card>
        <br />

        <Footer />

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.closeNotif.bind(this)} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  loginFacebook,
  facebookClearNotif,
  loginTwitter,
  twitterClearNotif,
  loginLinkedin,
  linkedinClearNotif,
})(Login)
