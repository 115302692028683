import React from 'react'
import Avatar from 'material-ui/Avatar'

const avatarPlaceholder = '/img/default-profile.png'

class AvatarV2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = { src: props.src };
  }

  componentDidMount() {
    // check if image source has an error.
    const self = this
    const img = new Image()
    img.onerror = function() {
      self.setState({ src: false })
    }
    img.src = this.props.src
  }

  render() {
    const { src } = this.state
    const props = this.props
    if (src === false) {
      let apx = {...props, src: undefined}
      if (props.alt !== undefined) {
        return <Avatar {...apx}>{props.alt}</Avatar>
      }

      apx.src = avatarPlaceholder
      return <Avatar {...apx} />
    }

    let ap = {...props, src}
    return <Avatar {...ap} />
  }
}

export default AvatarV2