import React, { Component } from 'react'
// import { connect } from 'react-redux'

import { List, AutoSizer, WindowScroller } from 'react-virtualized'

class InfiniteList extends Component {
  rowRenderer({key, index, isScrolling, isVisible, style}) {
    let { children, value } = this.props

    if (value.length === 0) {
      return null
    }

    let row = value[index]

    return (
      <div key={key} style={style}>
        {children(row)}
      </div>
    )
  }

  windowScrollerRef(ref) {
    this._windowScroller = ref
  }

  render() {
    let { value } = this.props
    let rowCount = value.length || 0

    return (
      <WindowScroller ref={this.windowScrollerRef.bind(this)}>
        {({ height, isScrolling, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                style={{outline: 'none', overflowY: 'hidden'}}

                autoHeight
                scrollTop={scrollTop}

                width={width}
                height={height}

                rowHeight={this.props.height}
                rowCount={rowCount}
                rowRenderer={this.rowRenderer.bind(this)}

                overscanRowCount={5}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    )
  }
}

export default InfiniteList
