import React, { Component } from 'react'

import numeral from 'numeral'
import IconButton from 'material-ui/IconButton'
import CircularProgress from 'material-ui/CircularProgress'
import { CardMedia, CardTitle } from 'material-ui/Card'
import LinkIcon from 'material-ui/svg-icons/action/open-in-new'

const styles = {
  card: {
    maxWidth: 800,
    margin: '0 auto',
  },
}

class CampaignCreateFinal extends Component {
  render() {
    const loader = (
      <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: '50%',
          textAlign: 'center',
          display: this.props.imageUploading ? 'block' : 'none',
        }}>
        <CircularProgress  />
      </div>
    )

    return (
      <div>
        <div>
          <CardMedia mediaStyle={styles.card} style={styles.card}
            overlay={<CardTitle
              title={<div>
                {this.props.title}
                <IconButton href={this.props.url} target="_blank"
                  style={{float: 'right'}}><LinkIcon color="white" /></IconButton>
              </div>}
              subtitle={<div>{this.props.description}</div>} />}>

              {loader}
            <img src={this.props.image} alt={this.props.image} />
          </CardMedia>
        </div>
        <div style={{maxWidth: 800, margin: '0 auto'}}>
          <p>
            {this.props.type === 'PPP' ? null : (
              <span>
                <strong>{this.props.maxClick}</strong> target clicks for&nbsp;
                <strong>{numeral(this.props.clickRate).format('0,0.00')}</strong> per click with&nbsp;
              </span>
            )}
            <strong>{this.props.influencersCount}</strong> influencers and total cost of&nbsp;
            <strong>{numeral(this.props.price).format('0,0.00')}</strong>
          </p>
        </div>
      </div>
    )
  }
}

export default CampaignCreateFinal
