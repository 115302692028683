import React from 'react'
import { Route, IndexRedirect, IndexRoute } from 'react-router'

import { requireAuth, checkAuth } from '../utils'

// influencer private container
import InfluencerRoot from '../influencer/Root'
import InfluencerOverview from '../influencer/Overview'
import InfluencerCampaign from '../influencer/Campaign'
import InfluencerCampaignStat from '../influencer/CampaignStat'
import InfluencerAccount from '../influencer/Account'
import InfluencerTransaction from '../influencer/Transaction'
import InfluencerFaq from '../influencer/Faq'

// influencer public container
import InfluencerAuth from '../influencer/Auth'
import InfluencerLogin from '../influencer/Login'

const appRoot = '/influencer'

export default (
  <Route path={appRoot}>
    <Route component={InfluencerRoot} onEnter={(nextState, replace) => {
        requireAuth(appRoot, nextState, replace)
      }}>
      <IndexRedirect to="overview"/>
      <Route path="overview" component={InfluencerOverview}/>
      <Route path="campaign">
        <IndexRoute component={InfluencerCampaign}/>
        <Route path=":id/stats" component={InfluencerCampaignStat}/>
      </Route>
      <Route path="account" component={InfluencerAccount}/>
      <Route path="transaction" component={InfluencerTransaction}/>
      <Route path="transaction/:id/view" component={InfluencerTransaction}/>
      <Route path="faq" component={InfluencerFaq}/>
    </Route>

    <Route component={InfluencerAuth} onEnter={(nextState, replace) => {
        checkAuth(appRoot, nextState, replace)
      }}>
      <Route path="login" component={InfluencerLogin}/>
    </Route>
  </Route>
)
