import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardHeader, CardText } from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'

import { getTransactionScore } from '../actions/transaction'
import { formatFigure } from '../utils'

import Score from '../components/Score'

const mapStateToProps = (state, ownProps) => {
  return {
    transaction: state.transaction,
  }
}

class TransactionScore extends Component {
  state = {
    score: {},
  }

  componentWillMount() {
    this.props.getTransactionScore()
  }

  componentWillReceiveProps(nextProps) {
    let { transaction } = nextProps

    this.setState({
      score: transaction.score,

      // notifyOpen: transaction.notify.open,
      // notifyMessage: transaction.notify.message,
    })
  }

  render() {
    return (
      <Card className="card-override">
        <CardHeader title="Dashboard" />
        <CardText>
          <GridList cols={4} cellHeight={50}>
            <GridTile>
              <Score title={'Total Buy'} value={formatFigure(this.state.score.total_buy)} />
            </GridTile>
            <GridTile>
              <Score title={'PPC Total'} value={formatFigure(this.state.score.total_ppc)} />
            </GridTile>
            <GridTile>
              <Score title={'PPP Total'} value={formatFigure(this.state.score.total_ppp)} />
            </GridTile>
            <GridTile>
              <Score title={'Remaining Balance'} value={formatFigure(this.state.score.remaining_balance)} />
            </GridTile>
          </GridList>
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getTransactionScore,
})(TransactionScore)
