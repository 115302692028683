import * as influencerAction from '../actions/influencer'
import { formatter } from '../utils/influencer'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  meta: {},
  list: [],
  posts: [],
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find influencer
    case `${influencerAction.FIND_INFLUENCER}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${influencerAction.FIND_INFLUENCER}_FULFILLED`:
      for (var i = 0; i < action.payload.data.data.length; i++) {
        let r = action.payload.data.data[i]
        formatter(r)
      }

      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data.data,
        meta: {
          result_count: action.payload.data.result_count,
          total_count: action.payload.data.total_count,
        },
      })
    case `${influencerAction.FIND_INFLUENCER}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get influencer profile
    case `${influencerAction.GET_INFLUENCER_PROFILE}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${influencerAction.GET_INFLUENCER_PROFILE}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: formatter(action.payload.data),
      })
    case `${influencerAction.GET_INFLUENCER_PROFILE}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get influencer post
    case `${influencerAction.GET_INFLUENCER_POST}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        posts: [],
      })
    case `${influencerAction.GET_INFLUENCER_POST}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        posts: action.payload.data,
      })
    case `${influencerAction.GET_INFLUENCER_POST}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // influencer clear message
    case influencerAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
