import React, { Component } from 'react'
import { connect } from 'react-redux'

import Chip from 'material-ui/Chip'
import Avatar from 'material-ui/Avatar'
import Subheader from 'material-ui/Subheader'
import TextField from 'material-ui/TextField'
import FlatButton from 'material-ui/FlatButton'
import EditIcon from 'material-ui/svg-icons/image/edit'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'

import { getUser, updateUser, clearNotif } from '../actions/user'
import { getAudience, updateAudience, clearNotif as audienceClearNotif }
  from '../actions/audience'

import Notify from '../components/Notify'
import { primaryColor } from '../influencer/Theme'
import ProgressBar from '../components/ProgressBar'
import LocationInput from '../components/LocationInput'
import CategoryDialog from '../components/CategoryDialog'

const styles = {
  chip: {
    marginRight: 8,
    marginLeft: 0,
  },
  chipWrapper: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    height: 32,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    audience: state.audience,
  }
}

class AccountProfile extends Component {
  state = {
    name: '',
    email: '',
    image: '',
    categories: [],

    openDialog: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    this.props.getUser()
    this.props.getAudience()
  }

  componentWillReceiveProps(nextProps) {
    let { user, audience } = nextProps

    this.setState({
      name: user.detail.name || '',
      email: user.detail.email || '',
      image: user.detail.image || '',
      location: audience.detail.location || '',
      categories: audience.detail.categories || [],

      notifyOpen: user.notify.open || audience.notify.open,
      notifyMessage: user.notify.message || audience.notify.message,
    })
  }

  toggleDialog() {
    this.setState({
      openDialog: !this.state.openDialog,
    })
  }

  onChange(field, value) {
    let state = this.state

    state[field] = value

    this.setState(state)
  }

  onNameChange(e, value) {
    let state = this.state

    state.name = value

    this.setState(state)
  }

  onSave() {
    let { name, location, categories } = this.state

    this.props.updateUser({
      name,
    })

    // since we cannot submit empty value
    // fill it with dash
    if (location === '') {
      location = '-'
    }

    this.props.updateAudience({
      location,
      categories,
    })
  }

  clearNotif() {
    this.props.clearNotif()
    this.props.audienceClearNotif()
  }

  render() {
    let { user, audience } = this.props

    return (
      <div>
        <CategoryDialog open={this.state.openDialog}
          value={this.state.categories}
          onSubmit={this.onChange.bind(this, 'categories')}
          onRequestClose={this.toggleDialog.bind(this)} />

        <Card className="card-override">
          <ProgressBar open={user.loading || audience.loading} />
          <CardHeader subtitle="Profile Information" />
          <CardText>
            <Avatar src={this.state.image} size={200} style={{ float: 'right', marginRight: 60 }} />

            <TextField floatingLabelText="Name"
              style={{width: '60%'}}
              floatingLabelFixed={true}
              value={this.state.name}
              onChange={this.onNameChange.bind(this)} />
            <br />
            <TextField floatingLabelText="Email"
              style={{width: '60%'}}
              floatingLabelFixed={true}
              disabled={true}
              defaultValue={this.state.email} />
            <br />

            <LocationInput floatingLabelText="Audience Location for Local Ads"
              fullWidth={true}
              floatingLabelFixed={this.state.location !== ''}
              searchText={this.state.location}
              onChange={this.onChange.bind(this, 'location')} />

            <Subheader style={{marginLeft: -16}} >Category</Subheader>
            {this.state.categories.length === 0 ? (
              <FlatButton label="Select categories"
                primary={true}
                onClick={this.toggleDialog.bind(this)} />
            ) : (
              <div style={styles.chipWrapper}>
                {this.state.categories.map((item, index) => (
                  <Chip key={index} style={styles.chip}>{item}</Chip>
                ))}

                <Avatar size={32}
                  icon={<EditIcon />}
                  style={{cursor: 'pointer'}}
                  backgroundColor={primaryColor}
                  onClick={this.toggleDialog.bind(this)} />
              </div>
            )}
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            <FlatButton label="Save" primary={true}
              onClick={this.onSave.bind(this)} />
          </CardActions>
        </Card>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.clearNotif.bind(this)} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getUser,
  updateUser,
  clearNotif,
  getAudience,
  updateAudience,
  audienceClearNotif,
})(AccountProfile)
