import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setTitle } from '../utils'
import { getUser, updateUser } from '../actions/user'

import Notify from '../components/Notify'
import AccountProfile from './AccountProfile'
import AccountSocial from './AccountSocial'
import AccountPayment from './AccountPayment'
import AccountCharity from './AccountCharity'
import AccountAutopost from './AccountAutopost'

const mapStateToProps = (state, ownProps) => {
  return {
    app: state.app,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserHandler: () => dispatch(getUser()),
    updateUserHandler: (user) => dispatch(updateUser(user)),
  }
}

// const styles = {
//   chip: {
//     marginRight: 8,
//     marginLeft: 0,
//   },
//   chipWrapper: {
//     textAlign: 'left',
//     display: 'flex',
//     alignItems: 'left',
//     justifyContent: 'left',
//     height: 32,
//   },
// }

const title = 'Account Setting'

class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogOpen: false,
      notifyOpen: false,
      notifyMessage: '',
      searchLocationDialogOpen: false,
      categoryDialogOpen: false,
    }

    this.openCategoryDialog = this.openCategoryDialog.bind(this)
  }

  componentWillMount() {
    setTitle(title)

    // this.props.getUserHandler()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.notify.open) {
      this.setState({
        notifyOpen: true,
        notifyMessage: nextProps.user.notify.message,
      })
    }
  }

  closeDialog() {
    this.setState({
      dialogOpen: false,
    })
  }

  closeNotify() {
    this.setState({
      notifyOpen: false,
    })
  }

  openDialog() {
    this.setState({
      dialogOpen: true,
    })
  }

  openCategoryDialog() {
    this.setState({
      categoryDialogOpen: true,
    })
  }

  infoSaveHandler() {
    let user = {
      name: this.nameField.input.value,
    }

    this.props.updateUserHandler(user)
  }

  resetPasswordSumbitHandler() {
    console.log('submitting reset password link');
    this.closeDialog()
  }

  render() {
    // let { user, app } = this.props

    // const dialogActions = [
    //   <FlatButton
    //     label="Cancel"
    //     primary={false}
    //     onClick={this.closeDialog.bind(this)} />,
    //   <FlatButton
    //     label="Submit"
    //     primary={true}
    //     onClick={this.resetPasswordSumbitHandler.bind(this)} />,
    // ];

    return (
      <div>
        <AccountProfile />
        <br />

        <AccountSocial />
        <br />

        <AccountPayment />
        <br />

        <AccountAutopost />
        <br />

        <AccountCharity />
        <br />

        <Notify open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.closeNotify.bind(this)} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
