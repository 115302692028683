import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getUser } from '../actions/user'
import { findCategory } from '../actions/category'
import { getSetting } from '../actions/setting'
import { getTransactionScore } from '../actions/transaction'
import { redirect, setContext, getAuth, isMobile, viewPortRefresher }
  from '../utils'

import Nav from './Nav'
import TitleBar from './TitleBar'
import Theme from './Theme'
import Notification from '../containers/Notification'
import UserErrorDialog from '../containers/UserErrorDialog'

const styles = {
  container: {
    marginLeft: isMobile() ? 0 : 256,
  },
  menu: {
    textDecoration: 'none',
  },
  content: {
    paddingTop: 50,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    app: state.app,
    transaction: state.transaction,
  }
}

class Root extends Component {
  state = {
    notifDrawerOpen: false,
    navDrawerOpen: !isMobile(),
  }

  constructor(props) {
    super(props)

    this.notifToggler = this.notifToggler.bind(this)
    this.navToggler = this.navToggler.bind(this)
  }

  componentWillMount() {
    setContext(this.context)

    // check type
    if (getAuth().type !== 'advertiser') {
      redirect('/error')

      return
    }

    // get user
    this.props.getUser()

    // bootstrap
    this.props.getSetting()
    this.props.findCategory()
    this.props.getTransactionScore()
  }

  componentWillReceiveProps(nextProps) {
    let { user } = nextProps

    // check if current has no error
    if (user.error === true) {
      return
    }
  }

  componentDidMount() {
    // refresh if size changed
    viewPortRefresher()
  }

  notifToggler() {
    this.setState({
      notifDrawerOpen: !this.state.notifDrawerOpen
    })
  }

  navToggler() {
    // dont toggle if not mobile
    if (!isMobile()) {
      return
    }

    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    })
  }

  render() {
    return (
      <Theme>
        <div style={styles.container}>
          <UserErrorDialog />

          <TitleBar onBellClick={this.notifToggler}
            onMenuClick={this.navToggler} />

          <Nav open={this.state.navDrawerOpen} toggle={this.navToggler} />

          <Notification open={this.state.notifDrawerOpen}
            onRequestClose={this.notifToggler} />

          <section style={styles.content}>
            {this.props.children}
          </section>
        </div>
      </Theme>
    )
  }
}

Root.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps, {
  getUser,
  getSetting,
  findCategory,
  getTransactionScore,
})(Root)
