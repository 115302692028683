import * as charityAction from '../actions/charity'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // create charity
    case `${charityAction.CREATE_CHARITY}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${charityAction.CREATE_CHARITY}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${charityAction.CREATE_CHARITY}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get charity
    case `${charityAction.GET_CHARITY}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
        detail: {},
      })
    case `${charityAction.GET_CHARITY}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${charityAction.GET_CHARITY}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // update charity
    case `${charityAction.UPDATE_CHARITY}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${charityAction.UPDATE_CHARITY}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: true,
          message: 'Changes saved'
        },
      })
    case `${charityAction.UPDATE_CHARITY}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // charity
    case charityAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
