import * as facebookAction from '../actions/facebook'
import { setAuth } from '../utils'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  detail: {},
  groups: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // login
    case `${facebookAction.LOGIN_FACEBOOK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${facebookAction.LOGIN_FACEBOOK}_FULFILLED`:
      // saving credentials
      setAuth(action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${facebookAction.LOGIN_FACEBOOK}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // connect
    case `${facebookAction.CONNECT_FACEBOOK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${facebookAction.CONNECT_FACEBOOK}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
      })
    case `${facebookAction.CONNECT_FACEBOOK}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // get facebook
    case `${facebookAction.GET_FACEBOOK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${facebookAction.GET_FACEBOOK}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: action.payload.data,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${facebookAction.GET_FACEBOOK}_REJECTED`:
      let notify = {
        open: true,
        message: action.payload.response.data.msg
      }

      // do not throw this error because its just get
      if (action.payload.response.data.name === 'FACEBOOK_RESOURCE_DETAIL_ERR') {
        notify.open = false
      }

      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify,
      })

      // get facebook groups
      case `${facebookAction.GET_FACEBOOK_GROUPS}_PENDING`:
        return Object.assign({}, state, {
          loading: true,
          notify: {
            open: false,
            message: ''
          },
        })
      case `${facebookAction.GET_FACEBOOK_GROUPS}_FULFILLED`:
        return Object.assign({}, state, {
          loading: false,
          groups: action.payload.data,
          notify: {
            open: false,
            message: ''
          },
        })
      case `${facebookAction.GET_FACEBOOK_GROUPS}_REJECTED`:
        notify = {
          open: true,
          message: action.payload.response.data.msg
        }

        // do not throw this error because its just get
        if (action.payload.response.data.name === 'FACEBOOK_RESOURCE_DETAIL_ERR') {
          notify.open = false
        }

        return Object.assign({}, state, {
          loading: false,
          error: true,
          notify,
        })

    // remove
    case `${facebookAction.REMOVE_FACEBOOK}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
      })
    case `${facebookAction.REMOVE_FACEBOOK}_FULFILLED`:
      return Object.assign({}, state, {
        loading: false,
        detail: {},
        notify: {
          open: true,
          message: 'Facebook successfully removed'
        },
      })
    case `${facebookAction.REMOVE_FACEBOOK}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // notification
    case facebookAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
