import React, { Component } from 'react'

import Dropzone from 'react-dropzone'
import TextField from 'material-ui/TextField'
import Avatar from 'material-ui/Avatar'

import { upload } from '../utils/upload'

// import AvatarV2 from './AvatarV2'

class WizardUser extends Component {
  state = {
    preview: '/img/default-campaign.png',
    file: {},
  }

  componentWillMount() {
    this.setState({
      preview: this.props.image,
    })
  }

  changeImage(files) {
    if (files.length < 0) {
      return
    }

    // trigger upload
    upload(files[0], (path) => {
      // handle success
      this.setState({ preview: path })
      this.props.onChange(path)
    }, (err) => {
      // handle error
    })

    this.setState({
      preview: files[0].preview,
      file: files[0],
    })
  }

  selectFile() {
    this.dropzone.open();
  }

  render() {
    return (
      <div>
        <Dropzone ref={(node) => { this.dropzone = node; }}
          style={{display: 'none'}}
          multiple={false}
          accept={'image/*'}
          onDrop={this.changeImage.bind(this)} />

        <p>Setting up your profile</p>
        <div style={{textAlign: 'center'}}>
          <Avatar src={this.props.image} size={100} />
        </div>
        <TextField
          disabled={true}
          style={{width: '49%'}}
          floatingLabelText="Email"
          value={this.props.email || '-'}
        />
        <TextField
          disabled={true}
          style={{width: '49%', float: 'right'}}
          floatingLabelText="Name"
          value={this.props.name || '-'}
        />
      </div>
    )
  }
}

export default WizardUser
