import React, { Component } from 'react'

import Chip from 'material-ui/Chip'
import Avatar from 'material-ui/Avatar'
import Subheader from 'material-ui/Subheader'
import FlatButton from 'material-ui/FlatButton'
import EditIcon from 'material-ui/svg-icons/image/edit'

import { primaryColor } from '../influencer/Theme'
import LocationInput from '../components/LocationInput'
import CategoryDialog from '../components/CategoryDialog'

const styles = {
  chip: {
    marginRight: 8,
    marginLeft: 0,
  },
  chipWrapper: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    height: 32,
  },
}

class WizardAudience extends Component {
  state = {
    location: '',
    categories: [],

    openDialog: false
  }

  componentWillMount() {
    let { location, categories } = this.props

    this.setState({
      location,
      categories,
    })
  }

  toggleDialog() {
    this.setState({
      openDialog: !this.state.openDialog,
    })
  }

  onChange(field, value) {
    let state = this.state

    state[field] = value

    this.setState(state)

    this.props.onChange(state)
  }

  render() {
    return (
      <div>
        <CategoryDialog open={this.state.openDialog}
          value={this.state.categories}
          onSubmit={this.onChange.bind(this, 'categories')}
          onRequestClose={this.toggleDialog.bind(this)} />

        <p>What are your interests and Where is your audience</p>
        <Subheader style={{marginLeft: -16}} >Category (required)</Subheader>
        {this.state.categories.length === 0 ? (
          <FlatButton label="Select categories"
            primary={true}
            onClick={this.toggleDialog.bind(this)} />
        ) : (
          <div style={styles.chipWrapper}>
            {this.state.categories.map((item, index) => (
              <Chip key={index} style={styles.chip}>{item}</Chip>
            ))}

            <Avatar size={32}
              icon={<EditIcon />}
              style={{cursor: 'pointer'}}
              backgroundColor={primaryColor}
              onClick={this.toggleDialog.bind(this)} />
          </div>
        )}

        <LocationInput fullWidth={true}
          floatingLabelText="Audience Location for Local Ads"
          searchText={this.state.location}
          hintText="Find your City, Country or Region"
          onChange={this.onChange.bind(this, 'location')} />
      </div>
    )
  }
}

export default WizardAudience
