import PropTypes from 'prop-types'
import React, { Component } from 'react'

import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import Dialog from 'material-ui/Dialog'

class SearchDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      open: false,
    }

    this.onEnter = this.onEnter.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    })
  }

  handleClose() {
    this.setState({
      open: false,
    })

    this.props.onRequestClose()
  }

  onChange(e, text) {
    this.setState({
      value: String(text).trim(),
    })
  }

  onSubmit() {
    this.handleClose()

    this.props.onSubmit(this.state.value)
  }

  onEnter(e) {
    // enter key is hit
    if (e.keyCode === 13) {
      this.onSubmit()
    }
  }

  render() {
    const actions = [
      <FlatButton
        label="Close"
        onClick={this.handleClose}
      />,
      <RaisedButton
        label="Submit"
        primary={true}
        onClick={this.onSubmit}
      />,
    ]

    return (
      <Dialog
        title="Search Name or Email"
        actions={actions}
        onRequestClose={this.handleClose}
        open={this.state.open}>
        <TextField
          onKeyDown={this.onEnter}
          onChange={this.onChange}
          floatingLabelText="Name filter"
          fullWidth={true}
        />
      </Dialog>
    )
  }
}

SearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
}

SearchDialog.defaultProps = {
  open: false,
};

export default SearchDialog
