import React, { Component } from 'react'

import { getQuery } from '../utils/parse'

class Redirect extends Component {
  componentWillMount() {
    let u = window.location.origin + '/#' +
      this.getBasePath() +
      // '?type=' + getQuery('type') +

      '&code=' + getQuery('code') +
      '&state=' + getQuery('state') +
      '&oauth_token=' + getQuery('oauth_token') +
      '&oauth_verifier=' + getQuery('oauth_verifier')

    // normalizes url
    window.location = u
  }

  getBasePath() {
    return window.location.hash.replace('#/redirect', '')
  }

  render() {
    return (
      <p style={{
          textAlign: 'center',
        }}>Redirecting...</p>)
  }
}

export default Redirect
