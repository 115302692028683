import * as cookie from './cookie'

let watchers = {
  local: {},
  session: {},
}

const store = (type = '') => {
  let name = type + 'Storage'

  // check support
  if (!(name in window)) {
    return new cookie.Storage(type)
  }

  // check web storage write issue
  // this happens on private mode in safari
  try {
    // test storage setter
    let k = 'test'
    let store = window[name]
    store.setItem(k, k)
    store.removeItem(k)

    // its compatible
    return store
  } catch (e) {
    // fallback storage
    return new cookie.Storage(type)
  }
}

let storage = {
  local: store('local'),
  session: store('session'),
}

const encode = (value) => {
  switch (typeof(value)) {
  case 'object':
    // save meta
    return JSON.stringify(value)
  // no default
  }

  // string or number
  return value
}

const decode = (value) => {
  switch (typeof(value)) {
  case 'number':
    return value
  case 'string':
    // check if its an object or array
    if (value.indexOf('{') === 0 || value.indexOf('[') === 0) {
      return JSON.parse(value)
    }

    return value
  // no default
  }

  // unknown
  return value
}

const set = (type, key, value) => {
  changes(type, key, value)

  storage[type].setItem(key, encode(value))
}

const get = (type, key) => {
  return decode(storage[type].getItem(key))
}

const remove = (type, key) => {
  decode(storage[type].removeItem(key))
}

const clear = (type) => {
  storage[type].clear()
}

const changes = (type, key, value) => {
  let d = watchers[type][key]
  for (var i in d) {
    d[i](value)
  }
}

export const setLocal = (key, value) => {
  set('local', key, value)
}

export const getLocal = (key) => {
  return get('local', key)
}

export const removeLocal = (key) => {
  remove('local', key)
}

export const clearLocal = () => {
  clear('local')
}

export const setSession = (key, value) => {
  set('session', key, value)
}

export const getSession = (key) => {
  return get('session', key)
}

export const removeSession = (key) => {
  remove('session', key)
}

export const clearSession = () => {
  clear('session')
}

export const watchChanges = (type, key, callback) => {
  if (watchers[type][key] === undefined) {
    watchers[type][key] = []
  }

  watchers[type][key].push(callback)
}
