import React, { Component } from 'react'
import { connect } from 'react-redux'

import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'

import { getAutopost, updateAutopost, clearNotif } from '../actions/autopost'

import { toSiteURL } from '../utils'

import Note from '../components/Note'
import ConfirmDialog from '../components/ConfirmDialog'
import Notify from '../components/Notify'
import ProgressBar from '../components/ProgressBar'

const mapStateToProps = (state, ownProps) => {
  return {
    autopost: state.autopost,
  }
}

class AccountAutopost extends Component {
  state = {
    active: false,

    enableToggleOpen: false,

    notifyOpen: false,
    notifyMessage: '',
  }

  componentWillMount() {
    this.props.getAutopost()
  }

  componentWillReceiveProps(nextProps) {
    let { autopost } = nextProps

    this.setState({
      active: Boolean(autopost.detail.active),

      notifyOpen: autopost.notify.open,
      notifyMessage: autopost.notify.message,
    })
  }

  toggleActive() {
    let active = !this.state.active

    this.props.updateAutopost({
      active: active,
    })
  }

  toggleConfirmDialog() {
    this.setState({
      enableToggleOpen: !this.state.enableToggleOpen
    })
  }

  render() {
    let { autopost } = this.props

    return (
      <div>
        <ConfirmDialog open={this.state.enableToggleOpen}
          title="Enable AutoPost"
          confirmLabel="I agree on Term & Conditions"
          cancelLabel="Close"
          body={<span>This will grant us access on your account, By confirming this you agree on <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/autopost-terms')}>Term &amp; Conditions</a> of this feature. You can disabled anytime.</span>}
          onClose={this.toggleConfirmDialog.bind(this)}
          onConfirm={this.toggleActive.bind(this)} />

        <Card className="card-override">
          <ProgressBar open={autopost.loading} />
          <CardHeader subtitle="AutoPost" />
          <CardText>
              Automatically Post new campaign to reach target goals
              <br />
            <Note>
              AutoPost will allow us to access your accout. Please read <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/terms')}>Term &amp; Conditions</a>.
              </Note>
          </CardText>
          <CardActions style={{textAlign: 'right'}}>
            {this.state.active ? (
                <FlatButton disabled={autopost.loading} label="Disable AutoPost"
                  onClick={this.toggleActive.bind(this)} />
              ) : (
                <RaisedButton disabled={autopost.loading} label="Enable AutoPost" primary={true}
                  onClick={this.toggleConfirmDialog.bind(this)} />
            )}
          </CardActions>
        </Card>
        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  getAutopost,
  updateAutopost,
  clearNotif,
})(AccountAutopost)
