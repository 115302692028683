import api from '../api'

/*
 *  action constants
 */

export const CREATE_CHARITY  = 'CREATE_CHARITY'
export const GET_CHARITY  = 'GET_CHARITY'
export const UPDATE_CHARITY  = 'UPDATE_CHARITY'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const createCharity = (data) => (dispatch) => {
  dispatch({
    type: CREATE_CHARITY,
    payload: api.post('/me/charity', data),
  })
}

export const getCharity = (id) => (dispatch) => {
  dispatch({
    type: GET_CHARITY,
    payload: api.get('/me/charity'),
  })
}

export const updateCharity = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CHARITY,
    payload: api.patch('/me/charity', data),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
