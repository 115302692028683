import { setSession } from '../utils/storage'
import * as categoryAction from '../actions/category'

let defaultState = {
  loading: false,
  notify: {
    open: false,
    message: ''
  },
  error: false,
  list: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // find category
    case `${categoryAction.FIND_CATEGORY}_PENDING`:
      return Object.assign({}, state, {
        loading: true,
        notify: {
          open: false,
          message: ''
        },
      })
    case `${categoryAction.FIND_CATEGORY}_FULFILLED`:
      setSession('categories', action.payload.data)

      return Object.assign({}, state, {
        loading: false,
        list: action.payload.data,
      })
    case `${categoryAction.FIND_CATEGORY}_REJECTED`:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        notify: {
          open: true,
          message: action.payload.response.data.msg
        },
      })

    // category clear message
    case categoryAction.CLEAR_NOTIF:
      return Object.assign({}, state, {
        notify: defaultState.notify
      })

    // no default
  }

  return state
}
