import React, { Component } from 'react';

import FontIcon from 'material-ui/FontIcon'
import TextField from 'material-ui/TextField'

class WizardCharity extends Component {
  state = {
    donation: 0,
    charityEmail: '',
  }

  componentWillMount() {
    let { donation, charityEmail } = this.props

    this.setState({
      donation,
      charityEmail,
    })
  }

  onChange(field, e) {
    let value = e.target.value
    let state = this.state

    state[field] = value
    this.setState(state)

    this.props.onChange(state)
  }

  render() {
    return (
      <div>
        <p>
          Help a Charity of your choice
          &nbsp;
          <FontIcon className="fa fa-heart"/>
        </p>

        <TextField floatingLabelFixed={true}
          floatingLabelText="Charity Paypal Email"
          style={{width: '49%'}}
          value={this.state.charityEmail}
          onChange={this.onChange.bind(this, 'charityEmail')} />
        <TextField floatingLabelFixed={true}
          floatingLabelText="Donation Percentage"
          style={{width: '49%', float: 'right'}}
          type="number"
          value={this.state.donation}
          onChange={this.onChange.bind(this, 'donation')} />
      </div>
    )
  }
}

export default WizardCharity
