import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardHeader, CardText } from 'material-ui/Card'
import { GridList, GridTile } from 'material-ui/GridList'

import { getOverviewScore } from '../actions/overview'
import { formatFigure } from '../utils'

import Score from '../components/Score'

const mapStateToProps = (state, ownProps) => {
  return {
    overview: state.overview,
  }
}

class OverviewScore extends Component {
  state = {
    score: {},
  }

  componentWillMount() {
    this.props.getOverviewScore()
  }

  componentWillReceiveProps(nextProps) {
    let { overview } = nextProps

    this.setState({
      score: overview.score,

      // notifyOpen: overview.notify.open,
      // notifyMessage: overview.notify.message,
    })
  }

  render() {
    return (
      <Card className="card-override">
        <CardHeader title="Dashboard" />
        <CardText>
          <GridList cols={4} cellHeight={50}>
            <GridTile>
              <Score title={'Total Earnings'} value={formatFigure(this.state.score.total_earning)} />
            </GridTile>
            <GridTile>
              <Score title={'PPC Earnings'} value={formatFigure(this.state.score.ppc_earning)} />
            </GridTile>
            <GridTile>
              <Score title={'PPP Earnings'} value={formatFigure(this.state.score.ppp_earning)} />
            </GridTile>
            <GridTile>
              <Score title={'Posted Campaign'} value={this.state.score.campaign_post || '-'} />
            </GridTile>
          </GridList>
        </CardText>
      </Card>
    )
  }
}

export default connect(mapStateToProps, {
  getOverviewScore,
})(OverviewScore)
