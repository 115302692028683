import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import TextField from 'material-ui/TextField'
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import { registerUser, loginUser, clearNotif } from '../actions/user'
import { toSiteURL } from '../utils'

import ProgressBar from '../components/ProgressBar'
import Notify from '../components/Notify'

const styles = {
  card: {
    marginTop: 20,
    marginBottom: 20,
  },
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  }
}

const defaultState = {
  success: false,

  nameField: '',
  contactNameField: '',
  emailField: '',
  passwordField: '',

  notifyOpen: false,
  notifyMessage: '',
}

class Register extends Component {
  state = defaultState

  constructor(props) {
    super(props)

    this.submitHandler = this.submitHandler.bind(this)
    this.submitOnEnterHandler = this.submitOnEnterHandler.bind(this)
  }

  submitHandler() {
    let user = {
      name: this.state.nameField,
      contact_name: this.state.contactNameField,
      email: this.state.emailField,
      password: this.state.passwordField,
    }

    // check

    this.props.registerUser(user)
  }

  submitOnEnterHandler(e) {
    // enter key is hit
    if (e.keyCode === 13) {
      this.submitHandler()
    }
  }

  componentWillReceiveProps(nextProps) {
    let { user } = nextProps
    let { success } = this.state

    // redirect to / when success
    if (user.detail.id !== undefined) {
      success = true
    }

    this.setState({
      success,
      notifyOpen: user.notify.open,
      notifyMessage: user.notify.message,
    })
  }

  render() {
    let { user } = this.props
    let { success } = this.state

    return (
      <div>
        {success ? (
          <Card className="card-override" style={styles.card}>
            <CardHeader title="MashDrop Advertiser" subtitle="Registration" />
            <CardText>
              <p>
                Congratulations <strong>{user.detail.name}</strong>!
                <br />
                <br />
                You are now registered but it needs confirmation.
                We sent you an email on <em><strong>{user.detail.email}</strong></em> to verify your account.
              </p>
            </CardText>
          </Card>
        ) : (
          <Card className="card-override" style={styles.card}>
            <ProgressBar open={user.loading} />

            <CardHeader title="MashDrop Advertiser" subtitle="Registration" />
            <CardText>
              <TextField floatingLabelText="Brand/Company Name" fullWidth={true}
                floatingLabelFixed={true}
                onChange={(e) => {this.setState({nameField: e.target.value })}} />
              <br />
              <TextField floatingLabelText="Contact Name" fullWidth={true}
                floatingLabelFixed={true}
                onChange={(e) => {this.setState({contactNameField: e.target.value })}} />
              <br />
              <TextField floatingLabelText="Email" fullWidth={true}
                floatingLabelFixed={true}
                onChange={(e) => {this.setState({emailField: e.target.value })}} />
              <br />
              <TextField floatingLabelText="Password" fullWidth={true}
                onKeyDown={this.submitOnEnterHandler}
                floatingLabelFixed={true} type="password"
                onChange={(e) => {this.setState({passwordField: e.target.value })}} />
              <br />
            </CardText>

            <CardActions style={{textAlign: 'center'}}>
              <RaisedButton label="Register" primary={true}
                disabled={user.loading}
                onClick={this.submitHandler} />

              <br />
              <p style={{
                  textAlign: 'center',
                }}>By signing up you confirm that you accept the <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/terms')}>Terms of Use</a> and <a target="_blank" rel="noopener noreferrer" href={toSiteURL('/privacy')}>Privacy Policy</a>.</p>
            </CardActions>
          </Card>
        )}

        <Link to="/advertiser/login">
          <FlatButton label="Already have an account?" />
        </Link>

        <Notify
          open={this.state.notifyOpen}
          message={this.state.notifyMessage}
          onRequestClose={this.props.clearNotif} />
      </div>
    )
  }
}

export default connect(mapStateToProps, {
  registerUser,
  loginUser,
  clearNotif,
})(Register)
