import axios from 'axios'
import { setApiInstance, getAuthToken } from '../utils'

export const API_HOST = process.env.REACT_APP_API_HOST

const createInstance = () => {
  const instance = axios.create({
    baseURL: API_HOST,
    headers: {'Authorization': 'Bearer ' + getAuthToken()}
  })

  setApiInstance(instance)

  return instance
}

export default createInstance()
