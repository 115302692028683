import React, { Component } from 'react'

import { grey50, teal500, indigo500 } from 'material-ui/styles/colors'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import InfoIcon from 'material-ui/svg-icons/action/info'

import { isMobile, toSiteURL } from '../utils'
import { getLocal } from '../utils/storage'
import { getQuery } from '../utils/parse'
import DefaultTheme from './DefaultTheme'
import Footer from './Footer'
import Logo from './Logo'

const styles = {
  wrapper: {
    textAlign: 'center',
    color: grey50,
  },
  heading: {
    fontWeight: 400,
    fontSize: 40,
    marginBottom: 100,
  },
  block: {
    width: isMobile() ? '100%' : '50%',
    height: isMobile() ? 200 : '100%',
    paddingTop: isMobile() ? 100 : 200,
    paddingBottom: isMobile() ? 150 : 250,
    top: 0,
  },
  button: {
    width: 200,
  },
  brand: {
    position: 'absolute',
    margin: 20,
  }
}

function handleRedirectFlag(path) {
  return '/#' + path +
    '?type=' + getQuery('type') +
    '&code=' + getQuery('code') +
    '&state=' + getQuery('state') +
    '&oauth_token=' + getQuery('oauth_token') +
    '&oauth_verifier=' + getQuery('oauth_verifier')
}

class Home extends Component {
  componentWillMount() {
    // HOTFIXED! to handle redirect flags
    const redirect = getQuery('redirect')
    if (redirect.trim() !== '') {
      this.redirect(handleRedirectFlag(redirect))
      return
    }

    let auth = getLocal('auth') || {}
    if (window.location.hash === '#/' && auth.type !== undefined) {
      this.redirect('/#/' + auth.type)
      return
    }
  }

  componentDidMount() {
    // refresh if size changed
    window.addEventListener('resize', function() {
      window.location.reload()
    })
  }

  redirect(path) {
    window.location = window.location.origin + path
  }

  render() {
    if (getLocal('auth') !== null) {
      return null
    }

    return (
      <DefaultTheme>
        <section style={styles.wrapper}>
          <Logo />

          <div style={{overflow: 'hidden'}}>
            <div style={Object.assign({}, styles.block, {
                float: 'left',
                background: teal500,
              })}>
              <h1 style={styles.heading}>ADVERTISER</h1>
              <p>Find the right influencer for your campaign.</p>
              <RaisedButton label="Sign up" secondary={true}
                style={styles.button}
                href="/#/advertiser/register" />
              <RaisedButton label="Login"
                style={styles.button}
                href="/#/advertiser" />
              <p>
                <br />
                <FlatButton href={toSiteURL('/advertiser')}
                  icon={<InfoIcon color={grey50} />} />
              </p>
            </div>

            <div style={Object.assign({}, styles.block, {
                float: 'right',
                background: indigo500,
              })}>
              <h1 style={styles.heading}>INFLUENCER</h1>
              <p>Monetize your influence on social platforms.</p>
              <RaisedButton secondary={true}
                label="Login with your social account"
                href="/#/influencer" />
              <p>
                <br />
                <FlatButton href={toSiteURL('/influencer')}
                  icon={<InfoIcon color={grey50} />} />
              </p>
            </div>
          </div>
          <Footer />
        </section>
      </DefaultTheme>
    )
  }
}

export default Home
