import api from '../api'

/*
 *  action constants
 */

export const FIND_CATEGORY  = 'FIND_CATEGORY'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findCategory = () => (dispatch) => {
  let params = '?simple'

  dispatch({
    type: FIND_CATEGORY,
    payload: api.get('/category' + params),
  })
}
