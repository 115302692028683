import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux'

import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import Dialog from 'material-ui/Dialog'
import LinearProgress from 'material-ui/LinearProgress'

import { getTransaction } from '../actions/transaction'
import { formatDate, formatAmount } from '../utils'

import ProgressBar from '../components/ProgressBar'

const mapStateToProps = (state, ownProps) => {
  return {
    transaction: state.transaction,
  }
}

class TransactionView extends Component {
  state = {
    detail: {},

    open: false,
    loading: false,
  }

  constructor(props) {
    super(props)

    this.closeHandler = this.closeHandler.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    let { transaction } = nextProps

    let id = nextProps.id || ''
    if (id !== '' && this.state.loading === false ) {
      this.props.getTransaction(id)

      this.setState({
        loading: true,
      })

      return
    }

    if (transaction.detail.id !== undefined) {
      this.setState({
        open: nextProps.open,
        detail: transaction.detail,
        loading: false,
      })

      return
    }
  }

  closeHandler() {
    this.setState({
      detail: {},
      open: false,
    })

    this.props.onRequestClose()
  }

  render() {
    let { transaction } = this.props

    const actions = [
      <FlatButton
        label="Close"
        primary={false}
        onClick={this.closeHandler} />,
    ]

    const title = (
      <div>
        <ProgressBar open={transaction.loading} style={{position: 'initial'}} />
        {this.state.detail.name || '-'}
      </div>
    )

    return (
      <Dialog
        title={title}
        actions={actions}
        modal={true}
        open={this.state.open}
        autoScrollBodyContent={true}
        onRequestClose={this.props.onRequestClose} >

        <LinearProgress mode="indeterminate" style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          visibility: transaction.loading ? 'visible' : 'hidden',
        }} />

        <TextField floatingLabelText="Type" fullWidth={true}
          value={this.state.detail.type || '-'} />
        <TextField floatingLabelText="Amount" fullWidth={true}
          value={formatAmount(this.state.detail.amount) || '-'} />
        <TextField floatingLabelText="Provider" fullWidth={true}
          value={this.state.detail.provider || '-'} />
        <TextField floatingLabelText="Reference ID" fullWidth={true}
          value={this.state.detail.ref_id || '-'} />
        <TextField floatingLabelText="Remark" fullWidth={true}
          value={this.state.detail.remark || '-'} />
        <TextField floatingLabelText="Date" fullWidth={true}
          value={formatDate(this.state.detail.date) || '-'} />
      </Dialog>
    )
  }
}

TransactionView.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default connect(mapStateToProps, {
  getTransaction,
})(TransactionView)
