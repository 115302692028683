import api from '../api'

/*
 *  action constants
 */

export const FIND_TRANSACTION  = 'FIND_TRANSACTION'
export const GET_TRANSACTION  = 'GET_TRANSACTION'
export const GET_TRANSACTION_SCORE  = 'GET_TRANSACTION_SCORE'

export const CLEAR_NOTIF = 'CLEAR_NOTIF'


/*
 *  action types
 */

export const findTransaction = (option) => (dispatch) => {
  let params = '?meta&campaign'
  params += '&order=created_at,desc'
  params += '&slice=' + parseInt(option.pageOffset, 10)
  params += ',' + (parseInt(option.pageLimit, 10)
    + parseInt(option.pageOffset, 10))
  params += '&search=' + option.searchKey.toString()

  dispatch({
    type: FIND_TRANSACTION,
    payload: api.get('/transaction' + params),
  })
}

export const getTransaction = (id) => (dispatch) => {
  dispatch({
    type: GET_TRANSACTION,
    payload: api.get('/transaction/' + id),
  })
}

export const getTransactionScore = () => (dispatch) => {
  dispatch({
    type: GET_TRANSACTION_SCORE,
    payload: api.get('/transaction-score'),
  })
}

export const clearNotif = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIF,
  })
}
