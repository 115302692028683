import React from 'react'

import { blueGrey500, orangeA400, grey50, grey700 } from 'material-ui/styles/colors'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
// import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

export const primaryColor = blueGrey500
export const accentColor = orangeA400
export const lightColor = grey50
const muiTheme = getMuiTheme({
  palette: {
    textColor: grey700,
    primary1Color: primaryColor,
    accent1Color: accentColor,
    alternateTextColor: lightColor,
  },
  appBar: {
    height: 50,
  },
})

export default (props) => (
  <MuiThemeProvider muiTheme={muiTheme}>
    {props.children}
  </MuiThemeProvider>
)
